<template>
  <div class="bar-box" :id="'bar-box' + chartIndex"></div>
</template>

<script>
import * as echarts from 'echarts';
import { toRefs, computed, onMounted, watch, onBeforeUnmount } from 'vue';
import { getThemeColor } from '@/utils/addColor.js';
export default {
  props: {
    barData: {
      type: Array,
      default: () => { []; }
    },
    chartIndex: String,
    barDataLegend: {
      type: Array,
      default: () => { []; }
    },
    barXLabel: {
      type: Array,
      default: () => { []; }
    }

  },
  setup (props) {
    const { barData, barDataLegend, barXLabel } = toRefs(props);
    const themStyles = getThemeColor();
    let myChart = {};
    const grid = {
      left: '3%',
      right: '5%',
      bottom: '5%',
      top: '15%',
      containLabel: true,
    };
    const barCharts = () => {
      if (barData["value"][0]) {
        const series = barDataLegend.value.map((name, sid) => {
          return {
            name,
            type: 'bar',
            stack: 'total',
            barWidth: '11.8px',
            data: barData["value"][sid].map((d) => {
              return d;
            }
            ),

            itemStyle: {
              borderRadius: [5, 5, 0, 0],
              color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [
                {
                  offset: 1,
                  color: themStyles["--appSafeBar"][`bar${sid + 1}Rgb`]
                },
                {
                  offset: 0,
                  color: themStyles["--appSafeBar"][`bar${sid + 1}Startcolor`]
                }
              ])
            },
          };
        });
        const barOptions = computed(() => {
          return {
            tooltip: {
              trigger: 'axis',
              backgroundColor: '#11151ee5',
              padding: 16,
              textStyle: {
                color: '#eaedff'
              },
              extraCssText: 'border: 1px dotted #eaedff99'
            },
            legend: {
              type: 'plain',
              right: 0,
              textStyle: {
                color: "#9FA2B1"
              }
            },
            grid,
            yAxis: {
              type: 'value',
              splitLine: {
                lineStyle: {
                  color: 'rgba(255, 255, 255, 0.2)'
                }
              },
            },
            xAxis: {
              type: 'category',
              axisLabel: {
                ellipsis: '...',
                overflow: 'truncate',
                width: 80
              },
              data: barXLabel.value
            },
            series
          };
        });
        let barBox = echarts.getInstanceByDom(document.getElementById('bar-box' + props.chartIndex));
        if (barBox == null) {
          barBox = echarts.init(document.getElementById('bar-box' + props.chartIndex));
        }
        barBox.setOption(barOptions.value);
        myChart = barBox;
      }

    };

    const resizeChart = () => {
      myChart.resize();
    };

    onMounted(() => {
      barCharts();
      window.addEventListener(
        "resize", resizeChart
      );
    });

    onBeforeUnmount(() => {
      window.removeEventListener(
        "resize", resizeChart
      );
    });

    // 监听传值
    watch(barData, () => {
      barCharts();
    });
  }
}
</script>

<style scoped lang="less">
.bar-box {
  width: 100%;
  height: 100%;
}
</style>