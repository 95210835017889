<template>
  <div class="filter-box">
    <a-form
      ref="formRef"
      layout="inline"
      :model="formState"
      @finish="handleSearch"
    >
      <a-form-item label="APP名称" name="app_name" v-if="tabVal === '1'">
        <a-input v-model:value="formState.app_name" placeholder="请输入..." />
      </a-form-item>
      <a-form-item label="APP包名" name="app_pkg" v-if="tabVal === '1'">
        <a-input v-model:value="formState.app_pkg" placeholder="请输入..." />
      </a-form-item>
      <a-form-item label="APK证书" name="apk_cert_md5" v-if="tabVal === '1'">
        <a-input
          v-model:value="formState.apk_cert_md5"
          placeholder="请输入..."
        />
      </a-form-item>
      <a-form-item label="安装状态" name="state" v-if="tabVal === '1'">
        <a-select
          ref="select"
          v-model:value="formState.state"
          placeholder="请选择..."
        >
          <a-select-option value="安装">安装</a-select-option>
          <a-select-option value="卸载">卸载</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="WIFI标签" name="wifiTag" v-if="tabVal === '2'">
        <a-input v-model:value="formState.wifiTag" placeholder="请输入..." />
      </a-form-item>
      <a-form-item label="WIFI位置" name="wifiAddress" v-if="tabVal === '2'">
        <a-input
          v-model:value="formState.wifiAddress"
          placeholder="请输入..."
        />
      </a-form-item>
      <a-form-item label="IP归属地" name="ipAddress" v-if="tabVal === '3'">
        <a-input v-model:value="formState.ipAddress" placeholder="请输入..." />
      </a-form-item>
      <a-form-item name="selectTime" v-if="tabVal !== '1'">
        <div class="filter-datepicker">
          <a-range-picker
            v-model:value="formState.selectTime"
            value-format="YYYY-MM-DD"
            :showToday="false"
            :ranges="{
              今天: [dayjs(), dayjs()],
              最近一周: [
                dayjs().startOf('day').subtract(6, 'd'),
                dayjs().endOf('day')
              ],
              最近一个月: [
                dayjs().startOf('day').subtract(30, 'd'),
                dayjs().endOf('day')
              ],
              最近三个月: [
                dayjs().startOf('day').subtract(90, 'd'),
                dayjs().endOf('day')
              ]
            }"
          />
        </div>
      </a-form-item>
      <a-form-item>
        <div class="search-btn-box">
          <span class="search-btn search-btn-primary" @click="handleSearch">
            检索
          </span>
          <!-- <a-button type="primary" html-type="submit">Submit</a-button> -->
          <span class="search-btn" @click="handleReset"> 重置 </span>
        </div>
      </a-form-item>
    </a-form>
  </div>
</template>

<script>
import { ref, reactive } from 'vue';
import dayjs from 'dayjs';
export default {
  props: {
    tabVal: String,
  },
  setup (props, vm) {
    const formRef = ref();
    const formState = reactive({
    });
    // 查询
    const handleSearch = () => {
      const _this = vm;
      formRef.value.validate().then((res) => {
        let newParams = {};
        newParams = { ...res };
        if (res.selectTime) {
          newParams.st = res.selectTime[0];
          newParams.et = res.selectTime[1];
          delete newParams.selectTime;
        }
        _this.emit("searchData", newParams);
      });
    };
    // 重置
    const handleReset = () => {
      formRef.value.resetFields();
      vm.emit("searchData", {});
    };
    return {
      formRef,
      formState,
      dayjs,
      handleSearch,
      handleReset
    };
  }
}
</script>

<style  lang="less">
@import url(./index.less);
@import url(../../../../style/a-datepicker.less);
@import url(../../../../style/a-input.less);
</style>