<template>
  <div class="barCharts" :id="'barCharts' + barIndex"></div>
</template>

<script>
import * as echarts from 'echarts';
import { toRefs, computed, onMounted, watch, onBeforeUnmount } from 'vue';
import { getThemeColor } from '@/utils/addColor.js';
export default {
  props: {
    barChartData: {
      type: Array,
      default: () => { []; }
    },
    barIndex: Number
  },
  setup (props, vm) {
    const { barChartData } = toRefs(props);
    let myChart = {};
    const themStyles = getThemeColor();
    const barOptions = computed(() => {
      return {
        grid: {
          left: '0',
          right: '5%',
          bottom: '0',
          top: '3%',
          containLabel: true
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'line',
            lineStyle: {
              opacity: 0
            }
          },
          backgroundColor: '#11151ee5',
          padding: 16,
          textStyle: {
            color: '#eaedff'
          },
          extraCssText: 'border: 1px dotted #eaedff99',
          formatter: (params) => {
            return params[0].name + '：' + (Math.round(params[0].value * 10000)) / 100 + '%';
          }
        },
        xAxis: {
          show: false,
          type: 'value'
        },
        yAxis: [
          {
            type: 'category',
            inverse: true,
            axisLabel: {
              show: false
            },
            splitLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            axisLine: {
              show: false
            },
            data: barChartData.value.map(item => item.name)
          },
          {
            type: 'category',
            inverse: true,
            axisTick: 'none',
            axisLine: 'none',
            show: true,
            axisLabel: {
              color: themStyles["--mainBarChart"]["xLabelColor"],
              fontSize: '12',
              formatter: (param) => {
                return (Math.round(param * 10000)) / 100 + '%';
              }
            },
            data: barChartData.value.map(item => item.ratio)
          }
        ],
        series: [
          {
            name: '值',
            type: 'bar',
            showBackground: true,
            backgroundStyle: {
              color: 'rgba(255, 255, 255, 0.1)'
            },
            itemStyle: {
              borderRadius: 30,
              color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                {
                  offset: 1,
                  color: themStyles["--mainBarChart"]["color"]
                },
                {
                  offset: 0,
                  color: themStyles["--mainBarChart"]['bar']
                }
              ])
            },
            label: {
              show: true,
              position: [0, '-20px'],
              color: '#EAEDFF',
              fontSize: 12,
              formatter: function (val) {
                return val.name;
              }
            },
            barWidth: 6,
            data: barChartData.value.map(item => item.ratio)
          }
        ]
      };
    });

    const barCharts = () => {
      let barCharts = echarts.getInstanceByDom(document.getElementById('barCharts' + props.barIndex));
      if (barCharts == null) {
        barCharts = echarts.init(document.getElementById('barCharts' + props.barIndex));
      }
      barCharts.setOption(barOptions.value);
      myChart = barCharts;
      barCharts.on('click', (value) => {
        vm.emit('handleClickBar', value.name, props.barIndex);
      });
    };

    const resizeChart = () => {
      myChart.resize();
    };

    onMounted(() => {
      barCharts();
      window.addEventListener(
        "resize", resizeChart
      );
    });

    onBeforeUnmount(() => {
      window.removeEventListener(
        "resize", resizeChart
      );
    });

    // 监听传值，刷新图表
    watch(barChartData, () => {
      barCharts();
    });
  }
}
</script>

<style scoped lang="less">
.barCharts {
  width: 100%;
  height: 100%;
}
</style>