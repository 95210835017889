<template>
  <div class="barItem" :id="'barItem' + barIndex"></div>
</template>

<script>
import * as echarts from 'echarts';
import { toRefs, computed, onMounted, watch, onBeforeUnmount } from 'vue';
import { getThemeColor } from '@/utils/addColor.js';

export default {
  props: {
    barChartData: {
      type: Array,
      default: () => { []; }
    },
    barIndex: Number
  },
  setup (props) {
    const { barChartData } = toRefs(props);
    let myChart = {};
    const themStyles = getThemeColor();
    const barOptions = computed(() => {
      return {
        grid: {
          left: '0',
          right: '0',
          bottom: '0',
          top: '3%',
          containLabel: true
        },
        xAxis: {
          show: false,
          type: 'value'
        },
        yAxis: [
          {
            type: 'category',
            inverse: true,
            axisLabel: {
              show: true,
              color: '#EAEDFF'
            },
            splitLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            axisLine: {
              show: false
            },
            data: barChartData.value.map(item => item.name)
          },
          {
            type: 'category',
            inverse: true,
            axisTick: 'none',
            axisLine: 'none',
            show: true,
            axisLabel: {
              color: themStyles["--mainBarChart"]["xLabelColor"],
              fontSize: '12'
            },
            data: barChartData.value.map(item => item.value)
          }
        ],
        series: [
          {
            name: '值',
            type: 'bar',
            showBackground: true,
            backgroundStyle: {
              color: 'rgba(255, 255, 255, 0.1)'
            },
            itemStyle: {
              borderRadius: 30,
              color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                {
                  offset: 1,
                  color: themStyles["--mainBarChart"]["color"]
                },
                {
                  offset: 0,
                  color: themStyles["--mainBarChart"]['bar']
                }
              ])
            },
            barWidth: 6,
            data: barChartData.value.map(item => item.value)
          }
        ]
      };
    });

    const barItems = () => {
      let barItem = echarts.getInstanceByDom(document.getElementById('barItem' + props.barIndex));
      if (barItem == null) {
        barItem = echarts.init(document.getElementById('barItem' + props.barIndex));
      }
      barItem.setOption(barOptions.value);
      myChart = barItem;
    };

    const resizeChart = () => {
      myChart.resize();
    };

    onMounted(() => {
      barItems();
      window.addEventListener(
        "resize", resizeChart
      );
    });

    onBeforeUnmount(() => {
      window.removeEventListener(
        "resize", resizeChart
      );
    });

    // 监听传值，刷新图表
    watch(barChartData, () => {
      barItems();
    });
  }
}
</script>

<style scoped lang="less">
.barItem {
  width: 100%;
  height: 100%;
}
</style>