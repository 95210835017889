<template>
  <div
    class="card"
    v-for="(item, index) in data"
    :key="index"
    @click="clickDetails(item.appname)"
  >
    <Card-border></Card-border>
    <div class="card-box">
      <span class="card-box-rank">{{ index + 1 }}</span>
      <div class="card-box-name">
        <span class="card-box-name-icon">
          <img :src="item.ico" />
        </span>
        <span class="card-box-name-text">{{ item.appname }}</span>
      </div>
      <div class="card-box-device">
        <span>感染设备</span>
        <CountUp
          class="card-box-device-infect"
          v-if="item.startInf"
          :startVal="item.startInf"
          :endVal="item.inf"
          :index="44"
        ></CountUp>
        <span v-else class="card-box-device-infect">{{ item.inf }}</span>
        <span>覆盖设备</span>
        <span class="card-box-device-cover">{{ item.cov }}</span>
      </div>
      <div class="card-box-chart">
        <GaugeChart
          :rateData="item.rate"
          :rateIndex="index"
          rateName="感染率"
        />
      </div>
    </div>
  </div>
</template>

<script>
import CardBorder from './card-border.vue';
import router from '@/router/index';
import GaugeChart from './gauge.vue';
import CountUp from './countup.vue';
export default {
  props: {
    data: Object,

  },
  components: {
    CardBorder,
    GaugeChart,
    CountUp
  },
  setup () {
    const clickDetails = (val) => {
      router.push(`/detail?name=${val}`);
    };

    return { clickDetails, CountUp };
  }
}
</script>

<style scoped lang="less">
.card {
  cursor: pointer;
  position: relative;
  height: 33.3%;
  &-box {
    // padding: 10px 16px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    &-rank {
      font-size: 24px;
      color: #eaedff;
    }
    &-name {
      display: flex;
      flex-direction: column;
      align-items: center;
      &-icon {
        width: 60px;
        height: 60px;
        display: block;
        img {
          width: 100%;
        }
      }
      &-text {
        font-size: 12px;
        color: #9fa2b1;
        line-height: 22px;
      }
    }
    &-device {
      font-size: 12px;
      color: var(--mainTextColor);
      line-height: 16px;
      display: flex;
      flex-direction: column;
      width: 150px;
      &-infect {
        font-family: Azonix;
        font-size: 16px;
        background: var(--mainInfectTextColor);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        // color: #ffb58f;
        line-height: 24px;
      }
      &-cover {
        font-family: Azonix;
        font-size: 16px;
        color: #eaedff;
        line-height: 24px;
      }
    }
    &-chart {
      width: 96px;
      height: 96px;
      background-image: url('../../assets/images/gaugeBg.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }
  }
}
</style>
