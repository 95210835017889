<template>
  <a-select
    v-if="selectShow"
    v-model:value="value"
    show-search
    placeholder="请输入"
    :options="options.map(item => ({value: item.appname}))"
    open
    :filter-option="filterOption"
    @change="handleChange"
  ></a-select>
</template>

<script>
import { ref, toRefs, watch } from 'vue';
import { Select } from 'ant-design-vue';
export default {
  props: {
    appOptions: {
      type: Array,
      default: () => {[]}
    },
    show: Boolean
  },
  components: {
    [Select.name]: Select,
  },
  setup(props, vm) {
    const { appOptions } = toRefs(props);
    const options = ref([]);
    const selectShow = ref(false);

    const handleChange = (val) => {
      selectShow.value = false;
      vm.emit('handelSelectApp', val, false);
    };

    const filterOption = (input, option) => {
      return option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    };

    // 监听传值，刷新图表
    watch(() => props.show, () => {
      selectShow.value = props.show;
      options.value = appOptions.value;
    });

    return {
      value: ref(undefined),
      filterOption,
      handleChange,
      options,
      selectShow
    };
  },

};
</script>

<style scoped lang="less">

</style>