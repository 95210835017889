<template>
  <div class="pirate-wrap">
    <div class="pirate-wrap-top">
      <div class="top-card">
        <Card-border-blue></Card-border-blue>
        <Card-number
          :data="appNumber"
          :style="'background: #282328;'"
          :color="orangeColor"
        ></Card-number>
      </div>
      <div class="top-card">
        <Card-border></Card-border>
        <Card-number
          :data="devNumber"
          :style="'background: #eaedff1a;'"
          :color="whiteColor"
        ></Card-number>
      </div>
      <div class="top-card">
        <Card-border-red></Card-border-red>
        <Card-number
          :data="perMumber"
          :style="'background: #29191d;'"
          :color="redColor"
        ></Card-number>
      </div>
      <div class="top-search">
        <a-select
          v-model:value="searchValue"
          show-search
          placeholder="自定义搜索应用名称"
          :default-active-first-option="false"
          :show-arrow="false"
          :allowClear="true"
          :filter-option="false"
          :not-found-content="null"
          :options="searchData"
          @search="handleSearch"
          @change="handleChange"
        ></a-select>
      </div>
    </div>
    <div class="pirate-wrap-bottom">
      <span class="bottom-title"
        >仿冒应用
        <!-- <span v-if="appListData.rows && appListData.rows.length > 0">
          （{{ appListData.count }}）
        </span> -->
      </span>
      <div class="bottom-box">
        <div class="bottom-list">
          <div class="bottom-list-inner">
            <div
              class="bottom-list-card"
              :class="activeShow === index ? 'active' : ''"
              v-for="(item, index) in appListData.rows"
              :key="index"
              @click="onClick(item.app, index)"
            >
              <Card-border-blue v-if="activeShow === index"></Card-border-blue>
              <Card-border v-else></Card-border>
              <div class="bottom-list-card-top">
                <span class="bottom-list-card-sort">{{ index + 1 }}</span>
                <span class="bottom-list-card-line"></span>
                <!-- <span class="bottom-list-card-icon">
                  <img :src="item.appIcon" />
                </span> -->
                <span class="bottom-list-card-name">{{ item.app }}</span>
                <!-- <span
                  class="bottom-list-card-btn"
                  :class="activeShow === index ? 'active-btn' : ''"
                  @click="handleModalChange(true)"
                  >一键处置</span
                > -->
              </div>
              <div class="bottom-list-card-border"></div>
              <div class="bottom-list-card-bottom">
                <div class="bottom-list-card-content">
                  <span>仿冒渠道数</span>
                  <span class="num">{{ item.channel_count || 0 }}</span>
                </div>
                <div class="bottom-list-card-content">
                  <span>仿冒应用数</span>
                  <span class="num">{{ item.s_count }}</span>
                </div>
                <div class="bottom-list-card-content">
                  <span>仿冒比例</span>
                  <span>{{ item.rate }}</span>
                </div>
                <div class="bottom-list-card-content">
                  <span>仿冒设备数</span>
                  <span>{{ item.inf }}</span>
                </div>
              </div>
            </div>
            <!-- <div class="bottom-list-pagination">
              <a-pagination v-model:current="current" simple :total="total" @change="onChange" />
            </div> -->
          </div>
          <!-- <div class="bottom-list-pagination">
            <a-pagination
              v-model:current="current"
              simple
              :total="total"
              @change="onChange"
            />
          </div> -->
        </div>
        <div class="bottom-table">
          <Card-border></Card-border>
          <div class="bottom-table-box">
            <a-table
              class="ant-table-striped"
              size="middle"
              :columns="columns"
              :data-source="appTableData"
              :pagination="appTable"
              @change="handlePageChange"
              :rowClassName="
                (record, index) => (index % 2 === 1 ? 'table-striped' : null)
              "
            >
              <template #bodyCell="{ column, text, record }">
                <template v-if="column.dataIndex === 'rate'">
                  <div class="downBox">
                    <span class="down-btn" @click="downLoad(record)">
                      下载
                    </span>
                    <span class="down-text">
                      {{ `${(Number(text) * 100).toFixed(2)}%` }}
                    </span>
                  </div>
                </template>
                <template v-if="column.dataIndex === 'source_url'">
                  <div @click="linkTo(record)" style="cursor: pointer">
                    {{ text }}
                  </div>
                </template>
              </template>
            </a-table>
          </div>
        </div>
      </div>
    </div>
    <disposal
      :visible="disposalVisible"
      @onClose="handleModalChange"
      :btn="false"
    ></disposal>
  </div>
</template>

<script>
import { ref, reactive, toRefs, onMounted, onBeforeUnmount } from 'vue';
import dayjs from 'dayjs';
import { getSummary, getList, getDetail, getSearch } from '@/api/pirate.js';
import CardBorder from '@/components/common/card-border.vue';
import CardBorderBlue from '@/components/common/card-border-blue.vue';
import CardBorderRed from '@/components/common/card-border-red.vue';
import CardNumber from '@/components/common/card-num.vue';
import Disposal from '@/components/common/disposal/index.vue';
import { Pagination, Table, Select } from 'ant-design-vue';
// import weatherIcon from '@/assets/images/weather.png';

// 时间传的都是周一的时间，因为仿冒监测的数据是每周一更新的，目前这个交互就是传最近的周一的时间
const monday = dayjs().subtract(1, "day").format("YYYY-MM-DD");

const orangeColor = { name: '#FCE8CC', value: '#FFB58F' };

const whiteColor = { name: '#EAEDFF', value: '#EAEDFF' };

const redColor = { name: '#ff7373', value: '#ff7373' };

const columns = [
  {
    title: '排序',
    customRender: ({ index }) => {
      return index + 1;
    },
    dataIndex: 'sort',
    key: 'sort',
    width: 80
  },
  {
    title: '仿冒包名',
    dataIndex: 'piracy_pn',
    key: 'piracy_pn',
    width: 200,
    ellipsis: true
  },
  {
    title: '仿冒MD5',
    dataIndex: 'apk_md5',
    key: 'apk_md5',
    ellipsis: true
  },
  {
    title: '仿冒应用名',
    dataIndex: 'piracy_an',
    key: 'piracy_an'
  },
  {
    title: '签名信息',
    dataIndex: 's',
    key: 's',
    width: 120,
    scopedSlots: { customRender: 's' },
    ellipsis: true
  },
  {
    title: '源链接',
    dataIndex: 'source_url',
    key: 'source_url',
    ellipsis: true
  },
  {
    title: '影响设备数',
    dataIndex: 'inf',
    key: 'inf'
  },
  {
    title: '影响设备占比',
    dataIndex: 'rate',
    key: 'rate',
  }
];

export default {
  components: {
    CardBorder,
    CardBorderBlue,
    CardBorderRed,
    CardNumber,
    Disposal,
    [Table.name]: Table,
    [Pagination.name]: Pagination,
    [Select.name]: Select
  },
  setup () {
    const appNumber = ref({});
    const devNumber = ref({});
    const perMumber = ref({});
    const appListData = ref({});
    const activeShow = ref(0);
    const appList = reactive({
      current: 1,
      pageSize: '5',
      total: 0
    });
    const appTable = reactive({
      current: 1,
      total: 0,
      pageSize: 10,
      showSizeChanger: false,
      showTotal: (total) => `共 ${total} 条`
    });
    const appTableData = ref([]);
    const searchValue = ref();
    const searchData = ref([]);
    const selectAppName = ref('');
    const disposalVisible = ref(false);

    const setTableHeight = () => {
      if ($(".bottom-table-box").height()) {
        const h = ($(".bottom-table-box").height() - 102) / 10 - 24;
        $(".bottom-table-box .ant-table-tbody td").height(h);
        $(".bottom-table-box .ant-table").height($(".bottom-table-box").height() - 56);
      }
    };

    onMounted(() => {
      window.addEventListener(
        "resize", setTableHeight
      );
    });

    onBeforeUnmount(() => {
      window.removeEventListener(
        "resize", setTableHeight
      );
    });

    // 列表页面跳转
    const onChange = (val) => {
      appList.current = val;
      getAppList('');
    };

    // 表格分页
    const handlePageChange = (val) => {
      appTable.current = val.current;
      getAppDetails(selectAppName.value);
    };

    // 时间传的都是周一的时间，因为仿冒监测的数据是每周一更新的，目前这个交互就是传最近的周一的时间
    // const getMonday = () => {
    //   const nowTime = new Date().getTime();
    //   const day = new Date().getDay();
    //   const onDayTime = 24 * 60 * 60 * 1000;
    //   const mondayTime = nowTime - (day - 1) * onDayTime;
    //   monday.value = dayjs(mondayTime).format('YYYY-MM-DD');
    // };
    // 源链接跳转
    const linkTo = (record) => {
      const { source_url = "" } = record;
      if (source_url) {
        window.open(source_url);
      }
    };
    // 获取数据概况
    const getSurvey = async () => {
      const { data } = await getSummary({ dt: monday });
      appNumber.value = { name: '仿冒应用数', value: data.app_count };
      devNumber.value = { name: '仿冒影响设备数量', value: data.inf };
      perMumber.value = { name: '仿冒影响设备占比', value: (Math.round(data.app_rate * 10000)) / 100 + '%' };
    };

    // 获取应用列表
    const getAppList = async (name) => {
      const param = {
        type: 'app', // 监测类型，默认传app
        dt: monday,
        page: appList.current,
        app: name,
        size: appList.pageSize
      };
      const { data } = await getList(param);
      appListData.value = data;
      appList.total = data.count;
      selectAppName.value = data.rows[0].app;
      appTable.current = 1;
      getAppDetails(selectAppName.value);
    };

    // 获取应用详情列表
    const getAppDetails = async (appName) => {
      const params = {
        dt: monday,
        page: appTable.current,
        size: appTable.pageSize,
        app: appName,
        pn: ''
      };
      const { rows, count } = (await getDetail(params)).data[0];
      appTableData.value = rows;
      appTable.total = count || 0;
      setTimeout(() => {
        setTableHeight();
      });
    };

    // 获取感染应用
    const getAppSearch = async (name) => {
      const { data } = await getSearch({
        dt: monday,
        app: name
      });
      searchData.value = data.ret.map((item, index) => {
        return {
          name: index,
          value: item.app
        };
      });
    };
    // 模糊查询
    const handleSearch = (val) => {
      getAppSearch(val);
    };
    // 点击搜索跳转详情
    const handleChange = (val) => {
      searchValue.value = val;
      getAppList(val);
    };
    // 下载
    const downLoad = (record) => {
      const { download_url = "" } = record;
      if (download_url) {
        window.open(download_url);
      }
    };
    const onClick = async (value, index) => {
      activeShow.value = index;
      selectAppName.value = value;
      appTable.current = 1;
      getAppDetails(value);
    };
    const handleModalChange = (state) => {
      disposalVisible.value = state;
    };

    // getMonday();
    getSurvey();
    getAppList('');

    return {
      appNumber,
      devNumber,
      orangeColor,
      whiteColor,
      redColor,
      perMumber,
      appListData,
      disposalVisible,
      ...toRefs(appList),
      onChange,
      onClick,
      downLoad,
      activeShow,
      columns,
      appTableData,
      appTable,
      handlePageChange,
      handleModalChange,
      searchValue,
      searchData,
      handleSearch,
      handleChange,
      linkTo
    };
  }
}
</script>

<style lang="less">
@import url('../../style/select.less');
@import url('../../style/a-table.less');
@import url('./index.less');
</style>
