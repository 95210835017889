<template>
  <div class="riskEqu-wrap">
    <div class="riskEqu-wrap-top">
      <div class="top-box">
        <span class="top-box-back" @click="goBack">
          <img :src="getImgPath('application/back.png')" />
        </span>
        <span class="top-box-line"></span>
        <span class="top-box-text">设备详情</span>
      </div>
      <div class="top-right">
        <div class="search-box">
          <a-select :value="selectVal" @change="handleSearchTypeChange">
            <a-select-option value="oaid">OAID</a-select-option>
          </a-select>
          <a-input-search
            placeholder="请输入..."
            style="width: 486px"
            @change="handleSearchKeyWord"
            @search="onSearch"
          />
        </div>
      </div>
    </div>
    <div class="riskEqu-appInfo">
      <Card-border></Card-border>
      <img :src="getImgPath('mapbg.png')" alt="" class="appInfo-mapbg" />
      <div class="appInfo-box">
        <div class="appInfo-left">
          <div class="user-type">
            <img
              :src="
                deviceInfo.userImg ||
                'http://p0.qhimg.com/t0165eb964264870395.png'
              "
              alt=""
            />
          </div>
        </div>
        <div class="appInfo-right">
          <div class="appInfo-name">
            <span>
              {{ deviceInfo.oaid_list || '-' }}
              <div
                class="appInfo-copy"
                @click="handleCopy(deviceInfo.app_name || '-')"
              >
                <copy-outlined />
              </div>
            </span>
          </div>
          <div class="appInfo-userInfo">
            <div class="userInfo-sex userInfo-tag">
              {{ deviceInfo.sex || '-' }}
            </div>
            <div class="userInfo-age userInfo-tag">
              {{ deviceInfo.age || '-' }}
            </div>
            <div class="userInfo-type userInfo-tag">
              {{ deviceInfo.tags || '-' }}
            </div>
          </div>
          <div class="appInfo-list">
            <div
              class="appInfo-card"
              v-for="(item, index) in cardConfig"
              :key="index"
            >
              <div class="appInfo-card-top">{{ item.title }}</div>
              <div class="appInfo-card-bottom">
                <div
                  class="appInfo-card-bottom-list"
                  v-for="data in item.list"
                  :key="data.key"
                >
                  <div class="list-label">{{ data.label }}：</div>
                  <div class="list-value">
                    {{ deviceInfo[data.key] || '-' }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="riskEqu-tablebox">
      <div class="riskEqu-tablebox-tabs">
        <a-radio-group v-model:value="activeTab" @change="handleRadioChange">
          <a-radio-button
            v-for="item in tabConfig"
            :key="item.value"
            :value="item.value"
          >
            {{ item.label }}
          </a-radio-button>
        </a-radio-group>
      </div>
      <div class="riskEqu-tablebox-table">
        <Card-border></Card-border>
        <div class="riskEqu-table">
          <div class="table-filter">
            <div class="filter-left">
              <div
                class="filter-tab-box"
                v-if="activeTab === '2' || activeTab === '3'"
              >
                <span
                  v-for="item in tableTypeConfig"
                  :key="item.value"
                  :class="tableType === item.value ? 'active-tab' : ''"
                  @click="handleTableChange(item.value)"
                  >{{ item.label }}</span
                >
              </div>
            </div>
            <div class="filter-right">
              <Filter :tabVal="activeTab" @searchData="handleSearchData" />
            </div>
          </div>
          <history-map
            v-if="activeTab > 3"
            :oaid="oaid"
            :filter="filter"
          ></history-map>
          <Table
            :tabVal="activeTab"
            :oaid="oaid"
            :filter="filter"
            :tableType="tableType"
            v-else
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import useClipboard from "vue-clipboard3";
import { CopyOutlined } from '@ant-design/icons-vue';
import CardBorder from "@/components/common/card-border.vue";
import Filter from "./components/Filter";
import { getImgPath } from '@/utils/addColor.js';
import { getDevicedInfo, getDevicedArea } from '@/api/riskEquipment.js';
import Table from './components/Table/index.vue';
import HistoryMap from './components/HistoryMap/index.vue';
import router from '@/router/index';
import { message } from 'ant-design-vue';
const { toClipboard } = useClipboard();
const tableTypeConfig = [
  {
    label: '历史模式',
    value: '1'
  }, {
    label: '统计模式',
    value: '2'
  },
];
const cardConfig = [
  {
    title: "设备信息",
    list: [
      {
        label: "手机机型",
        key: "model"
      }, {
        label: "手机语言",
        key: "language"
      }, {
        label: "虚拟机",
        key: "is_virtual"
      }
    ],
    img: 'test'
  },
  {
    title: "地区信息",
    list: [
      {
        label: "原籍地",
        key: "birthplace"
      }, {
        label: "工作地",
        key: "workplace"
      }, {
        label: "家庭地",
        key: "homeplace"
      }
    ]
  },
  {
    title: "最新位置",
    list: [
      {
        label: "位置",
        key: "location"
      }, {
        label: "时间",
        key: "recent_time"
      }
    ]
  }
];
const tabConfig = [
  {
    label: "APP安装列表",
    value: "1"
  }, {
    label: "WIFI列表",
    value: "2"
  }, {
    label: "历史IP",
    value: "3"
  }, {
    label: "历史轨迹",
    value: "4"
  }
  // , {
  //   label: "团伙发现",
  //   value: "5"
  // }
];
const commonDic = {
  m1: 'oaid',
  level: 'level'
};
export default {
  components: {
    CardBorder,
    Filter,
    Table,
    HistoryMap,
    CopyOutlined
  },
  setup () {
    const selectVal = ref('oaid');
    const activeTab = ref('1');
    const tableType = ref('1');
    const keyWrods = ref('');
    const deviceInfo = ref({});
    const oaid = ref("");
    const filter = ref({});
    const searchTableData = ref(null);//搜索结果
    const searchTableVisible = ref(false);
    const searchLoading = ref(false);
    const params = router.currentRoute.value.query;
    // 表格属性
    const handleTableChange = (val) => {
      tableType.value = val;
    };
    // tab切换
    const handleRadioChange = (e) => {
      const val = e.target.value;
      activeTab.value = val;
      tableType.value = "1";
    };
    // 详情内容查询
    const onSearch = async () => {
      let newParams = {};
      if (keyWrods.value) {
        searchLoading.value = true;
        for (let i in params) {
          newParams[commonDic[i]] = params[i];
        }
        newParams[selectVal.value] = keyWrods.value;
        const data = await getData(newParams);
        searchLoading.value = false;
        searchTableData.value = data;
        searchTableVisible.value = true;
        if (data.length > 0) {
          const { level = '' } = params;
          router.push(`/riskEquipment?level=${level}&m1=${data[0]["oaid_list"].split(" ")[0]}`);
          setTimeout(() => {
            window.location.reload();
          }, 500);
        } else {
          message.info("未查到相关数据");
        }
      }
    };
    // 获取设备信息
    const getDeviceInfoData = async () => {
      let newParams = {};
      for (let i in params) {
        newParams[commonDic[i]] = params[i];
      }
      oaid.value = newParams.oaid;
      const data = await getData({ ...newParams });
      if (data[0]) {
        let newData = { ...data[0] };
        newData.tags = data[0].tags.split(',')[1];
        newData.userImg = getUserImg(newData);
        deviceInfo.value = newData;
        getDevicedAreaData();
      }
    };
    // 获取地区信息
    const getDevicedAreaData = async () => {
      let newDeviceInfo = { ...deviceInfo.value };
      const res = await getDevicedArea({ oaid });
      if (res.error == 200) {
        if (res.data && res.data[0]) {
          newDeviceInfo = { ...newDeviceInfo, ...res.data[0] };
          deviceInfo.value = newDeviceInfo;
        }
      }
    };

    const getUserImg = (data) => {
      const { sex, age } = data;
      let img = "http://p0.qhimg.com/t0165eb964264870395.png";
      if (age !== "19岁以下" && age !== "50岁以上") {
        if (sex === '女') {
          img = "http://p0.qhimg.com/t01aa525ae9b18fc190.png";
        } else {
          img = "http://p0.qhimg.com/t01ef4f81301d96352a.png";
        }
      }
      if (age === "50岁以上") {
        if (sex === '女') {
          img = "http://p0.qhimg.com/t019254600a3cdebf41.png";
        } else {
          img = "http://p0.qhimg.com/t012ebd451fa8994cb5.png";
        }
      }
      return img;
    };
    //查询数据
    const handleSearchData = async (filterData) => {
      filter.value = filterData;
    };
    const handleSearchTypeChange = (val) => {
      selectVal.value = val;
    };
    const handleSearchKeyWord = (e) => {
      const val = e.target.value;
      keyWrods.value = val;
    };
    const goBack = () => {
      window.history.go(-1);
    };
    // 查询数据并返回
    const getData = async (params) => {
      const res = await getDevicedInfo(params);
      if (res.error == 200) {
        return res.data || [];
      }
      return [];
    };
    const handleCopy = async (text) => {
      try {
        await toClipboard(text);  //实现复制
        message.success("复制成功");
      } catch (e) {
        console.error(e);
      }
    };
    getDeviceInfoData();
    return {
      oaid,
      selectVal,
      activeTab,
      getImgPath,
      cardConfig,
      tabConfig,
      deviceInfo,
      tableType,
      keyWrods,
      filter,
      tableTypeConfig,
      searchTableVisible,
      searchTableData,
      searchLoading,
      handleCopy,
      handleTableChange,
      handleRadioChange,
      handleSearchData,
      handleSearchTypeChange,
      handleSearchKeyWord,
      onSearch,
      goBack
    };
  }
}
</script>
<style lang="less" >
@import url(./index.less);
</style>