import { get } from "../utils/request";
// 接口文档 http://doc.qiwoo.org/doc/view/5821

// 仿冒监测 -> 数据概览
export const getSummary = (params) => get('/piracy/cmcc/app/summary', params);

// 仿冒监测 -> 数据概览
export const getList = (params) => get('/piracy/cmcc/app/list', params);

// 仿冒监测 -> 仿冒应用详情列表
export const getDetail = (params) => get('/piracy/cmcc/app/detail', params);

// 仿冒监测 -> app应用名称搜索
export const getSearch = (params) => get('/piracy/cmcc/app/search', params);