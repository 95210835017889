<template>
  <div class="pieCharts" id="pieCharts"></div>
</template>

<script>
import * as echarts from 'echarts';
import { toRefs, computed, onMounted, watch, onBeforeUnmount } from 'vue';
import { getThemeColor } from '@/utils/addColor.js';

export default {
  props: {
    pieData: {
      type: Array,
      default: () => { []; }
    }
  },
  setup (props) {
    const { pieData } = toRefs(props);
    let myChart = {};
    const themStyles = getThemeColor();
    const THEME = localStorage.theme || 'orange';
    const CHARCOLOR = {
      blue: ['#58C1FF', '#479ACC', '#357499', '#234D66'],
      orange: ['#483734', '#6f4e42', '#a77158', '#CC6666']
    };
    const pieOptions = computed(() => {
      return {
        color: CHARCOLOR[THEME],
        series: [
          {
            name: '攻击设备等级',
            type: 'pie',
            radius: ['28%', '80%'],
            roseType: 'area',
            label: {
              show: true,
              formatter: (params) => {
                return `{top|攻击等级}\n{bottom|${params.name}}\n{top|攻击设备}\n{bottom|${params.value}}`;
              },
              rich: {
                top: {
                  color: '#9FA2B1',
                  fontSize: 12,
                  padding: [10, 0, 0, 0]
                },
                bottom: {
                  color: themStyles['--mainChartColor'],
                  fontSize: 16,
                  padding: [8, 0, 8, 0]
                }
              }
            },
            labelLine: {
              lineStyle: {
                color: 'rgba(255, 255, 255, 0.2)'
              }
            },
            data: pieData.value
          }
        ]
      };
    });

    const pieCharts = () => {
      let pieCharts = echarts.getInstanceByDom(document.getElementById('pieCharts'));
      if (pieCharts == null) {
        pieCharts = echarts.init(document.getElementById('pieCharts'));
      }
      pieCharts.setOption(pieOptions.value);
      myChart = pieCharts;
    };

    const resizeChart = () => {
      myChart.resize();
    };

    onMounted(() => {
      pieCharts();
      window.addEventListener(
        "resize", resizeChart
      );
    });

    onBeforeUnmount(() => {
      window.removeEventListener(
        "resize", resizeChart
      );
    });

    // 监听传值，刷新图表
    watch(pieData, () => {
      pieCharts();
    });
  }
}
</script>

<style scoped lang="less">
.pieCharts {
  width: 100%;
  height: 100%;
}
</style>