<template>
  <div class="gaugeChart" :id="'gaugeChart' + rateIndex"></div>
</template>

<script>
import * as echarts from 'echarts';
import { computed, onMounted, toRefs, watch, onBeforeUnmount } from 'vue';
import { getThemeColor } from '@/utils/addColor.js';
export default {
  name: 'GaugeChart',
  props: {
    rateData: String,
    rateIndex: Number,
    rateName: String
  },
  setup (props) {
    const { rateData, rateName } = toRefs(props);
    let myChart = {};
    const themStyles = getThemeColor();
    const setOptions = computed(() => {
      let options = {
        series: [{
          type: 'gauge',
          startAngle: 90,
          endAngle: -270,
          min: 0,
          max: 1,
          splitNumber: 8,
          axisLine: {
            show: false
          },
          progress: {
            show: true,
            width: 8,
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [
                {
                  offset: 0,
                  color: `rgba(${themStyles["--mainChartColorRgb"]}, 1)`
                },
                {
                  offset: 0.5,
                  color: `rgba(${themStyles["--mainChartColorRgb"]}, 0.93)`
                },
                {
                  offset: 1,
                  color: `rgba(${themStyles["--mainChartColorRgb"]}, 0.05)`
                },
              ]),
            }
          },
          pointer: {
            show: false
          },
          axisTick: {
            show: false
          },
          splitLine: {
            show: false
          },
          axisLabel: {
            show: false
          },
          title: {
            offsetCenter: [0, '-30%'],
            fontSize: 12,
            color: themStyles["--mainTextColor"],
          },
          detail: {
            fontSize: 16,
            offsetCenter: [0, '30%'],
            valueAnimation: true,
            fontFamily: "Azonix",
            formatter: function (value) {
              return (Math.round(value * 10000)) / 100 + '%';
            },
            color: '#EAEDFF'
          },
          data: [
            {
              value: rateData.value,
              name: rateName.value
            }
          ]
        }]
      };
      return options;
    });

    function initChart () {
      let gaugeChart = echarts.getInstanceByDom(document.getElementById('gaugeChart' + props.rateIndex));
      if (gaugeChart == null) {
        gaugeChart = echarts.init(document.getElementById('gaugeChart' + props.rateIndex));
      }
      gaugeChart.setOption(setOptions.value);
      myChart = gaugeChart;
    }

    const resizeChart = () => {
      myChart.resize();
    };

    onMounted(() => {
      initChart();
      window.addEventListener(
        "resize", resizeChart
      );
    });

    onBeforeUnmount(() => {
      window.removeEventListener(
        "resize", resizeChart
      );
    });

    // 监听传值，刷新图表
    watch(rateData, () => {
      initChart();
    });
  }
}
</script>

<style scoped lang="less">
.gaugeChart {
  width: 100%;
  height: 100%;
}
</style>
