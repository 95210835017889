<template>
  <div class="funnelChart" id="funnelChart"></div>
</template>

<script>
import * as echarts from 'echarts';
import { toRefs, computed, onMounted, watch, onBeforeUnmount } from 'vue';
export default {
  props: {
    funnelData: {
      type: Array,
      default: () => {[]}
    }
  },
  setup(props) {
    const { funnelData } = toRefs(props);
    let myChart = {};
    const colorList = ['rgba(204, 102, 102, 0.9)', 'rgba(204, 136, 102, 0.9)', 'rgba(255, 190, 102, 0.7)', 'rgba(255, 190, 102, 0.65)', 'rgba(255, 212, 163, 0.6)',
      'rgba(255, 212, 163, 0.5)', 'rgba(255, 212, 163, 0.4)']

    const funnelOptions = computed(() => {
      return {
        color: colorList,
        grid: {
          top: '0',
          left: '0',
          right: '0',
          bottom: '0',
          containLabel: true
        },
        series: [
          {
            top: '5%',
            type: 'funnel',
            gap: 5,
            minSize: '20%',
            width: '80%',
            left: '3%',
            bottom: '0',
            sort: 'descending',
            label: {
              show: true,
              position: 'inside',
              fontSize: 16,
              formatter: '{c}',
              color: '#FFFFFF'
            },
            itemStyle: {
              borderWidth: 0
            },
            data: funnelData.value
          },
          {
            top: '5%',
            type: 'funnel',
            gap: 5,
            minSize: '20%',
            width: '80%',
            left: '3%',
            bottom: '0',
            sort: 'descending',
            label: {
              show: true,
              position: 'right',
              fontSize: 14,
              formatter: '{b}',
              color: '#FFFFFF'
            },
            labelLine: {
              show: false
            },
            itemStyle: {
              borderWidth: 0
            },
            data: funnelData.value
          },
        ]
      }
    })

    const funnelCharts = () => {
      let funnelChart = echarts.getInstanceByDom(document.getElementById('funnelChart'));
      if (funnelChart == null) {
        funnelChart = echarts.init(document.getElementById('funnelChart'));
      }
      funnelChart.setOption(funnelOptions.value);
      myChart = funnelChart
    };

    const resizeChart = () => {
      myChart.resize()
    }

    onMounted(() => {
      funnelCharts();
      window.addEventListener(
        "resize", resizeChart
      )
    });

    onBeforeUnmount(() => {
      window.removeEventListener(
          "resize", resizeChart
      )
    })

    // 监听传值，刷新图表
    watch(funnelData, () => {
      funnelCharts();
    });
  }
}
</script>

<style scoped lang="less">
.funnelChart {
  width: 100%;
  height: 100%;
}
</style>