import { get } from "../utils/request";
// 接口文档 http://doc.qiwoo.org/doc/view/5833

// 风险管控 -> TOP1~TOP6及设备总数
export const getSummary = (params) => get('/risk_control/summary', params);

// 风险管控 -> 风险等级
export const getLevel = (params) => get('/risk/level', params);

// 风险管控 -> 风险管控Top10
export const getLevelTop = (params) => get('/risk/top', params);

// 风险管控 -> 各个省份风险管控
export const getGeo = (params) => get('/risk_control/geo', params);

// 风险管控 -> 风险管控详情页面
export const getDetail = (params) => get('/risk/detailv2', params);
