<template>
  <div class="risk-wrap">
    <div class="risk-wrap-left">
      <Card-border></Card-border>
      <div class="left-card">
        <div
          class="left-card-box"
          :class="activeShow === 4 ? 'active' : ''"
          @click="selectedLevel(levelList.serious_device, 4)"
        >
          <div class="left-card-chart">
            <GaugeChart :rateData="levelList.serious_rate" :rateIndex="0" />
          </div>
          <div class="left-card-content">
            <span>严重</span>
            <CountUp :endVal="levelList.serious_device" :index="81"></CountUp>
          </div>
        </div>
        <div
          class="left-card-box"
          :class="activeShow === 3 ? 'active' : ''"
          @click="selectedLevel(levelList.high_device, 3)"
        >
          <div class="left-card-chart">
            <GaugeChart :rateData="levelList.high_rate" :rateIndex="1" />
          </div>
          <div class="left-card-content">
            <span>高风险</span>
            <!-- <span>{{levelList.high_device}}</span> -->
            <CountUp :endVal="levelList.high_device" :index="82"></CountUp>
          </div>
        </div>
        <div
          class="left-card-box"
          :class="activeShow === 2 ? 'active' : ''"
          @click="selectedLevel(levelList.medium_device, 2)"
        >
          <div class="left-card-chart">
            <GaugeChart :rateData="levelList.medium_rate" :rateIndex="2" />
          </div>
          <div class="left-card-content">
            <span>中风险</span>
            <!-- <span>{{levelList.medium_device}}</span> -->
            <CountUp :endVal="levelList.medium_device" :index="83"></CountUp>
          </div>
        </div>
        <div
          class="left-card-box"
          :class="activeShow === 1 ? 'active' : ''"
          @click="selectedLevel(levelList.low_rate, 1)"
        >
          <div class="left-card-chart">
            <GaugeChart :rateData="levelList.low_rate" :rateIndex="3" />
          </div>
          <div class="left-card-content">
            <span>低风险</span>
            <!-- <span>{{levelList.low_device}}</span> -->
            <CountUp :endVal="levelList.low_device" :index="84"></CountUp>
          </div>
        </div>
      </div>
      <div class="left-rank">
        <div
          class="left-rank-box"
          v-for="(item, index) in devRank"
          :key="index"
        >
          <span>TOP&nbsp;&nbsp;{{ index + 1 }}</span>
          <span>|</span>
          <span>{{ item.name }}</span>
          <span>{{ item.value }}</span>
        </div>
      </div>
      <div class="left-map">
        <div class="left-map-box">
          <MapChart :seriesData="allGeoData" />
        </div>
      </div>
    </div>
    <div class="risk-wrap-right">
      <div class="right-top">
        <div class="right-top-card">
          <Card-border></Card-border>
          <Card-number
            :data="deviceAll"
            :style="'background: #242830e5'"
            :color="deviceColor"
          ></Card-number>
        </div>
        <div class="right-top-calendar">
          <DatePicker @dataPickerTime="selectTime" />
        </div>
      </div>
      <div class="right-list">
        <Card-border></Card-border>
        <div class="right-list-title">
          <span class="right-list-name">风险设备TOP10</span>
          <span class="right-list-more" @click="clickMore">查看更多</span>
        </div>
        <div class="right-list-table">
          <a-table
            class="ant-table-striped"
            :columns="columns"
            :data-source="devRankData"
            :pagination="false"
            :rowClassName="
              (record, index) => (index % 2 === 1 ? 'table-striped' : null)
            "
          >
            <template #bodyCell="{ column, text, record }">
              <template v-if="column.dataIndex === 'm1'">
                <span @click="handleToDetail(record)">{{ text }}</span>
              </template>
              <template v-if="column.dataIndex === 'geo'">
                <div class="downBox">
                  <!-- <Disposal @handleDisposal="disposal" /> -->
                  <span class="down-text">
                    {{ text }}
                  </span>
                </div>
              </template>
            </template>
          </a-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, onBeforeUnmount } from 'vue';
import dayjs from 'dayjs';
import CardBorder from '@/components/common/card-border.vue';
import GaugeChart from './gauge.vue';
import MapChart from '@/components/common/map.vue';
import CardNumber from '@/components/common/card-num.vue';
// import DatePicker from '@/components/common/date-picker.vue';
import DatePicker from '@/components/common/date-picker-one-day.vue';
import CountUp from "@/components/common/countup.vue";
import Disposal from '@/components/common/disposal/index.vue';
import { Table, Tooltip } from 'ant-design-vue';
import router from '@/router/index';
import { getSummary, getGeo, getLevel, getLevelTop } from '@/api/riskControl.js';

let nowTime = dayjs().startOf('week').add(1, 'day').format("YYYY-MM-DD");
const deviceColor = { name: '#cbcfdf', value: '#EAEDFF' };

const devRankName = {
  ave_device: '病毒木马设备',
  root: 'Root设备',
  xposed: 'Xposed框架',
  hook: 'Hook设备',
  ps: '双开设备',
  sdr: '模拟器设备'
};

const riskLevel = {
  '1': '低风险',
  '2': '中风险',
  '3': '高风险',
  '4': '严重'
};

const columns = [
  {
    title: '排序',
    customRender: ({ index }) => {
      return index + 1;
    },
    dataIndex: 'sort',
    key: 'sort',
    width: 80
  },
  {
    title: '设备信息',
    dataIndex: 'm1',
    key: 'm1',
    ellipsis: true
  },
  {
    title: '风险等级',
    dataIndex: 'risk_level',
    key: 'risk_level',
    customRender: ({ text }) => {
      let texts = '';
      Object.keys(riskLevel).map(key => {
        if (key === text) {
          texts = riskLevel[key];
        }
      });
      return texts;
    }
  },
  {
    title: '地理信息',
    dataIndex: 'geo',
    key: 'geo'
  }
];

export default {
  components: {
    CardBorder,
    GaugeChart,
    MapChart,
    CardNumber,
    DatePicker,
    CountUp,
    // Disposal,
    [Table.name]: Table,
    [Tooltip.name]: Tooltip
  },
  setup () {
    const levelList = ref({});
    const devRank = ref([]);
    const deviceAll = ref({});
    const allGeoData = ref([]);
    const devRankData = ref([]);
    const activeShow = ref('');
    const risklevel = ref('');

    const setTableHeight = () => {
      if ($(".right-list").height()) {
        const h = ($(".right-list").height() - 97 - 42) / 10 - 24;
        $(".right-list .ant-table-tbody td").height(h);
      }
    };

    onMounted(() => {
      window.addEventListener(
        "resize", setTableHeight
      );
    });

    onBeforeUnmount(() => {
      window.removeEventListener(
        "resize", setTableHeight
      );
    });

    // 日期选择
    const selectTime = (val) => {
      nowTime = val;
      getRiskSummary();
      getRiskLevel();
      getRiskLevelTop();
      getRiskGeo();
    };

    // 风险等级筛选
    const selectedLevel = (val, level) => {
      activeShow.value = level;
      risklevel.value = level;
      getRiskLevelTop(level);
    };

    // 获取TOP1~TOP6及设备总数
    const getRiskSummary = async () => {
      const res = (await getSummary({ dt: nowTime })).data[0];
      devRank.value = [];
      if (res) {
        deviceAll.value = { name: '设备总数', value: res.device_total, startValue: '', index: '85' };
        Object.keys(res).map(key => {
          if (Object.keys(devRankName).includes(key)) {
            devRank.value.push({
              name: devRankName[key],
              value: res[key]
            });
          }
        });
      }
    };
    // 获取风险等级
    const getRiskLevel = async () => {
      const res = (await getLevel({ dt: nowTime })).data[0] || {};
      const { serious_device = 0, high_device = 0, medium_device = 0, low_device = 0 } = res;
      let show = 4;
      if (Number(low_device)) {
        show = 1;
      }
      if (Number(medium_device)) {
        show = 2;
      }
      if (Number(high_device)) {
        show = 3;
      }
      if (Number(serious_device)) {
        show = 4;
      }
      levelList.value = res;
      activeShow.value = show;
    };
    // 跳转详情页
      const handleToDetail = (record) => {
      const { m1 = "" } = record;
      router.push(`/riskEquipment?level=${risklevel.value}&m1=${m1}`);
    };
    // 风险管控Top10(默认会展示严重等级top10的数据，如果严重等级没有数据就展示高风险等级Top10，依次类推)
    const getRiskLevelTop = async (level) => {
      const { data } = await getLevelTop({ dt: nowTime, risk_level: level });
      devRankData.value = data;
      setTimeout(() => {
        setTableHeight();
      });
    };
    // 获取地图各个省份风险管控的数据
    const getRiskGeo = async () => {
      const { data } = await getGeo({ dt: nowTime });
      allGeoData.value = data.map(item => {
        return {
          name: item.geo,
          value: item.inf
        };
      });
    };

    const clickMore = () => {
      let risk = risklevel.value || 4;
      router.push(`/riskMore?level=${risk}`);
    };

    getRiskSummary();
    getRiskGeo();
    getRiskLevel();
    getRiskLevelTop();

    return {
      levelList,
      devRank,
      deviceAll,
      deviceColor,
      columns,
      devRankData,
      clickMore,
      allGeoData,
      riskLevel,
      selectTime,
      selectedLevel,
      activeShow,
      handleToDetail
    };
  }
}
</script>

<style lang="less" >
@import url(./index.less);
</style>
