export const columnsArr = [
    {
        title: '序号',
        customRender: ({ index }) => {
            return index + 1;
        },
        dataIndex: 'sort',
        key: 'sort',
        width: 80
    }, {
        title: 'APP名称	',
        dataIndex: 'app_name',
        key: 'app_name',
        ellipsis: true
    }, {
        title: 'APP包名',
        dataIndex: 'app_pkg',
        key: 'app_pkg',
        ellipsis: true
    }, {
        title: '签名MD5',
        dataIndex: 'apk_cert_md5',
        key: 'apk_cert_md5',
        ellipsis: true
    }, {
        title: '版本',
        dataIndex: 'appVersionCode',
        key: 'appVersionCode'
    }, {
        title: 'APP类型',
        dataIndex: 'category',
        key: 'category'
    }, {
        title: 'APP标签',
        dataIndex: 'app_tags',
        key: 'app_tags'
    }, {
        title: '历史装机量',
        dataIndex: 'age',
        key: 'age'
    }, {
        title: '操作',
        dataIndex: 'action',
        key: 'action'
    }
];