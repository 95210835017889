<template>
  <a-table
    class="ant-table-risk"
    size="middle"
    :columns="columns"
    :data-source="dataSource"
    :pagination="appTable"
    @change="handlePageChange"
    :loading="tableLoading"
    :rowClassName="
      (record, index) => (index % 2 === 1 ? 'table-striped' : null)
    "
  >
    <template #bodyCell="{ column, text }">
      <template v-if="column.dataIndex === 'sex'">
        <div :class="text === '男' ? 'tags_green' : 'tags_yellow'" v-if="text">
          {{ text }}
        </div>
      </template>
      <template v-if="column.dataIndex === 'tags'">
        <div class="tags_default" v-if="text">
          {{ text }}
        </div>
      </template>
    </template>
  </a-table>
</template>
<script>
import { ref, watch, reactive } from 'vue';
import { getAppInstallList } from '@/api/application';
import { columnsArr } from './columns';
export default {
  props: {
    tabVal: String,
    deviceInfo: Object,
    filter: Object
  },
  setup (props) {
    const appTable = reactive({
      current: 1,
      total: 0,
      pageSize: 10,
      showSizeChanger: false,
      showTotal: (total) => `共 ${total} 条数据`
    });
    const dataSource = ref([]);
    const columns = ref([]);
    const tableLoading = ref(false);
    const searcFilter = ref({});
    const appInfo = ref({});
    const getTableData = async () => {
      tableLoading.value = true;
      let params = {};
      params.app_pkg = props.deviceInfo.app_pkg;
      params.apk_cert_md5 = props.deviceInfo.apk_cert_md5;
      params.page = appTable.current;
      params.size = appTable.pageSize;
      params = { ...params, ...searcFilter.value };
      const res = await getAppInstallList(params);
      tableLoading.value = false;
      if (res.error == 200) {
        dataSource.value = res.data || [];
        appTable.total = res.data ? res.data.length : 0;
        console.log(appTable);
      }
    };
    const getColumns = () => {
      columns.value = columnsArr;
    };
    // 表格分页
    const handlePageChange = (val) => {
      appTable.current = val.current;
      getTableData();
    };
    getColumns();
    getTableData();

    watch(() => props.filter, val => {
      appTable.current = 1;
      searcFilter.value = val;
      getTableData();
    });

    watch(() => props.deviceInfo, val => {
      appTable.current = 1;
      appInfo.value = val;
      getTableData();
    });
    return {
      searcFilter,
      dataSource,
      columns,
      appTable,
      tableLoading,
      handlePageChange
    };
  }
}
</script>
<style lang="less" >
@import url(./index.less);
@import url(../../../../style/a-table.less);
</style>