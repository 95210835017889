import { get, post } from "../utils/request";
// 接口文档 http://doc.qiwoo.org/doc/view/6374

// 反诈-设备终端数据接口
export const getDevicedInfo = (params) => get('/device/deviceInfo', params);

// 反诈-地区信息
export const getDevicedArea = (params) => get('/device/deviceArea', params);

// 反诈-APP装机列表数据
export const getDevicedInstall = (params) => get('/device/installs', params);

// 反诈-Wifi列表数据
export const getDevicedWifiList = (params) => get('/device/wifiList', params);

// 反诈-历史IP数据
export const getDevicedIpHistory = (params) => get('/device/ipHistory', params);

// 反诈-历史轨迹数据
export const getDevicedGpsHistory = (params) => get('/device/gpsHistory', params);

