<template>
  <div class="lineCharts" :id="'lineCharts' + chartIndex"></div>
</template>

<script>
import * as echarts from 'echarts';
import { toRefs, computed, onMounted, watch, onBeforeUnmount } from 'vue';
import { getThemeColor } from '@/utils/addColor.js';
export default {
  props: {
    lineBarData: {
      type: Array,
      default: () => { []; }
    },
    chartIndex: {
      type: Number,
      default: 9999,
    }
  },
  setup (props) {
    const { lineBarData } = toRefs(props);
    let myChart = {};
    const themStyles = getThemeColor();
    const THEME = localStorage.theme || 'orange';
    const CHARCOLOR = {
      blue: {
        bar: "rgba(187, 239, 255, 0)",
        line: "#BBEFFF",
      },
      orange: {
        bar: "rgba(217, 163, 108, 0.5)",
        line: "#FCE8CC",
      }
    };
    const lineOptions = computed(() => {
      return {
        tooltip: {
          trigger: 'axis',
          backgroundColor: '#11151ee5',
          padding: 16,
          textStyle: {
            color: '#eaedff'
          },
          extraCssText: 'border: 1px dotted #eaedff99',
          formatter: '{b}：{c}'
        },
        grid: {
          left: '0',
          top: '5%',
          right: '1%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          nameTextStyle: {
            color: '#9FA2B1',
            fontSize: 12
          },
          axisLine: {
            lineStyle: {
              color: 'rgba(255, 255, 255, 0.2)'
            }
          },
          axisTick: {
            show: false
          },
          data: lineBarData.value.map(item => item.date)
        },
        yAxis: {
          type: 'value',
          splitLine: {
            lineStyle: {
              color: 'rgba(255, 255, 255, 0.2)'
            }
          },
          axisLabel: {
            color: '#9FA2B1',
            formatter: '{value}'
          }
        },
        series: [{
          type: 'line',
          showSymbol: false,
          itemStyle: {
            color: '#eaedff',
            borderColor: '#ffffff54'
          },
          lineStyle: {
            color: 'rgba(88, 193, 255, 1)'
          },
          smooth: true,
          areaStyle: {
            color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [
              {
                offset: 0,
                color: "rgba(88, 193, 255, 0)"
              },
              {
                offset: 0.8,
                color: " rgba(88, 193, 255, 1)"
              }
            ])
          },
          data: lineBarData.value.map(item => item.installs)
        }]
      };
    });


    const lineCharts = () => {
      let lineCharts = echarts.getInstanceByDom(document.getElementById('lineCharts' + props.chartIndex));
      if (lineCharts == null) {
        lineCharts = echarts.init(document.getElementById('lineCharts' + props.chartIndex));
      }
      lineCharts.setOption(lineOptions.value);
      myChart = lineCharts;
    };

    const resizeChart = () => {
      myChart.resize();
    };

    onMounted(() => {
      lineCharts();
      window.addEventListener(
        "resize", resizeChart
      );
    });

    onBeforeUnmount(() => {
      window.removeEventListener(
        "resize", resizeChart
      );
    });

    // 监听传值，刷新图表
    watch(lineBarData, () => {
      lineCharts();
    });
  }
}
</script>

<style scoped lang="less">
.lineCharts {
  width: 100%;
  height: 100%;
}
</style>
