<template>
  <div class="log-wrap">
    <div class="log-wrap-left">
      <CardBorder></CardBorder>
      <div class="log-wrap-title">崩溃日志TOP10</div>
      <div class="left-list">
        <div class="left-list-item" v-for="(item, index) in topList" :key="index">
            <span class="left-list-sort">{{index + 1}}</span>
            <div class="left-list-content">
            <div class="left-list-main">
                <span>日志摘要</span>
                <span>{{item.et}}</span>
            </div>
            <div>
                <span class="left-list-text">影响用户数</span>
                <span class="left-list-num">{{item.personNum}}</span>
            </div>
            </div>
        </div>
      </div>
    </div>
    <div class="log-wrap-right">
      <CardBorder></CardBorder>
      <div class="right-table">
        <span class="right-table-title">崩溃日志统计</span>
        <div class="right-table-select">
          <div class="right-table-options">
            <a-select
              v-model:value="versionSel"
              :options="versionList.map(item => ({value: item}))"
              placeholder="版本"
              @change="handleChangeVer"
            ></a-select>
          </div>
          <div class="right-table-options">
            <a-select
              v-model:value="timeSel"
              :options="timeList"
              placeholder="时间"
              @change="handleChangeTime"
            ></a-select>
          </div>
          <div class="right-table-options">
            <a-select
              v-model:value="typeSel"
              :options="typeList"
              placeholder="类型"
              @change="handleChangeType"
            ></a-select>
          </div>
          <div class="right-table-options">
            <a-select
              v-model:value="statusSel"
              :options="statusList"
              placeholder="状态"
              @change="handleChangeStatus"
            ></a-select>
          </div>
        </div>
      </div>
      <a-table
        class="ant-table-striped"
        size="middle"
        :total="pageTotal"
        :show-total="(total) => `共 ${pageTotal} 条`"
        :columns="columns"
        :data-source="appTableData"
        :current="pageNum"
        :pageSize="pageSize"
        @change="handlePageChange"
        :customRow="rowClick"
        :rowClassName="(record, index) => (index % 2 === 1 ? 'table-striped' : null)"
      >
        <template #bodyCell="{ column, record }">
          <template v-if="column.key === 'logContent'">
            <div class="show-elipsis" :title="record.et">
              异常类型:{{record.et}}
            </div>
            <div class="show-elipsis" :title="record.ec">异常信息:{{record.ec}}</div>
            <div class="show-elipsis" :title="record.est">关键方法:{{record.est}}</div>
          </template>
        </template>
      </a-table>
    </div>
  </div>
</template>

<script>
import { toRefs, ref, reactive, watch, onMounted, onBeforeUnmount } from 'vue';
import { getTopList, getTableList } from '@/api/crashLog.js';
import CardBorder from '@/components/common/card-border.vue';
import { Table, Select } from 'ant-design-vue';
import router from '@/router/index';
const columns = [
  {
    title: '崩溃日志摘要',
    key: 'logContent',
    width: 548
  },
  {
    title: '应用版本',
    dataIndex: 'vn',
    key: 'vn'
  },
  {
    title: '首次发生时间',
    dataIndex: 'minCrashTime',
    key: 'minCrashTime'
  },
  {
    title: '最近发生时间',
    dataIndex: 'maxCrashTime',
    key: 'maxCrashTime'
  },
  {
    title: '累计崩溃次数',
    dataIndex: 'docCount',
    key: 'docCount'
  },
  {
    title: '累计影响用户',
    dataIndex: 'personNum',
    key: 'personNum'
  }
];
export default {
  props: {
    appData: {
      type: Object,
      default: () => {}
    },
  },
  components: {
    CardBorder,
    [Table.name]: Table,
    [Select.name]: Select
  },
  setup(props) {
    const { appData } = toRefs(props);
    const appParams = ref({
      appKey: '',
      pn: ''
    });
    const topList = ref([]);
    const appTableData = ref([]);
    const appTable = reactive({
      pageNum: 1,
      pageTotal: 0,
      pageSize: 5
    });
    const versionList = ref([]);
    const timeList = [
      {
        label: '小时',
        value: 0
      },
      {
        label: '天',
        value: 1
      },
      {
        label: '7天',
        value: 2
      },
      {
        label: '30天',
        value: 3
      }
    ];
    const typeList = [
      {
        label: 'NATIVE',
        value: 0
      },
      {
        label: 'JAVA',
        value: 1
      }
    ];
    const statusList = [
      {
        label: '未处理',
        value: 0
      },
      {
        label: '已处理',
        value: 1
      }
    ]
    const versionSel = ref();
    const timeSel = ref();
    const typeSel = ref();
    const statusSel = ref();

    const setTableHeight = () => {
        if($(".log-wrap-right").height()){
            const h = ($(".log-wrap-right").height() - 50 - 56 - 42)/5 - 24
            $(".log-wrap-right .ant-table-tbody td").height(h)
            $(".log-wrap-right .ant-table").height($(".log-wrap-right").height() - 50 - 56)
        }
    }

    onMounted(() => {
        window.addEventListener(
            "resize", setTableHeight
        )
    })

    onBeforeUnmount(() => {
        window.removeEventListener(
            "resize", setTableHeight
        )
    })

    // 选择版本号
    const handleChangeVer = (val) => {
      versionSel.value = val;
      getLogTableList(1);
    };
    // 选择时间
    const handleChangeTime = (val) => {
      timeSel.value = val;
      getLogTableList(1);
    };
    // 选择类型
    const handleChangeType = (val) => {
      typeSel.value = val;
      getLogTableList(1);
    };
    // 选择状态
    const handleChangeStatus = (val) => {
      statusSel.value = val;
      getLogTableList(1);
    };
    // 表格分页
    const handlePageChange = (val) => {
      appTable.pageNum = val;
      getLogTableList();
    };

    // 获取崩溃日志TOP10
    const getLogTopList = async () => {
      const { data } = await getTopList(appParams.value);
      topList.value = data;
    };

    // 获取崩溃日志统计列表
    const getLogTableList = async (pageNum) => {
      const { data } = await getTableList({
        appKey: appParams.value.appKey,
        pn: appParams.value.pn,
        offset: pageNum || appTable.pageNum,
        limit: appTable.pageSize,
        timeType: timeSel.value,
        version: versionSel.value,
        type: typeSel.value,
        status: statusSel.value
      });
      appTable.pageTotal = data.arrListCount;
      appTableData.value = data.arrList;
      versionList.value = data.arrVersion;
      setTimeout(()=>{
          setTableHeight()
      })
    };

    const rowClick = (record) => {
      return {
        onClick: () => {
          router.push(`/logDetails?meVnT=${record.me_v_t}&version=${record.vn}&type=${record.t}&appKey=${appParams.value.appKey}&pn=${appParams.value.pn}`)
        }
      }
    };

    // 监听传值
    watch(appData.value, () => {
      appParams.value = appData.value;
      getLogTopList();
      getLogTableList(1);
    },{immediate: true});

    return {
      topList,
      columns,
      ...toRefs(appTable),
      handlePageChange,
      appTableData,
      versionSel,
      versionList,
      timeList,
      timeSel,
      typeSel,
      typeList,
      statusSel,
      statusList,
      handleChangeVer,
      handleChangeTime,
      handleChangeType,
      handleChangeStatus,
      rowClick,
    }
  }
}
</script>

<style lang="less">
.log-wrap {
  display: flex;
  padding: 0 2px 2px 0;
  height: 100%;
  &-title {
      font-size: 18px;
      line-height: 26px;
      margin-bottom: 10px;
  }
  &-left {
    width: 21%;
    height: 100%;
    position: relative;
    padding: 24px;
    margin-right: 24px;
    .left-list {
      height: calc(100% - 36px);
      &-item{   
        display: flex;
        align-items: center;
        height: 10%;
      }
      &-sort {
        width: 43px;
        height: 43px;
        background-image: url('../../assets/images/application/sort.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        line-height: 43px;
        text-align: center;
        display: block;
      }
      &-content {
        margin-left: 8px;
        display: flex;
        flex-direction: column;
        padding: 4px 10px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.4);
        width: calc(100% - 51px);
        height: 100%;
        justify-content: space-around;
      }
      &-main {
        overflow: hidden;
        text-overflow:ellipsis;
        white-space: nowrap;
      }
      &-text {
        color: #CCCCCC;
        opacity: 0.65;
      }
      &-num {
        color: #FFB58F;
        margin-left: 8px;
      }
    }
  }
  &-right {
    width: 78%;
    height: 100%;
    position: relative;
    padding: 24px;
    .ant-table-tbody > tr.ant-table-row:hover > td, .ant-table-tbody > tr > td.ant-table-cell-row-hover {
      cursor: pointer;
    }
    .right-table {
      display: flex;
      justify-content: space-between;
      &-title {
        display: block;
        font-size: 18px;
        line-height: 26px;
        margin-bottom: 24px;
      }
      &-select {
        display: flex;
        flex-direction: row;
      }
      &-options {
        width: 160px;
        height: 32px;
        margin-left: 10px;
        .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
          height: 32px !important;
        }
        .ant-select-arrow {
          color: #AEAEAE !important;
        }
        .ant-select:not(.ant-select-customize-input) .ant-select-selector {
          box-shadow: none !important;
        }
        // .ant-select-single .ant-select-selector .ant-select-selection-item {
        //   line-height: 32px;
        // }
        .ant-select-single .ant-select-selector .ant-select-selection-item, .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
          line-height: 32px !important;
        }
      }
    }
  }
}
.ant-select-dropdown {
  color: #eaedff !important;
  background: #11151ee5 !important;
  box-shadow: none !important;
  border: 1px solid rgba(255, 255, 255, 0.3);
  .ant-select-item {
    color: #eaedff;
  }
  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background: #ebc5b826;
  }
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background: #ebc5b826;
    color: #eaedff;
  }
}
.ant-table-striped{
    .show-elipsis{
        width: 518px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        word-break: break-word;
    }
}
</style>