import { get } from "../utils/request";
// 接口文档 http://doc.qiwoo.org/doc/view/

// 应用监测 -> 应用感染排行
export const getList = (params) => get('/security/cmcc/app/list', params);

// 应用监测 -> 应用覆盖设备感染分布
export const getAll = (params) => get('/security/cmcc/prov/all', params);

// 应用监测 -> 设备感染7日趋势
export const getTrend = (params) => get('/security/cmcc/7day_trend', params);

// 应用监测 -> 应用监测页面地图，每个省份app的感染数据
export const getProv = (params) => get('/security/cmcc/app/prov', params);

// 应用监测 -> 感染应用搜索
export const getSearch = (params) => get('/security/cmcc/app/search', params);

// 应用监测详情 -> 风险感染详情
export const getDetail = (params) => get('/security/cmcc/app/detail', params);

// 应用监测详情 -> 单个应用监测信息
export const getInfo = (params) => get('/security/cmcc/app/info', params);

// 应用监测详情 -> 详情页面地图中，各个省份的数据
export const getApp = (params) => get('/security/cmcc/prov/app', params);

// 应用监测详情 -> APP信息
export const getAppInfo = (params) => get('/app/apps', params);

// 应用监测详情 -> APP安装列表
export const getAppInstallList = (params) => get('/app/installs', params);

// 应用监测详情 -> APP安装列表
export const getAppInstallLifeCycle = (params) => get('/app/lifeCycle', params);