import { createApp } from 'vue';
import App from './App.vue';
import router from './router/index';
import { dispatchEventStroage } from './utils/index';
import 'ant-design-vue/dist/antd.css';
import Antd from 'ant-design-vue';

const app = createApp(App);
app.use(router);
app.use(Antd);
app.use(dispatchEventStroage);
app.mount('#app');
