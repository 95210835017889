<template>
  <div class="home-wrap">
    <div class="home-datepicker">
      <DatePicker @dataPickerTime="selectTime" />
    </div>
    <div class="home-wrap-left">
      <div class="left-device">
        <Card-border></Card-border>
        <div class="left-device-num">
          <div>
            <span class="text" @click="addColor('blue')">设备总数</span>
            <!-- <span class="number">{{deviceAll}}</span> -->
            <span class="device-number"
              ><CountUp
                :startVal="startDeviceAll"
                :endVal="deviceAll"
                index="0"
              ></CountUp
            ></span>
          </div>
          <div>
            <span class="text" @click="addColor('orange')">风险设备数</span>
            <!-- <span class="number">{{riskDevice}}</span> -->
            <span class="infect-number"
              ><CountUp
                :startVal="startRiskDevice"
                :endVal="riskDevice"
                index="1"
              ></CountUp
            ></span>
          </div>
        </div>
        <div class="left-device-box">
          <div v-for="(item, index) in devRank" :key="index">
            <div class="name">
              <div>风险设备</div>
              <div>{{ item.name }}</div>
            </div>
            <span class="value"
              ><CountUp
                :startVal="item.startValue"
                :endVal="item.value"
                :index="index + 22"
              >
              </CountUp
            ></span>
          </div>
        </div>
        <div class="left-device-map">
          <span class="title">风险设备分布</span>
          <div class="chart">
            <MapChart :seriesData="allGeoData" />
          </div>
        </div>
      </div>
      <div class="left-animation">
        <video
          v-for="(item, index) in videoList"
          :key="index"
          :class="item.class"
          :src="item.src"
          autoPlay="autoPlay"
          loop="loop"
          muted="muted"
        />
        <div class="left-animation-circle">
          <div v-for="(item, index) in dataList" :key="index">
            <!-- <span>{{dataList.length == (index + 1) ? item.value : item.name }}</span>
            <span>{{dataList.length == (index + 1) ? item.name : item.value}}</span> -->
            <span v-if="dataList.length !== index + 1"> {{ item.name }}</span>
            <CountUp
              v-if="dataList.length !== index + 1"
              :startVal="item.startValue"
              :endVal="
                item.name == '高风险设备'
                  ? item.value > 10
                    ? (item.value = 10)
                    : item.value
                  : item.value
              "
              :index="index + 33"
            ></CountUp>
            <CountUp
              v-if="dataList.length == index + 1"
              :startVal="item.startValue"
              :endVal="item.value"
              :index="index + 33"
            ></CountUp>
            <span v-if="dataList.length == index + 1"> {{ item.name }}</span>
            <!-- <span v-if="item.explain">({{item.explain}})</span> -->
          </div>
          <div></div>
        </div>
      </div>
      <div class="left-line">
        <span class="left-line-title">最近七日活跃用户</span>
        <div class="left-line-charts">
          <Card-border></Card-border>
          <LineChart :data="trendData" :chartIndex="0" />
        </div>
      </div>
      <div class="left-gauge">
        <Card-border></Card-border>
        <div class="left-gauge-title">
          仿冒影响设备数量
          <span class="left-gauge-number">{{ piracyList.inf }}</span>
        </div>
        <div class="left-gauge-box">
          <div
            class="left-gauge-box-inner"
            v-for="(value, key, index) in piracy"
            :key="index"
          >
            <div class="gauge">
              <!-- <GaugeChart :rateData="piracyList[key + '_rate'] || '0'" :rateName="piracyList[key + '_count'] || '0'" :rateIndex="index + 66"/> -->
              <GaugeChart
                :rateData="piracyList[key + '_rate'] || '0'"
                rateName="影响率"
                :rateIndex="index + 66"
              />
            </div>
            <div class="gaugeTitle">{{ value.value }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="home-wrap-right">
      <div class="right-infect">
        <div class="right-infect-name">
          <span>感染应用排行</span>
          <!-- <span>搜索</span> -->
        </div>
        <div class="right-infect-content">
          <Card :data="infectionCardData" />
        </div>
      </div>
      <div class="right-virus">
        <span class="right-virus-name">病毒木马排行</span>
        <div class="right-virus-rank">
          <Card-border></Card-border>
          <div class="right-virus-box">
            <div
              class="list"
              v-for="(item, index) in virusTopData"
              :key="index"
            >
              <span class="sort">{{ index + 1 }}</span>
              <div class="main">
                <span class="text">{{ item.ave }}</span>
                <div class="tag">
                  <span>设备感染</span>
                  <CountUp
                    v-if="item.startInf"
                    :startVal="item.startInf"
                    :endVal="item.inf"
                    :index="index + 55"
                  ></CountUp>
                  <span v-else>{{ item.inf }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import dayjs from "dayjs";
import CardBorder from "@/components/common/card-border.vue";
import MapChart from "@/components/common/map.vue";
import LineChart from '@/components/common/homeLine.vue';
import GaugeChart from '@/components/common/gauge.vue';
import Card from "@/components/common/card.vue";
import CountUp from "@/components/common/countup.vue";
import weatherIcon from "@/assets/images/weather.png";
import { getSummary, getGeo } from "@/api/riskControl.js";
import { getList } from "@/api/application.js";
import { getLevelTop } from "@/api/virus.js";
import DatePicker from '@/components/common/date-picker-one-day.vue';
import { getHomeSummary, getActiveUser, getPirateSummary } from "@/api/home.js";
import { addColor } from '@/utils/addColor.js';
let date = dayjs()
  .subtract(1, "day")
  .format("YYYY-MM-DD");
let lastTime = dayjs()
  .subtract(1, "day")
  .format("YYYY-MM-DD");
let lastWeekTime = dayjs()
  .subtract(6, "day")
  .format("YYYY-MM-DD");

export default {
  components: {
    CardBorder,
    MapChart,
    LineChart,
    GaugeChart,
    Card,
    CountUp,
    DatePicker
  },
  setup () {
    const deviceAll = ref('');
    const riskDevice = ref();
    const devRank = ref([]);
    const allGeoData = ref([]);
    const infectionCardData = ref([]);
    const virusTopData = ref([]);
    const trendData = ref([]);
    const piracyList = ref({});
    const startDeviceAll = ref();
    const startRiskDevice = ref();
    const videoList = ref([]);
    onMounted(() => {
      window.addEventListener('setItemEvent', function (e) {
        // 这里的info是我存入localstorage的key值
        if (e.key === "theme") {
          setTimeout(() => {
            getVideoConfig();
          }, 100);
        }
      });
    });
    const piracy = {
      // app: {
      //   value: '仿冒总体',
      // },
      ico: {
        value: '图标仿冒',
      },
      pn: {
        value: '包名仿冒',
      },
      appname: {
        value: '软件名仿冒',
      },
    };
    const devRankName = {
      xposed: "Xposed框架",
      root: "Root设备",
      ps: "双开",
      sdr: "模拟器"
    };
    const dataList = [
      {
        name: "高风险设备",
        startValue: '',
        value: "",
        explain: "",
        tag: "risk"
      },
      {
        name: "日活跃用户",
        startValue: '',
        value: "",
        explain: "",
        tag: "active"
      },
      {
        name: "仿冒应用",
        startValue: '',
        value: "",
        explain: "",
        tag: "piracy"
      },
      {
        name: "病毒木马",
        startValue: '',
        value: "",
        explain: "",
        tag: "virus"
      },
      {
        name: "感染设备",
        startValue: '',
        value: "",
        explain: "",
        tag: "inf"
      },
      {
        name: "安全指数",
        startValue: '',
        value: "",
        explain: "",
        tag: "score"
      }
    ];

    const rankCardData = [
      {
        appIcon: weatherIcon,
        appName: "360天气",
        infectNum: 9876,
        coverNum: 999333,
        infectRate: 50.4
      },
      {
        appIcon: weatherIcon,
        appName: "爱奇艺",
        infectNum: 9876,
        coverNum: 999333,
        infectRate: 32.5
      },
      {
        appIcon: weatherIcon,
        appName: "快手",
        infectNum: 9876,
        coverNum: 999333,
        infectRate: 23.4
      }
    ];
    const rankListData = ref([
      {
        content: "Riskware.AndroidT.Drolock.AI",
        tag: "感染设备",
        num: "10876"
      },
      {
        content: "Riskware.AndroidT.Drolock.AI",
        tag: "感染设备",
        num: "10876"
      },
      {
        content: "Riskware.AndroidT.Drolock.AI",
        tag: "感染设备",
        num: "10876"
      },
      {
        content: "Riskware.AndroidT.Drolock.AI",
        tag: "感染设备",
        num: "10876"
      },
      {
        content: "Riskware.AndroidT.Drolock.AI",
        tag: "感染设备",
        num: "10876"
      }
    ]);
    const getVideoConfig = () => {
      const path = localStorage.theme || 'orange';
      const list = [
        {
          class: "backgd",
          src: require(`@/assets/${path}/home01.webm`),
        },
        {
          class: "iphone",
          src: require(`@/assets/${path}/iphone.webm`),
        },
        {
          class: "itemone",
          src: require(`@/assets/${path}/item01.webm`),
        },
        {
          class: "itemtwo",
          src: require(`@/assets/${path}/item02.webm`),
        },
        {
          class: "itemthr",
          src: require(`@/assets/${path}/item03.webm`),
        },
        {
          class: "itemfou",
          src: require(`@/assets/${path}/item04.webm`),
        },
        {
          class: "itemfiv",
          src: require(`@/assets/${path}/item05.webm`),
        },
      ];
      videoList.value = list;
    };
    const selectTime = (val) => {
      date = val;
      lastWeekTime = dayjs(val).subtract(6, "day").format("YYYY-MM-DD");
      lastTime = dayjs(val).format("YYYY-MM-DD");
      getRiskGeo();
      getRiskSummary();
      getAppRank();
      getVirusTop();
      getSummaryData();
      getLastWeekuser();
      getVirusSummary();
      getVideoConfig();
    };
    // 风险设备数和设备总数
    const getRiskSummary = async () => {
      const res = (await getSummary({ dt: date })).data[0];
      if (res) {
        devRank.value = [];
        deviceAll.value = res.device_total;
        riskDevice.value = res.risk_device;
        Object.keys(res).map(key => {
          if (Object.keys(devRankName).includes(key)) {
            devRank.value.push({
              name: devRankName[key],
              value: res[key],
              startValue: '',
            });
          }
        });
      }
    };
    // 获取地图各个省份风险管控的数据
    const getRiskGeo = async () => {
      const { data = [] } = await getGeo({ dt: date });
      if (data.length > 0) {
        allGeoData.value = data.map(item => {
          return {
            name: item.geo,
            value: item.inf
          };
        });
      }
    };
    // 获取应用排行
    const getAppRank = async () => {
      const params = {
        sign: 0,
        page: 1,
        size: 5,
        dt: date
      };
      const { data } = await getList(params);
      infectionCardData.value = data.slice(0, 3);
      infectionCardData.value[1].startInf = '';
    };
    //病毒木马排行
    const getVirusTop = async () => {
      const { data } = await getLevelTop({ dt: date });
      virusTopData.value = data.top5 || [];
      if (virusTopData.value.lenth > 3) {
        virusTopData.value[1].startInf = '';
        virusTopData.value[2].startInf = '';
      }
      // virusTopData.value.forEach(val=>{
      //     val.level = data.level
      // })
    };
    //数据概览
    const getSummaryData = async () => {
      const { data } = await getHomeSummary({ dt: date });
      Object.keys(data).map(item => {
        dataList.map(i => {
          if (i.tag == item) {
            i.value = data[item];
          }
        });
      });
    };
    //近7日活跃用户数
    const getLastWeekuser = async () => {
      const { data = [] } = await getActiveUser({
        startDate: lastWeekTime,
        endDate: lastTime
      });
      if (data.length > 0) {
        trendData.value = data.map(item => {
          return {
            name: item.day,
            value: item.active_num,
          };
        });
      }

    };
    const getVirusSummary = async () => {
      const { data } = await getPirateSummary({ dt: date });
      piracyList.value = data;
    };

    return {
      dataList,
      rankCardData,
      rankListData,
      deviceAll,
      startDeviceAll,
      riskDevice,
      startRiskDevice,
      devRank,
      allGeoData,
      infectionCardData,
      virusTopData,
      // analogData,
      trendData,
      piracy,
      piracyList,
      addColor,
      videoList,
      selectTime
    };
  }
};
</script>

<style scoped lang="less">
@import url('./index.less');
</style>
