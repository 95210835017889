<template>
  <div class="lineChart" id="lineChart"></div>
</template>

<script>
import * as echarts from 'echarts';
import { toRefs, computed, onMounted, watch, onBeforeUnmount } from 'vue'

export default {
  props: {
    data: {
      type: Array,
      default: () => {[]},
    }
  },
  setup(props) {
    const { data } = toRefs(props);
    let myChart = {};

    const lineOptions = computed(() => {
      return {
        tooltip: {
          trigger: 'axis',
          backgroundColor: '#11151ee5',
          padding: 16,
          textStyle: {
            color: '#eaedff'
          },
          extraCssText: 'border: 1px dotted #eaedff99;border-radius:10px',
          formatter: function (params) {
            return `<div>${params[0].data[0]}</br><ul style='padding:10px 16px 0px;'><li style='list-style-type:disc;'>感染设备：${params[0].data[1]}</li><li style='list-style-type:disc;'>覆盖设备：${params[0].data[2]}</li><li style='list-style-type:disc;'>感染率：${params[0].data[3]}%</li></ul></div>`;
          }
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          nameTextStyle: {
            color: '#9FA2B1',
            fontSize: 12
          },
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
        },
        yAxis: {
          type: 'value',
          splitLine: {
            lineStyle:{
                color:"#EAEDFF",
                opacity:0.09,
            }
          },
        },
        grid: {
            left: 50,
            right: 40,
            top: 30,
            bottom: 35,
        },
        dataZoom:{
            show: data.value.length > 12,
            startValue: data.value.length > 12 ? data.value.length - 12 : 0,
            endValue: data.value.length - 1,
            zoomLock: true,
            bottom: -20
        },
        series: [{
          name: '病毒木马感染趋势',
          type: 'line',
          itemStyle: {
            color: '#eaedff',
            borderColor: '#ffffff54'
          },
          lineStyle: {
            color: '#ac524b'
          },
          smooth: true,
          areaStyle: {
            color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [
              {
                offset: 0,
                color: 'rgba(187,113,80, 0.2)'
              },
              {
                offset: 1,
                color: 'rgba(187,113,80, 0.8)'
              }
            ])
          },
          data: data.value.map(item => {
              return [item.day, item.inf, item.cov, item.rate]
          })
        }]
      }
    })

    function lineChart () {
      let lineChart = echarts.getInstanceByDom(document.getElementById('lineChart'));
      if (lineChart == null) {
        lineChart = echarts.init(document.getElementById('lineChart'));
      }
      lineChart.setOption(lineOptions.value);
      myChart = lineChart
    }

    const resizeChart = () => {
      myChart.resize()
    }

    onMounted(() => {
      lineChart();
      window.addEventListener(
        "resize", resizeChart
      )
    });

    onBeforeUnmount(() => {
      window.removeEventListener(
          "resize", resizeChart
      )
    })

    // 监听传值，刷新图表
    watch(data, () => {
      lineChart();
    });
    
  },
}
</script>

<style scoped lang="less">
.lineChart {
  width: 100%;
  height: 100%;
}
</style>
