<template>
  <div class="line-charts" id="line-charts"></div>
</template>

<script>
import * as echarts from 'echarts';
import dayjs from 'dayjs';
import { toRefs, computed, onMounted, watch, onBeforeUnmount } from 'vue';
import { getThemeColor } from '@/utils/addColor.js';
export default {
  props: {
    lineData: {
      type: Object,
      default: () => { []; }
    }
  },
  setup (props) {
    const { lineData } = toRefs(props);
    let myChart = {};
    const themStyles = getThemeColor();

    const lineOptions = computed(() => {
      const percent = (param) => {
        return Math.round(param * 10000) / 100;
      };
      const colorList = themStyles["--environmentLineCharts"]['colorList'];
      const nameList = Object.keys(lineData.value);
      const time = lineData.value[nameList[0]] && lineData.value[nameList[0]].map(item => dayjs(item.day).format('MM/DD'));
      let seriesData = [];
      nameList.map((item, index) => {
        const series = {
          name: item,
          type: 'line',
          // stack: 'Total',
          lineStyle: {
            color: colorList[index] + ', 1)'
          },
          itemStyle: {
            color: colorList[index] + ', 1)'
          },
          showSymbol: false,
          areaStyle: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 1,
                color: colorList[index] + ', 0)'
              },
              {
                offset: 0,
                color: colorList[index] + ', 0.4)'
              }]),
          },
          data: lineData.value[item].map(item => percent(item.ratio))
        };
        seriesData.push(series);
      });
      return {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'line',
            lineStyle: {
              opacity: 0
            },
          },
          backgroundColor: '#11151ee5',
          padding: 16,
          textStyle: {
            color: '#eaedff'
          },
          valueFormatter: (value) => {
            return value + "%";
          },
          extraCssText: 'border: 1px dotted #eaedff99'
        },
        legend: {
          right: '0',
          data: nameList,
          icon: 'rect',
          itemWidth: 10,
          itemHeight: 4,
          textStyle: {
            color: '#9FA2B1'
          },
        },
        grid: {
          left: '0',
          top: '10%',
          right: '5%',
          bottom: '3%',
          containLabel: true,
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          axisTick: {
            show: false
          },
          axisLine: {
            lineStyle: {
              color: 'rgba(255, 255, 255, 0.2)'
            }
          },
          axisLabel: {
            show: true,
            color: '#9FA2B1',
            fontSize: 12
          },
          data: time
        },
        yAxis: {
          type: 'value',
          max: 100,
          axisLabel: {
            color: '#9FA2B1',
            formatter: '{value} %'
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: 'rgba(255, 255, 255, 0.05)'
            }
          }
        },
        series: seriesData
      };
    });

    const lineCharts = () => {
      let lineCharts = echarts.getInstanceByDom(document.getElementById('line-charts'));
      if (lineCharts == null) {
        lineCharts = echarts.init(document.getElementById('line-charts'));
      }
      lineCharts.setOption(lineOptions.value);
      myChart = lineCharts;
    };

    const resizeChart = () => {
      myChart.resize();
    };

    onMounted(() => {
      lineCharts();
      window.addEventListener(
        "resize", resizeChart
      );
    });

    onBeforeUnmount(() => {
      window.removeEventListener(
        "resize", resizeChart
      );
    });

    // 监听传值，刷新图表
    watch(lineData, () => {
      lineCharts();
    });
  }
}
</script>

<style scoped lang="less">
.line-charts {
  width: 100%;
  height: 100%;
}
</style>