import { get, post } from "../utils/request";
import qs from "qs"
// 接口文档 http://doc.qiwoo.org/doc/view/5880

// 数据分析 -> 数据概览
export const getAllNumber = (params) => get('/appstat/ajaxtop', params);

// 数据分析 -> 活跃设备
export const getActiveDevice = (params) => get('/security/summaryDevice', params);

// 数据分析 -> 终端分析 -> 类型列表
export const terminalList = (urlParam, params) => {
  return post(`/appstat/ajaxdetail?m=${urlParam.m}&pn=${urlParam.pn}&sign=${urlParam.sign}`, qs.stringify(params));
}

// 数据分析 -> 终端分析 -> 启动次数列表
export const startUpNumList = (urlParam, params) => {
  return post(`/appstat/startuptimes?pn=${urlParam.pn}&sign=${urlParam.sign}`, qs.stringify(params));
}

// 数据分析 -> 用户分析 -> 留存率
export const userRateList = (urlParam, params) => {
  return post(`/appstat/ajax?m=${urlParam.m}&pn=${urlParam.pn}&sign=${urlParam.sign}`, qs.stringify(params));
}

// 数据分析 -> 用户分析 -> 用户数量趋势
export const userTrendList = (urlParam, params) => {
  return post(`/appstat/usertrend?pn=${urlParam.pn}&sign=${urlParam.sign}`, qs.stringify(params));
}

// 数据分析 -> 用户分析 -> 新用户留存（近7日）
export const newUserRemainList = (urlParam, params) => {
  return post(`/appstat/summaryRemainanalyse?pn=${urlParam.pn}&sign=${urlParam.sign}`, qs.stringify(params));
}