<template>
  <div class="pieCharts" :id="domId"></div>
</template>

<script>
import * as echarts from 'echarts';
import { toRefs, computed, onMounted, watch, onBeforeUnmount } from 'vue';
import { getThemeColor } from '@/utils/addColor.js';

export default {
  props: {
    pieData: {
      type: Array,
      default: () => { []; }
    },
    domId: {
      type: String,
      default: () => { "pieCharts"; }
    },
  },
  setup (props) {
    const { pieData } = toRefs(props);
    let myChart = {};
    const themStyles = getThemeColor();

    const pieCharts = () => {
      const pieOptions = computed(() => {
        return {
          tooltip: {
            trigger: 'item',
            formatter: '{b}: {c} ({d}%)',
            backgroundColor: '#11151ee5',
            padding: 16,
            textStyle: {
              color: '#eaedff'
            },
            extraCssText: 'border: 1px dotted #eaedff99',
          },
          legend: {
            type: 'scroll',
            top: 'center',
            right: '0',
            orient: 'vertical',
            icon: 'circle',
            itemWidth: 12,
            itemHeight: 12,
            textStyle: {
              color: '#9FA2B1',
              fontSize: 12,
              fontWeight: 400,
              ellipsis: '...',
              overflow: 'truncate',
              width: 100,
            },
          },
          color: themStyles["--appSafePie"]["colorList"],
          series: [
            {
              type: 'pie',
              radius: ['60%', '90%'],
              left: '-30%',
              avoidLabelOverlap: false,
              itemStyle: {
                // 此配置
                opacity: 0,
                normal: {
                  borderWidth: 4,
                  borderColor: '#10151e',

                },
                emphasis: {
                  borderWidth: 10,
                  borderColor: '#10151e',
                },
              },
              zlevel: 1,
              label: {
                normal: {
                  padding: [2, 2, 2, 2],
                  backgroundColor: '#10151e',
                  show: false,
                  position: 'center',
                  ellipsis: '...',
                  overflow: 'truncate',
                  width: 100,
                  formatter: function ({ name, value }) { // 图例后显示值
                    return [`{name|${name}}`, `{value|${value}}`].join('\n');
                  },
                  rich: {
                    value: {
                      color: '#EAEDFF',
                      fontSize: 24,
                      lineHeight: 24,
                      fontFamily: 'Azonix'
                    },
                    name: {
                      color: '#9FA2B1',
                      fontSize: 14,
                      lineHeight: 35,
                      align: 'center'
                    },
                  },
                },
                emphasis: {
                  show: true,
                  textStyle: {
                    fontSize: '16',
                    fontWeight: 'bold',
                  },
                },
              },
              labelLine: {
                normal: {
                  show: false,
                },
              },
              data: pieData.value || []
            }
          ]
        };
      });
      let pieCharts = echarts.getInstanceByDom(document.getElementById(props.domId));
      if (pieCharts == null) {
        pieCharts = echarts.init(document.getElementById(props.domId));
      }
      pieCharts.setOption(pieOptions.value);
      myChart = pieCharts;
    };

    const resizeChart = () => {
      myChart.resize();
    };

    onMounted(() => {
      pieCharts();
      window.addEventListener(
        "resize", resizeChart
      );
    });

    onBeforeUnmount(() => {
      window.removeEventListener(
        "resize", resizeChart
      );
    });

    // 监听传值，刷新图表
    watch(pieData, () => {
      pieCharts();
    });
  }
}
</script>

<style scoped lang="less">
.pieCharts {
  width: 100%;
  height: 100%;
}
</style>