<template>
  <div class="dataAnalysis-wrap">
    <div
      class="dataAnalysis-wrap-tabs"
      :class="moduleRadio == 1 ? 'terminalClass' : 'userClass'"
    >
      <a-radio-group v-model:value="moduleRadio">
        <a-radio-button value="1">终端分析</a-radio-button>
        <a-radio-button value="2">用户分析</a-radio-button>
      </a-radio-group>
    </div>
    <div class="dataAnalysis-wrap-module">
      <TerminalModule v-if="moduleRadio == 1"></TerminalModule>
      <UserModule v-if="moduleRadio == 2"></UserModule>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import { Radio } from 'ant-design-vue';
import TerminalModule from './terminal.vue';
import UserModule from './user.vue';
export default {
  components: {
    TerminalModule,
    UserModule,
    [Radio.Group.name]: Radio.Group,
    [Radio.Button.name]: Radio.Button
  },
  setup () {
    const moduleRadio = ref('1');

    return { moduleRadio };
  }
}
</script>

<style scoped lang="less">
@import url(./index.less);
</style>
