<template>
  <div class="rank-table">
    <div class="rank-table-title">
      <span>IP</span>
      <span>设备数</span>
    </div>
    <div
      class="rank-table-item"
      v-for="(item, index) in rankTableData"
      :key="index"
    >
      <span class="rank-table-sort">{{ index + 1 }}</span>
      <span class="rank-table-text">{{ item.imei }}</span>
      <span class="rank-table-num">{{ item.num }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    rankTableData: Array
  },
  setup () { }
}
</script>

<style scoped lang="less">
.rank-table {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  div {
    width: 100%;
    height: 40px;
    padding: 0 24px;
    display: flex;
    align-items: center;
    &:nth-child(2) {
      margin-top: 16px;
    }
  }
  &-title {
    background: var(--commonTableHeadBg);
    color: var(--commonTableHeadTextColor);
    border-radius: 8px;
    font-size: 14px;
    justify-content: space-between;
  }
  &-item {
    background: rgba(255, 255, 255, 0.05);
    border: 1px solid rgba(234, 237, 255, 0.3);
    border-radius: 8px;
    margin-top: 8px;
  }
  &-sort {
    width: 22px;
    height: 22px;
    background-image: url('../../../assets/images/application/sort.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    line-height: 22px;
    text-align: center;
    display: block;
  }
  &-text {
    font-size: 14px;
    margin-left: 8px;
    width: 70%;
  }
  &-num {
    color: #ffb58f;
    width: 30%;
    text-align: right;
  }
}
</style>
