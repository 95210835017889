<template>
  <div class="mapChart" id="mapChart"></div>
</template>

<script>
import * as echarts from 'echarts';
import { toRefs, computed, onMounted, watch, onBeforeUnmount } from 'vue';
import { getThemeColor } from '@/utils/addColor.js';
import axios from 'axios';
export default {
  props: {
    seriesData: {
      type: Array,
      default: () => { []; }
    },
    data: {
      type: Array,
      default: () => { []; }
    },
    showVisualMap: {
      type: Boolean,
      default: true
    }
  },
  setup (props, vm) {
    const { seriesData, showVisualMap } = toRefs(props);
    const themStyles = getThemeColor();
    let myChart = {};
    const setOptions = computed(() => {
      let options = {
        tooltip: {
          trigger: 'item',
          backgroundColor: '#11151ee5',
          padding: 16,
          textStyle: {
            color: '#eaedff'
          },
          extraCssText: 'border: 1px dotted #eaedff99'
        },
        geo: {
          map: 'china',
          roam: false,
          zoom: 1.23,
          top: 'center',
          label: {
            show: false
          },
          itemStyle: {
            normal: {
              color: {
                type: 'linear-gradient',
                x: 150,
                y: 20,
                x2: 100,
                y2: 450,
                colorStops:themStyles["--mainMapConfig"]["bgColor"],
                global: true,// 缺省为 false
              },
              borderWidth: 1, //设置外层边框
              borderColor: themStyles["--mainMapConfig"]["borderColor"],
              shadowColor: themStyles["--mainMapConfig"]["borderColor"],
            },
            emphasis: {
              show: false,
              areaColor:null,
            }
          }
        },
        visualMap: [{
          type: 'continuous',
          inRange: {
            color: [
              themStyles["--mainMapColor"],
              `rgba(${themStyles["--mainMapColorRgb"]}, 0.3)`,
              `rgba(${themStyles["--mainMapColorRgb"]}, 1)`
            ]
          },
          textStyle: {
            color: '#CC6666'
          },
          show: showVisualMap.value
        }],
        series: [
          {
            name: '感染设备',
            type: 'map',
            map: 'china',
            roam: false,
            zoom: 1.23,
            top: 'center',
            itemStyle: {
              normal: {
                areaColor:'rgba(128, 128, 128, 0)',
                borderColor: '#EAEDFF',
                // borderWidth: 0.72,
              },
              emphasis: {
                  show: false,
                  areaColor: null
              }
            },
            emphasis: {
              label: {
                color: '#eaedff'
              },
              itemStyle: {
                areaColor: 'none'
              }
            },
            data: seriesData.value
          },
        ]
      };
      // 设置颜色最大值
      let max = 0;
      seriesData.value?.forEach((item) => {
        let value = Number(item.value)
        if (value > max) {
          max = value
        }
      })
      if (max) {
        options.visualMap[0].min = 0;
        options.visualMap[0].max = max;
      }
      return options;
    });

    const initChart = () => {
      axios.get('/6507d9a98a1e476e.json').then(geoJson => {
        echarts.registerMap('china', { geoJSON: geoJson.data });
        let mapChart = echarts.getInstanceByDom(document.getElementById('mapChart'));
        if (!mapChart) {
          mapChart = echarts.init(document.getElementById('mapChart'));
        } 
        mapChart.setOption(setOptions.value);
        myChart = mapChart;

        mapChart.on('mouseover', (params) => {
          vm.emit('mouseoverMap', params.name);
        });
      });
    }

    const resizeChart = () => {
      myChart.resize();
    };

    onMounted(() => {
      initChart();
      window.addEventListener(
        "resize", resizeChart
      );
    });

    onBeforeUnmount(() => {
      window.removeEventListener(
        "resize", resizeChart
      );
    });

    // 监听传值，刷新图表
    watch(seriesData, () => {
      initChart();
    });
  },
}
</script>

<style lang="less" scoped>
.mapChart {
  width: 100%;
  height: 100%;
}
</style>
