<template>
  <div class="crashLog-details">
    <div class="crashLog-details-top">
      <span class="top-back" @click="goBack">
        <img :src="getImgPath('application/back.png')" />
      </span>
      <span class="top-title">日志标识信息</span>
    </div>
    <div class="crashLog-details-bottom">
      <div class="bottom-left">
        <Card-border></Card-border>
        <span class="bottom-left-title">崩溃机型top25</span>
        <a-table
          class="ant-table-striped"
          size="middle"
          :columns="columns"
          :data-source="riskData"
          :rowClassName="
            (record, index) => (index % 2 === 1 ? 'table-striped' : null)
          "
        >
        </a-table>
      </div>
      <div class="bottom-right">
        <div class="bottom-right-box">
          <Card-border></Card-border>
          <span class="bottom-right-title">崩溃次数趋势</span>
          <div class="bottom-right-chart">
            <LineChart :lineData="detailAnalyse" :chartIndex="0" />
          </div>
        </div>
        <div class="bottom-right-box">
          <Card-border></Card-border>
          <span class="bottom-right-title">影响用户趋势</span>
          <div class="bottom-right-chart">
            <LineChart :lineData="detailTrend" :chartIndex="1" />
          </div>
        </div>
        <div class="bottom-right-box">
          <Card-border></Card-border>
          <span class="bottom-right-title">崩溃系统排行</span>
          <div class="bottom-right-chart">
            <BarChart :barData="detailOsData" :chartIndex="0" />
          </div>
        </div>
        <div class="bottom-right-box">
          <Card-border></Card-border>
          <span class="bottom-right-title">崩溃机型排行</span>
          <div class="bottom-right-chart">
            <BarChart :barData="detailModelData" :chartIndex="1" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import CardBorder from '@/components/common/card-border.vue';
import { Table } from 'ant-design-vue';
import router from '@/router/index';
import { getTypeTop, getDayCount, getTopOS, getTopModel } from '@/api/crashLog.js';
import BarChart from './common/bar.vue';
import LineChart from './common/dotted-line.vue';
import { getImgPath } from '@/utils/addColor.js';
const columns = [
  {
    title: '排序',
    customRender: ({ index }) => {
      return index + 1;
    },
    dataIndex: 'sort',
    key: 'sort',
    width: 80
  },
  {
    title: '崩溃机型',
    dataIndex: 'modelOS',
    key: 'modelOS',
    width: 200
  },
  {
    title: '系统版本',
    dataIndex: 'o',
    key: 'o'
  },
  {
    title: '崩溃次数',
    dataIndex: 'docCount',
    key: 'docCount'
  },
  {
    title: '崩溃进程',
    dataIndex: 'ep',
    key: 'ep',
    width: 300,
    ellipsis: true
  }
];
export default {
  components: {
    CardBorder,
    BarChart,
    LineChart,
    [Table.name]: Table
  },
  setup () {
    const queryParams = router.currentRoute.value.query;
    const riskData = ref([]);
    const detailAnalyse = ref([]);
    const detailTrend = ref([]);
    const detailOsData = ref([]);
    const detailModelData = ref([]);

    const goBack = () => {
      window.history.go(-1);
    };

    // 获取崩溃机型TOP25
    const getLogTypeTop = async () => {
      const { data } = await getTypeTop({
        appKey: queryParams.appKey,
        pn: queryParams.pn,
        meVnT: queryParams.meVnT,
        limit: ''
      });
      riskData.value = data;
    };

    // 崩溃总体趋势
    const getLogDayCount = async () => {
      const { data } = await getDayCount({
        appKey: queryParams.appKey,
        pn: queryParams.pn,
        meVnT: queryParams.meVnT,
        version: queryParams.version,
        type: queryParams.type,
        startDate: queryParams.startDate,
        endDate: queryParams.endDate
      });
      detailAnalyse.value = data.map(item => {
        return {
          time: item.dateTime,
          count: item.personNum // 影响用户数
        };
      });
      detailTrend.value = data.map(item => {
        return {
          time: item.dateTime,
          count: item.docCount // 崩溃次数
        };
      });
    };

    // 获取崩溃机型排行
    const getLogTopModel = async () => {
      const { data } = await getTopModel({
        appKey: queryParams.appKey,
        pn: queryParams.pn,
        meVnT: queryParams.meVnT,
        timeType: '',
        limit: ''
      });
      data.map(item => {
        detailModelData.value.push({
          name: item.model,
          value: item.docCount
        });
      });
    };

    // 获取崩溃系统排行
    const getLogTopOS = async () => {
      const { data } = await getTopOS({
        appKey: queryParams.appKey,
        pn: queryParams.pn,
        meVnT: queryParams.meVnT,
        timeType: '',
        limit: ''
      });
      data.map(item => {
        detailOsData.value.push({
          name: item.os,
          value: item.docCount
        });
      });
    };

    getLogTypeTop();
    getLogDayCount();
    getLogTopModel();
    getLogTopOS();

    return { getImgPath, goBack, columns, riskData, detailAnalyse, detailTrend, detailOsData, detailModelData };
  }
}
</script>

<style scoped lang="less">
.crashLog-details {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
  &-top {
    display: flex;
    height: 38px;
    align-items: center;
    margin-bottom: 24px;
    .top-back {
      width: 38px;
      height: 38px;
      cursor: pointer;
      display: block;
      img {
        width: 100%;
      }
    }
    .top-title {
      margin-left: 16px;
      font-size: 24px;
    }
  }
  &-bottom {
    display: flex;
    height: calc(100% - 62px);
    span {
      display: block;
    }
    .bottom-left {
      position: relative;
      flex: 1;
      margin-right: 22px;
      padding: 24px;
      &-title {
        font-size: 18px;
        line-height: 28px;
        margin-bottom: 24px;
      }
    }
    .bottom-right {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      &-title {
        font-size: 18px;
        line-height: 28px;
      }
      div:first-child {
        margin-top: 0;
      }
      &-box {
        height: 22%;
        position: relative;
        width: 100%;
        padding: 24px;
      }
      &-chart {
        width: 100%;
        margin-top: 5px;
        height: 80%;
      }
    }
  }
}
</style>