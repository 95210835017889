import { get } from "../utils/request";
// 接口文档 http://doc.qiwoo.org/doc/view/5890

// 病毒木马 -> 感染设备数据概览
export const getSummary = (params) => get('/virus/summarydevice', params);

// 病毒木马 -> 风险等级
export const getLevel = (params) => get('/virus/risklevel', params);

// 病毒木马 -> 感染设备Top5
export const getLevelTop = (params) => get('/virus/top', params);

// 病毒木马 -> 各个省份数据
export const getGeo = (params) => get('/virus/prov', params);

// 病毒木马 -> 感染趋势
export const getTrend = (params) => get('/virus/trend', params);

// 病毒木马 -> 详情页面
export const getDetail = (params) => get('/risk/detail', params);

