<template>
  <div class="boards-wrap">
    <div class="boards-wrap-left">
      <div class="boards-wrap-box">
        <span class="boards-wrap-title">品牌活跃指数</span>
        <div class="boards-wrap-chart">
          <CardBorder></CardBorder>
          <div class="device-attack-mobile">
            <span class="device-attack-title">移动设备品牌排名</span>
            <div class="device-attack-mobile-main">
              <div class="device-attack-chart">
                <BarChart
                  :barChartData="brandDevData"
                  :barIndex="0"
                  @handleClickBar="handleClickBar"
                />
              </div>
            </div>
          </div>
          <div class="device-attack-brand">
            <div>
              <span class="device-attack-name">{{ brandName }}</span>
              <span class="device-attack-title">品牌覆盖率变化趋势</span>
            </div>
            <LinesChart :data="vendorTrendData" :linesIndex="0" />
          </div>
        </div>
      </div>
      <div class="chart-space">
        <div class="boards-wrap-card card-space">
          <div>
            <span class="boards-wrap-title">设备联网方式</span>
            <!-- <span>|</span>
          <div>日历</div> -->
          </div>
          <div class="boards-wrap-chart">
            <CardBorder></CardBorder>
            <LineCharts :lineData="netTrendData" />
          </div>
        </div>
        <div class="boards-wrap-card">
          <div>
            <span class="boards-wrap-title">设备分辨率</span>
            <!-- <span>|</span>
          <div>日历</div> -->
          </div>
          <div class="boards-wrap-chart">
            <CardBorder></CardBorder>
            <BarCharts :barChartsData="ratioDevData" />
          </div>
        </div>
      </div>
    </div>
    <div class="boards-wrap-right">
      <div class="boards-wrap-box">
        <div>
          <span class="boards-wrap-title">操作系统分布</span>
          <!-- <span>|</span>
        <div>日历</div> -->
        </div>
        <div class="boards-wrap-chart">
          <CardBorder></CardBorder>
          <TreeMap :treeMapData="OsTrendData" />
        </div>
      </div>
      <div class="chart-space">
        <span class="boards-wrap-title">设备活跃指数</span>
        <div class="boards-wrap-chart">
          <CardBorder></CardBorder>
          <div class="device-attack-mobile">
            <span class="device-attack-title">移动设备机型排名</span>
            <div class="device-attack-mobile-main">
              <div class="device-attack-chart">
                <BarChart
                  :barChartData="modelDevData"
                  :barIndex="1"
                  @handleClickBar="handleClickBar"
                />
              </div>
            </div>
          </div>
          <div class="device-attack-brand">
            <div>
              <span class="device-attack-name">{{ modelName }}</span>
              <span class="device-attack-title">移动设备机型变化趋势</span>
            </div>
            <LinesChart :data="modelTrendData" :linesIndex="1" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, toRefs, watch } from 'vue';
import { getVendor, getVendorTrend, getDpi, getOstrend, getModel, getModelTrend, getNetworkTrend } from '@/api/environment.js';
import CardBorder from '@/components/common/card-border.vue';
import BarChart from '@/components/common/bar-chart.vue';
import LinesChart from './common/lines.vue';
import LineCharts from './common/line-charts.vue';
import BarCharts from './common/bar-charts.vue';
import TreeMap from './common/tree-map.vue';
export default {
  props: {
    timePicker: Object
  },
  components: {
    CardBorder,
    BarChart,
    LinesChart,
    LineCharts,
    BarCharts,
    TreeMap
  },
  setup (props) {
    const { timePicker } = toRefs(props);
    const brandDevData = ref([]);
    const vendorTrendData = ref([]);
    const modelDevData = ref([]);
    const modelTrendData = ref([]);
    const ratioDevData = ref([]);
    const OsTrendData = ref([]);
    const netTrendData = ref({});
    const brandName = ref('');
    const modelName = ref('');

    // 点击移动设备品牌排名
    const handleClickBar = (name, type) => {
      if (type === 0) {
        brandName.value = name;
        getEnvVendorTrend();
      } else {
        modelName.value = name;
        getEnvModelTrend();
      }
    };

    // 获取移动设备品牌排名
    const getEnvVendor = async () => {
      const { data } = await getVendor(timePicker.value);
      brandDevData.value = data;
      brandName.value = data[0].name;
      getEnvVendorTrend();
    };
    // 获取移动设备品牌覆盖率变化趋势
    const getEnvVendorTrend = async () => {
      const { data } = await getVendorTrend({
        st: timePicker.value.st,
        et: timePicker.value.et,
        dt: timePicker.value.dt,
        ve: brandName.value
      });
      vendorTrendData.value = data;
    };
    // 获取移动设备机型排名
    const getEnvModel = async () => {
      const { data } = await getModel(timePicker.value);
      modelDevData.value = data;
      modelName.value = data[0].name;
      getEnvModelTrend();
    };
    // 获取移动设备机型品牌覆盖率变化趋势
    const getEnvModelTrend = async () => {
      const { data } = await getModelTrend({
        st: timePicker.value.st,
        et: timePicker.value.et,
        dt: timePicker.value.dt,
        model: modelName.value
      });
      modelTrendData.value = data;
    };
    // 获取设备联网方式
    const getEnvNetworkTrend = async () => {
      const { data } = await getNetworkTrend(timePicker.value);
      netTrendData.value = data;
    };
    // 获取设备分辨率
    const getEnvDpi = async () => {
      const { data } = await getDpi(timePicker.value);
      ratioDevData.value = data;
    };
    // 获取操作系统分布
    const getEnvOstrend = async () => {
      const { data } = await getOstrend(timePicker.value);
      OsTrendData.value = data;
    };
    // 监听传值
    watch(timePicker.value, () => {
      getEnvVendor();
      getEnvDpi();
      getEnvOstrend();
      getEnvModel();
      getEnvNetworkTrend();
    });

    getEnvVendor();
    getEnvDpi();
    getEnvOstrend();
    getEnvModel();
    getEnvNetworkTrend();

    return { vendorTrendData, brandDevData, modelDevData, modelTrendData, ratioDevData, OsTrendData, netTrendData, handleClickBar, brandName, modelName };
  }
}
</script>

<style scoped lang="less">
.boards-wrap {
  display: flex;
  height: 100%;
  &-left {
    width: 50%;
    height: 100%;
  }
  &-box {
    height: 50%;
  }
  &-title {
    font-size: 18px;
    line-height: 32px;
  }
  &-card {
    width: 49%;
    height: 100%;
    display: inline-block;
  }
  &-chart {
    width: 100%;
    height: calc(100% - 32px);
    position: relative;
    padding: 24px;
    display: flex;
  }
  &-right {
    width: 50%;
    height: 100%;
    margin-left: 24px;
  }
  .card-space {
    margin-right: 2%;
  }
  .chart-space {
    padding-top: 25px;
    height: 50%;
  }
  .device-attack-mobile {
    width: 41%;
    height: 100%;
    border-right: 1px solid rgba(255, 255, 255, 0.2);
    &-main {
      height: 90%;
      overflow-y: scroll;
    }
  }
  .device-attack-brand {
    width: 59%;
    height: 100%;
    padding-left: 24px;
  }
  .device-attack-name {
    font-size: 14px;
    color: #fce8cc;
    margin-right: 8px;
  }
  .device-attack-title {
    font-size: 14px;
    color: #9fa2b1;
  }
  .device-attack-chart {
    height: 600px;
    margin-right: 5px;
    // 滚动条设置
    &::-webkit-scrollbar {
      width: 5px;
    }
    &::-webkit-scrollbar-track {
      width: 0;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background: #585c61;
    }
    &::-webkit-scrollbar-thumb:hover {
      width: 0;
    }
    &::-webkit-scrollbar-thumb:active {
      border-radius: 10px;
      background: #585c61;
    }
  }
}
</style>
