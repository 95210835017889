<template>
  <div class="card-border">
    <span></span>
    <span></span>
    <span></span>
    <span></span>
  </div>
</template>

<script>
export default {
  setup () { }
}
</script>

<style scoped lang="less">
.card-border {
  position: absolute;
  left: 0;
  top: 0;
  border: 1px dotted var(--cardBorderRedBorderColor);
  border-radius: 16px;
  width: 100%;
  height: 100%;
  span {
    width: 18px;
    height: 18px;
    display: inline-block;
    position: absolute;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    &:first-child {
      left: -1px;
      top: -1px;
      background-image: var(--cardBorderRedTopLeft);
    }
    &:nth-child(2) {
      left: -1px;
      top: calc(100% - 17px);
      background-image: var(--cardBorderRedBottomLeft);
    }
    &:nth-child(3) {
      top: -1px;
      left: calc(100% - 17px);
      background-image: var(--cardBorderRedTopRight);
    }
    &:nth-child(4) {
      top: calc(100% - 17px);
      left: calc(100% - 17px);
      background-image: var(--cardBorderRedBottomRight);
    }
  }
}
</style>
