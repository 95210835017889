<template>
    <div class="pieChart" :id="'pieChart' + pieIndex"></div>
</template>

<script>
import * as echarts from 'echarts';
import dayjs from 'dayjs';
import { toRefs, computed, onMounted, watch, onBeforeUnmount } from 'vue';
import { getThemeColor } from '@/utils/addColor.js';

export default {
    props: {
        data: {
            type: Array,
            default: () => { []; }
        },
        pieIndex: {
            type: Number,
            default: 0
        },
        colorPie: {
            type: Array,
            default: ['#57C1FF', '#4DADEC', '#449AD8', '#3B87C5', '#3174B1', '#28629E', '#1F518B', '#1F518B', '#154077', '#0A3065', '#002152'],
        }
    },
    setup(props) {
        const { data } = toRefs(props);
        let myChart = {};
        const themStyles = getThemeColor();
        const pieOptions = computed(() => {
            return {
                // title: {
                //     zlevel: -1,
                //     text: [`{name|${data.value[0] ? data.value[0].name : ''}}`, `{value|${data.value[0] ? data.value[0].value : ''}}`].join('\n'),
                //     top: 'center',
                //     left: 'center',
                //     textStyle: {
                //         ellipsis: '...',
                //         overflow: 'truncate',
                //         width: 100,
                //         rich: {
                //             value: {
                //                 fontFamily: 'Azonix',
                //                 color: '#EAEDFF',
                //                 fontSize: 24,
                //                 lineHeight: 24,
                //             },
                //             name: {
                //                 color: '#9FA2B1',
                //                 fontSize: 14,
                //                 lineHeight: 35,
                //                 align: 'center'
                //             },
                //         },
                //     }
                // },
                tooltip: {
                    trigger: 'item',
                    formatter: '{b}: {c} ({d}%)',
                    backgroundColor: '#11151ee5',
                    padding: 16,
                    textStyle: {
                        color: '#eaedff'
                    },
                    extraCssText: 'border: 1px dotted #eaedff99',
                },
                legend: {
                    type: 'scroll',
                    top: 'center',
                    left: '70%',
                    orient: 'vertical',
                    icon: 'circle',
                    itemWidth: 12,
                    itemHeight: 12,
                    textStyle: {
                        color: '#9FA2B1',
                        fontSize: 12,
                        fontWeight: 400,

                        ellipsis: '...',
                        overflow: 'truncate',
                        width: 70,
                    }
                },
                color: props.colorPie,
                emphasis: {
                    scaleSize: 20,
                },
                series: [
                    {
                        // name: 'Access From',
                        type: 'pie',
                        radius: ['60%', '90%'],
                        left: '-30%',
                        avoidLabelOverlap: false,
                        itemStyle: {
                            // 此配置
                            opacity: 0,
                            normal: {
                                borderWidth: 4,
                                borderColor: '#10151e',

                            },
                            emphasis: {
                                borderWidth: 8,
                                scaleSize: 20,
                                borderColor: '#10151e',
                            },
                        },
                        zlevel: 1,
                        label: {
                            show: true,
                            normal: {
                                // padding: 2,
                                backgroundColor: '#10151e',
                                show: false,
                                position: 'center',
                                ellipsis: '...',
                                overflow: 'truncate',
                                width: 80,
                                formatter: function (obj) { // 图例后显示值
                                    let { name, value } = obj;
                                    return [`{name|${name}}`, `{value|${value}}`].join('\n');
                                },
                                rich: {
                                    value: {
                                        color: '#EAEDFF',
                                        fontSize: 24,
                                        lineHeight: 24,
                                        fontFamily: 'Azonix'
                                    },
                                    name: {
                                        color: '#9FA2B1',
                                        fontSize: 14,
                                        lineHeight: 35,
                                        align: 'center'
                                    },
                                    d: {
                                        color: '#EAEDFF',
                                        fontSize: 24,
                                        lineHeight: 24,
                                        fontFamily: 'Azonix'
                                    }
                                },
                            },
                            emphasis: {
                                show: true,
                                textStyle: {
                                    fontSize: '16',
                                    fontWeight: 'bold',
                                },
                            },

                        },
                        labelLine: {
                            normal: {
                                show: false,
                            },
                        },
                        data: [...data.value]
                    }
                ]
            };
        });

        function linesChart() {
            let linesChart = echarts.getInstanceByDom(document.getElementById('pieChart' + props.pieIndex));
            if (linesChart == null) {
                linesChart = echarts.init(document.getElementById('pieChart' + props.pieIndex));
            }
            linesChart.setOption(pieOptions.value);
            myChart = linesChart;
        }

        const resizeChart = () => {
            myChart.resize();
        };

        onMounted(() => {
            linesChart();
            window.addEventListener(
                "resize", resizeChart
            );
        });

        onBeforeUnmount(() => {
            window.removeEventListener(
                "resize", resizeChart
            );
        });

        // 监听传值，刷新图表
        watch(data, () => {
            linesChart();
        });

    },
}
</script>

<style scoped lang="less">
.pieChart {
    width: 100%;
    height: 100%;
    // transform: translateX(-20%);
}
</style>