<template>
  <div class="start-charts" :id="'start-charts' + chartIndex"></div>
</template>

<script>
import * as echarts from 'echarts';
import dayjs from 'dayjs';
import { toRefs, computed, onMounted, watch, onBeforeUnmount } from 'vue';
import { getThemeColor } from '@/utils/addColor.js';
export default {
  props: {
    barLineData: Object,
    chartIndex: Number
  },
  setup (props) {
    const { barLineData } = toRefs(props);
    let myChart = {};
    const themStyles = getThemeColor();
    const lineOptions = computed(() => {
      const { startUpPerDaily, startUpDaily, newuserTotalDaily } = barLineData.value;
      return {
        tooltip: {
          trigger: 'axis',
          backgroundColor: '#11151ee5',
          padding: 16,
          textStyle: {
            color: '#eaedff'
          },
          extraCssText: 'border: 1px dotted #eaedff99'
        },
        legend: {
          top: '-2%',
          right: '0%',
          itemGap: 20,
          itemWidth: 15,
          textStyle: {
            color: 'inherit',
          },
          icon: 'roundRect'
        },
        grid: {
          left: '0',
          right: '0',
          bottom: '10%',
          top: '15%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            axisLine: {
              lineStyle: {
                color: 'rgba(255, 255, 255, .5)',
              }
            },
            splitLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            splitArea: {
              show: false
            },
            axisLabel: {
              interval: 0,
              color: '#9FA2B1',
              fontSize: 12,
            },
            data: startUpPerDaily && startUpPerDaily.map(item => dayjs(item.timelong).format('YYYY-MM-DD'))
          }
        ],
        yAxis: [
          {
            type: 'value',
            splitLine: {
              show: false
            },
            axisLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              interval: 0,
              color: '#9FA2B1',
              fontSize: 12
            },
            splitArea: {
              show: false
            }
          },
          {
            type: 'value',
            axisLabel: {
              formatter: '{value}',
              color: 'rgba(234, 237, 255, 0.65)'
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: 'rgba(255, 255, 255, 0.1)'
              }
            }
          }
        ],
        series: [
          {
            name: '平均启动数',
            type: 'line',
            symbolSize: 10,
            yAxisIndex: 1,
            symbol: 'circle',
            itemStyle: {
              color: themStyles["--dataAnalysisBarLine"]["line"],
              borderColor: 'rgba(255, 255, 255, 0.2)',
              borderWidth: 3,
              borderType: 'solid'
            },
            lineStyle: {
              color: themStyles["--dataAnalysisBarLine"]["line"],
              width: 1
            },
            data: startUpPerDaily && startUpPerDaily.map(item => Math.floor(item.total * 100) / 100)
          },
          {
            name: '启动次数',
            type: 'bar',
            barMaxWidth: 16,
            roundCap: false,
            itemStyle: {
              borderRadius: [5, 5, 0, 0],
              color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [
                {
                  offset: 1,
                  color: themStyles["--dataAnalysisBarLine"]["bar1Rgb"]
                },
                {
                  offset: 0,
                  color: themStyles["--dataAnalysisBarLine"]["bar1Startcolor"]
                }
              ])
            },
            data: startUpDaily && startUpDaily.map(item => item.total)
          },
          {
            name: '启动用户数',
            type: 'bar',
            barMaxWidth: 16,
            roundCap: false,
            itemStyle: {
              borderRadius: [5, 5, 0, 0],
              color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [
                {
                  offset: 1,
                  color: themStyles["--dataAnalysisBarLine"]["bar2Rgb"]
                },
                {
                  offset: 0,
                  color: themStyles["--dataAnalysisBarLine"]["bar2Startcolor"]
                }
              ])
            },
            data: newuserTotalDaily && newuserTotalDaily.map(item => item.total)
          }
        ]
      };
    });


    const startCharts = () => {
      let startCharts = echarts.getInstanceByDom(document.getElementById('start-charts' + props.chartIndex));
      if (startCharts == null) {
        startCharts = echarts.init(document.getElementById('start-charts' + props.chartIndex));
      }
      startCharts.setOption(lineOptions.value);
      myChart = startCharts;
    };

    const resizeChart = () => {
      myChart.resize();
    };

    onMounted(() => {
      startCharts();
      window.addEventListener(
        "resize", resizeChart
      );
    });

    onBeforeUnmount(() => {
      window.removeEventListener(
        "resize", resizeChart
      );
    });

    // 监听传值，刷新图表
    watch(barLineData, () => {
      startCharts();
    });
  }
}
</script>

<style scoped lang="less">
.start-charts {
  width: 100%;
  height: 100%;
}
</style>
