<template>
  <div class="bisePie">
    <div class="header">
      <div class="name">行为总计</div>
      <div class="value">
        {{ activitySummary.totalActivity }}
      </div>
    </div>
    <div class="bise">
      <div class="header">
        <div class="name">风险总计</div>
        <div class="value">
          {{ activitySummary.riskActivity }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { ref } from 'vue';
import * as echarts from 'echarts';
import { toRefs, computed, onMounted, watch, onBeforeUnmount } from 'vue';
import { getThemeColor } from '@/utils/addColor.js';

export default {
  name: 'RiskPic',
  props: {
    data: {
      type: Object,
      default: {}
    }
  },
  setup (props) {
    const { data } = toRefs(props);

    const activitySummary = ref({});


    watch(data, () => {
      activitySummary.value = {
        totalActivity: data.value.totalActivity.toLocaleString(),
        riskActivity: data.value.riskActivity.toLocaleString()
      };
    });
    return { activitySummary };
  }
}

</script>

<style scoped lang="less">
@font-face {
  font-family: 'Azonix';
  // src: url('#{$fa-font-path}/fontawesome-webfont.eot?v=#{$fa-version}');
  src: url('../../../assets/font/Azonix.otf') format('opentype'); // used when developing fonts
  font-weight: normal;
  font-style: normal;
}

.bisePie {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto auto;
  width: 60%;
  height: 90%;
  border: 1px dashed rgba(88, 193, 255, 1);
  border-radius: 50%;
  background: linear-gradient(
    179.75deg,
    rgba(87, 193, 255, 0.3) 0.11%,
    rgba(0, 33, 82, 0.3) 99.79%
  );
  color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;

  .header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 8px;

    .value {
      font-family: Azonix;
      font-size: 24px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0.1em;
      text-align: center;
    }

    .name {
      font-family: PingFang SC;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0em;
      text-align: center;
    }
  }

  .bise {
    width: 60%;
    height: 60%;
    border: 1px dashed rgba(255, 153, 102, 1);
    border-radius: 50%;
    background: linear-gradient(
      180deg,
      rgba(255, 153, 102, 0.4) 0%,
      rgba(255, 153, 102, 0.1) 100%
    );
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
</style>