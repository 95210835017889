<template>
  <div class="virus-wrap">
    <div class="virus-top-calendar">
      <DatePicker @dataPickerTime="selectTime" />
    </div>
    <div class="virus-wrap-top">
      <div class="virus-wrap-top-left">
        <div class="top-num">
          <div class="top-num-card">
            <Card-border></Card-border>
            <Card-number
              :data="deviceInf"
              :style="'background: #282328'"
              :color="orangeColor"
            ></Card-number>
          </div>
          <div class="top-num-card">
            <Card-border></Card-border>
            <Card-number
              :data="deviceCov"
              :style="'background: #242830e5'"
              :color="whiteColor"
            ></Card-number>
          </div>
        </div>
        <div class="left-map">
          <MapChart :seriesData="allGeoData" :showVisualMap="false" />
        </div>
      </div>
      <div class="virus-wrap-top-right">
        <Card-border></Card-border>
        <div class="right-card">
          <div
            class="right-card-box"
            :class="activeShow === value.value ? 'active' : ''"
            v-for="(value, key, index) in riskLevel"
            :key="key"
            @click="selectedLevel(value.value)"
          >
            <div class="right-card-chart">
              <GaugeChart
                :rateData="levelList[key + '_rate'] || '0'"
                :rateName="levelList[key + '_device'] || '0'"
                :rateIndex="index"
              />
            </div>
            <div class="right-card-content">
              {{ value.label }}
            </div>
          </div>
        </div>
        <div class="right-list">
          <div class="right-list-title">
            <span class="right-list-name">感染病毒排行TOP5</span>
            <span class="right-list-more" v-show="activeShow" @click="clickMore"
              >查看更多</span
            >
          </div>
          <div class="right-list-table">
            <a-table
              class="ant-table-striped"
              :columns="columns"
              :data-source="virusTopData"
              :pagination="false"
              :rowClassName="
                (record, index) => (index % 2 === 1 ? 'table-striped' : null)
              "
            >
            </a-table>
          </div>
        </div>
      </div>
    </div>
    <div class="virus-wrap-bottom">
      <div class="title">病毒木马趋势分析</div>
      <div class="chart">
        <LineChart :data="trendData" />
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, onBeforeUnmount } from "vue";
import dayjs from "dayjs";
import CardBorder from "@/components/common/card-border.vue";
import GaugeChart from "./components/gauge.vue";
import MapChart from "@/components/common/map.vue";
import LineChart from './components/line.vue';
import CardNumber from "@/components/common/card-num.vue";
import DatePicker from "@/components/common/date-picker.vue";
import { Table, Tooltip } from "ant-design-vue";
import router from "@/router/index";

import {
  getSummary,
  getLevelTop,
  getLevel,
  getGeo,
  getTrend,
} from "@/api/virus.js";

let nowTime = dayjs().startOf("week").add(1, "day").format("YYYY-MM-DD");
const orangeColor = { name: '#FCE8CC', value: '#FFB58F' };
const whiteColor = { name: '#EAEDFF', value: '#EAEDFF' };

const riskLevel = {
  serious: {
    label: "严重",
    value: "4"
  },
  high: {
    label: "高风险",
    value: "3"
  },
  medium: {
    label: "中风险",
    value: "2"
  },
  low: {
    label: "低风险",
    value: "1"
  }
};


const columns = [
  {
    title: "排序",
    customRender: ({ index }) => {
      return index + 1;
    },
    dataIndex: "sort",
    key: "sort",
    width: 80,
  },
  {
    title: "风险名称",
    dataIndex: "ave",
    key: "ave",
    ellipsis: true,
  },
  {
    title: "主行为",
    dataIndex: "mb",
    key: "mb",
    customRender: ({ text }) => {
      return text || '-';
    },
  },
  {
    title: "子行为",
    dataIndex: "sb",
    key: "sb",
    customRender: ({ text }) => {
      return text || '-';
    },
  },
  {
    title: "风险等级",
    dataIndex: "level",
    key: "level",
    customRender: ({ text }) => {
      let texts = "-";
      Object.keys(riskLevel).map((key) => {
        if (riskLevel[key].value === text) {
          texts = riskLevel[key].label;
        }
      });
      return texts;
    },
  },
  {
    title: "感染设备数",
    dataIndex: "inf",
    key: "inf",
    customRender: ({ text }) => {
      return text || 0;
    },
  },
];

export default {
  components: {
    CardBorder,
    GaugeChart,
    MapChart,
    CardNumber,
    DatePicker,
    LineChart,
    [Table.name]: Table,
    [Tooltip.name]: Tooltip,
  },
  setup () {
    const levelList = ref({});
    const devRank = ref([]);
    const deviceInf = ref({});
    const deviceCov = ref({});
    const allGeoData = ref([]);
    const virusTopData = ref([]);
    const activeShow = ref('');
    const trendData = ref([]);

    const setTableHeight = () => {
      if ($(".right-list-table").height()) {
        const h = ($(".right-list-table").height() - 42) / 5 - 10;
        $(".right-list-table .ant-table-tbody td").height(h);
      }
    };

    onMounted(() => {
      window.addEventListener(
        "resize", setTableHeight
      );
    });

    onBeforeUnmount(() => {
      window.removeEventListener(
        "resize", setTableHeight
      );
    });

    // 日期选择
    const selectTime = (val) => {
      nowTime = dayjs(val).startOf('week').add(8, 'day').format("YYYY-MM-DD");
      getSummarydevice();
      getVirusLevel();
      getVirusTop();
      getRiskGeo();
    };

    // 风险等级筛选
    const selectedLevel = (level) => {
      activeShow.value = level;
      getVirusTop();
      getRiskGeo();
    };

    // 获取设备感染数及设备总数
    const getSummarydevice = async () => {
      const res = (await getSummary({ dt: nowTime })).data;
      if (res) {
        deviceInf.value = { name: "感染设备数", value: res.inf || 0, startValue: '', index: 78 };
        deviceCov.value = { name: "设备总数", value: res.cov || 0, startValue: '', index: 79 };
      }
    };
    // 获取风险等级
    const getVirusLevel = async () => {
      levelList.value = (await getLevel({ dt: nowTime })).data.risk || {};
    };
    // 病毒木马感染Top5(默认会展示严重等级top5的数据，如果严重等级没有数据就展示高风险等级Top5，依次类推)
    const getVirusTop = async () => {
      const { data } = await getLevelTop({ dt: nowTime, level: activeShow.value });
      virusTopData.value = data.top5 || [];
      virusTopData.value.forEach(val => {
        val.level = data.level;
      });
      if (!activeShow.value) {
        getRiskGeo();
        activeShow.value = data.level || '';
      }
      setTimeout(() => {
        setTableHeight();
      });
    };
    // 获取地图各个省份病毒木马的数据
    const getRiskGeo = async () => {
      const { data } = await getGeo({ dt: nowTime, level: activeShow.value });
      const value = data.provs || [];
      allGeoData.value = value.map((item) => {
        return {
          name: item.prov,
          value: item.inf,
        };
      });
    };
    // 病毒木马感染趋势
    const getAppTrend = async () => {
      const { data } = await getTrend();
      const trend = data.trend || [];
      trendData.value = trend.map(val => {
        val.day = val.dt;
        return val;
      });
    };

    const clickMore = () => {
      router.push({
        path: "/virusMore",
        query: {
          level: activeShow.value
        }
      });
    };

    getSummarydevice();
    getVirusLevel();
    getVirusTop();
    getAppTrend();


    return {
      levelList,
      devRank,
      deviceInf,
      deviceCov,
      orangeColor,
      whiteColor,
      columns,
      virusTopData,
      trendData,
      clickMore,
      allGeoData,
      riskLevel,
      selectTime,
      selectedLevel,
      activeShow,
    };
  },
};
</script>

<style lang="less">
.virus-wrap {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  position: relative;
  .virus-top-calendar {
    width: 288px;
    height: 44px;
    // margin: 10px 0 20px;
    position: absolute;
    top: 10px;
    left: 0px;
  }
  &-top {
    flex: 1;
    display: flex;
    // min-height: calc(100vh - 425px);
    height: 77%;
    padding-top: 74px;
    &-left {
      position: relative;
      flex: 1;
      display: flex;
      flex-direction: column;
      .top-num {
        display: flex;
        position: absolute;
        top: 0;
        left: 0;
        &-card {
          position: relative;
          width: 286px;
          height: 112px;
          margin-right: 20px;
          z-index: 100;
        }
      }
      .left-rank {
        margin-top: 16px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        background: #eaedff0d;
        border-radius: 8px;
        padding-bottom: 16px;
        &-box {
          width: 30%;
          background: #ffffff00;
          border: 1px solid #ffffff33;
          border-radius: 4px;
          margin-top: 16px;
          padding: 0 16px;
          display: inline-flex;
          flex-direction: row;
          align-items: center;
          span {
            display: block;
            &:first-child {
              color: #ffb58f;
            }
            &:nth-child(2) {
              color: #ffffff66;
              margin-left: 6px;
              margin-right: 12px;
            }
            &:nth-child(3) {
              width: 114px;
              text-align: left;
              font-size: 14px;
              opacity: 0.85;
            }
            &:last-child {
              font-size: 20px;
            }
          }
        }
      }
      .left-map {
        padding-top: 10%;
        width: 100%;
        height: 100%;
      }
    }
    &-right {
      width: 40%;
      margin-left: 24px;
      position: relative;
      padding: 30px;
      margin-right: 2px;
      .right-card {
        display: flex;
        justify-content: space-between;
        div:last-child {
          margin-right: 0px;
        }
        &-box {
          width: 22%;
          height: 160px;
          box-sizing: border-box;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          background: #eaedff0d;
          border-radius: 8px;
          padding: 10px;
          position: relative;
          cursor: pointer;
        }
        .active {
          border: 1px solid #ffb58f;
        }
        &-chart {
          width: 106px;
          height: 106px;
          background-image: url('../../assets/images/gaugeDottedBg.svg');
          background-size: 85px 85px;
          background-repeat: no-repeat;
          background-position: center;
        }
        &-content {
          font-size: 18px;
          color: #9fa2b1;
          line-height: 26px;
        }
      }
      .right-list {
        position: relative;
        height: calc(100% - 160px);
        &-title {
          position: relative;
          height: 65px;
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-bottom: 1px solid #6f7277;
        }
        &-name {
          font-size: 24px;
          line-height: 32px;
        }
        &-more {
          font-size: 16px;
          color: #fce8cc;
          line-height: 20px;
          text-decoration: underline;
          cursor: pointer;
        }
        &-table {
          margin-top: 20px;
          height: calc(100% - 85px);
          overflow-y: scroll;
          .ant-pagination {
            display: none;
          }
        }
      }
    }
  }
  &-bottom {
    height: 23%;
    .title {
      font-size: 24px;
    }
    .chart {
      height: calc(100% - 32px);
    }
  }
  .ant-table-wrapper .ant-table-tbody > tr > td {
    padding: 5px 15px !important;
  }
}
</style>
