import dayjs from "dayjs";
export const columnsArr = {
    app: [
        {
            title: '序号',
            customRender: ({ index }) => {
                return index + 1;
            },
            dataIndex: 'sort',
            key: 'sort',
            width: 80
        }, {
            title: 'APP名称	',
            dataIndex: 'app_name',
            key: 'app_name',
            ellipsis: true
        }, {
            title: 'APP包名',
            dataIndex: 'app_pkg',
            key: 'app_pkg',
            ellipsis: true
        }, {
            title: 'APK证书MD5',
            dataIndex: 'apk_cert_md5',
            key: 'apk_cert_md5',
            ellipsis: true
        }, {
            title: 'APP类型',
            dataIndex: 'category',
            key: 'category'
        }, {
            title: 'APP标签',
            dataIndex: 'app_tags',
            key: 'app_tags'
        }, {
            title: '历史装机量',
            dataIndex: 'installs',
            key: 'installs'
        }, {
            title: '当前安装状态',
            dataIndex: 'state',
            key: 'state'
        }, {
            title: '最早安装时间',
            dataIndex: 'first_time',
            key: 'first_time',
            sorter: (a, b) => dayjs(a.first_time) - dayjs(b.first_time)
        }, {
            title: '最近活跃时间',
            dataIndex: 'recent_time',
            key: 'recent_time',
            sorter: (a, b) => dayjs(a.recent_time) - dayjs(b.recent_time)
        }
    ],
    wifi: {
        "1": [
            {
                title: '序号',
                customRender: ({ index }) => {
                    return index + 1;
                },
                dataIndex: 'sort',
                key: 'sort',
                width: 80
            }, {
                title: 'WIFI 名称',
                dataIndex: 'wifi_name',
                key: 'wifi_name',
                ellipsis: true
            }, {
                title: 'WIFI MAC',
                dataIndex: 'wifi_mac',
                key: 'wifi_mac',
            }, {
                title: 'WIFI 标签',
                dataIndex: 'wifi_tags',
                key: 'wifi_tags'
            }, {
                title: 'WIFI 位置',
                dataIndex: 'location',
                key: 'location'
            }, {
                title: '关联时间',
                dataIndex: 'timestamp',
                key: 'timestamp',
                sorter: (a, b) => dayjs(a.timestamp) - dayjs(b.timestamp)
            }
        ],
        "2": [
            {
                title: '序号',
                customRender: ({ index }) => {
                    return index + 1;
                },
                dataIndex: 'sort',
                key: 'sort',
                width: 80
            }, {
                title: 'WIFI 名称',
                dataIndex: 'wifi_name',
                key: 'wifi_name',
                ellipsis: true
            }, {
                title: 'WIFI MAC',
                dataIndex: 'wifi_mac',
                key: 'wifi_mac',
            }, {
                title: 'WIFI 标签',
                dataIndex: 'wifi_tags',
                key: 'wifi_tags'
            }, {
                title: 'WIFI 位置',
                dataIndex: 'location',
                key: 'location'
            }, {
                title: '连接次数',
                dataIndex: 'count',
                key: 'count'
            }, {
                title: '最早关联时间',
                dataIndex: 'min_timestamp',
                key: 'min_timestamp',
                sorter: (a, b) => dayjs(a.min_timestamp) - dayjs(b.min_timestamp)
            }, {
                title: '最近关联时间',
                dataIndex: 'max_timestamp',
                key: 'max_timestamp',
                sorter: (a, b) => dayjs(a.max_timestamp) - dayjs(b.max_timestamp)
            }
        ]
    },
    ip: {
        "1": [{
            title: '序号',
            customRender: ({ index }) => {
                return index + 1;
            },
            dataIndex: 'sort',
            key: 'sort',
            width: 80
        }, {
            title: '历史IP',
            dataIndex: 'ip',
            key: 'ip',
            ellipsis: true
        }, {
            title: 'IP归属地',
            dataIndex: 'location',
            key: 'location',
        }, {
            title: '记录时间',
            dataIndex: 'timestamp',
            key: 'timestamp',
            sorter: (a, b) => dayjs(a.timestamp) - dayjs(b.timestamp)
        }],
        "2": [{
            title: '序号',
            customRender: ({ index }) => {
                return index + 1;
            },
            dataIndex: 'sort',
            key: 'sort',
            width: 80
        }, {
            title: '历史IP',
            dataIndex: 'ip',
            key: 'ip',
            ellipsis: true
        }, {
            title: 'IP类型',
            dataIndex: 'ip',
            key: 'ip',
            ellipsis: true
        }, {
            title: 'IP归属地',
            dataIndex: 'location',
            key: 'location',
        }, {
            title: '频次',
            dataIndex: 'count',
            key: 'count',
        }, {
            title: '最近一次时间',
            dataIndex: 'max_timestamp',
            key: 'max_timestamp',
            sorter: (a, b) => dayjs(a.max_timestamp) - dayjs(b.max_timestamp)
        }],
    }
};