<template>
  <div class="trend-wrap">
    <div class="trend-wrap-top">
      <div class="top-chart">
        <CardBorder></CardBorder>
        <div class="top-chart-info">
          <span class="top-chart-name">崩溃总体趋势</span>
          <div class="top-chart-check">
            <div class="top-chart-check-option">
              <a-select
                v-model:value="selVersion"
                placeholder="全部版本"
                :options="versionList.map((item) => ({ value: item }))"
                @change="handleChangeVersion"
              >
              </a-select>
            </div>
            <div class="top-chart-check-option">
              <a-select
                v-model:value="selType"
                :options="typeList"
                @change="handleChangeType"
              >
              </a-select>
            </div>
          </div>
        </div>
        <div class="top-chart-main">
          <LineChart :lineData="trendData" :chartIndex="0" />
        </div>
      </div>
      <div class="top-chart chart-space">
        <CardBorder></CardBorder>
        <div class="top-chart-info">
          <span class="top-chart-name">用户崩溃分析</span>
          <div class="top-chart-check">
            <div class="top-chart-check-option">
              <a-select
                placeholder="全部版本"
                v-model:value="userVersion"
                :options="userVersionList.map((item) => ({ value: item }))"
                @change="handleUserChangeVersion"
              >
              </a-select>
            </div>
            <div class="top-chart-check-option">
              <a-select
                v-model:value="userType"
                :options="userTypeList"
                @change="handleUserChangeType"
              >
              </a-select>
            </div>
          </div>
        </div>
        <div class="top-chart-main">
          <LineChart :lineData="analyseData" :chartIndex="1" />
        </div>
      </div>
    </div>
    <div class="trend-wrap-bottom">
      <div class="bottom-chart">
        <div class="bottom-chart-title">崩溃最多的机型和系统</div>
        <div class="bottom-chart-box">
          <CardBorder></CardBorder>
          <div class="bottom-chart-main">
            <div>
              <span class="bottom-chart-main-text">崩溃最多机型</span>
              <span class="bottom-chart-main-logo">
                <img :src="getImgPath('crashLog/model.png')" />
              </span>
              <span v-if="modelData.length > 0" class="bottom-chart-main-name">
                {{ modelData[0].name }}
              </span>
            </div>
            <div>
              <span class="bottom-chart-main-text">崩溃次数最多的系统</span>
              <span class="bottom-chart-main-logo">
                <img :src="getImgPath('crashLog/os.png')" />
              </span>
              <span v-if="osData.length > 0" class="bottom-chart-main-name">
                {{ osData[0].name }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="bottom-chart chart-space">
        <div class="bottom-chart-title">崩溃机型排行</div>
        <div class="bottom-chart-box">
          <CardBorder></CardBorder>
          <BarChart :barData="modelData" :chartIndex="0" />
        </div>
      </div>
      <div class="bottom-chart chart-space">
        <div class="bottom-chart-title">崩溃系统排行</div>
        <div class="bottom-chart-box">
          <CardBorder></CardBorder>
          <BarChart :barData="osData" :chartIndex="1" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, toRefs, watch } from 'vue';
import { getVersionList, getDayCount, getTopModel, getTopOS } from '@/api/crashLog.js';
import CardBorder from '@/components/common/card-border.vue';
import BarChart from './common/bar.vue';
import LineChart from './common/dotted-lines.vue';
import { Select } from 'ant-design-vue';
import { getImgPath } from '@/utils/addColor.js';
export default {
  props: {
    appData: {
      type: Object,
      default: () => { }
    },
  },
  components: {
    CardBorder,
    BarChart,
    LineChart,
    [Select.name]: Select
  },
  setup (props) {
    const { appData } = toRefs(props);
    const versionList = ref([]);
    const userVersionList = ref([]);
    const typeList = [
      {
        label: 'JAVA',
        value: 0
      },
      {
        label: 'NATIVE',
        value: 1
      }
    ];
    const userTypeList = [
      {
        label: 'JAVA',
        value: 0
      },
      {
        label: 'NATIVE',
        value: 1
      }
    ];
    const selVersion = ref();
    const userVersion = ref();
    const selType = ref(0);
    const userType = ref(0);
    const trendData = ref([]);
    const analyseData = ref([]);
    const modelData = ref([]);
    const osData = ref([]);
    const appParams = ref({
      appKey: '',
      pn: '',
      startDate: '',
      endDate: ''
    });

    // 选择版本
    const handleChangeVersion = (val) => {
      selVersion.value = val;
      getLogDayCount();
    };
    const handleUserChangeVersion = (val) => {
      userVersion.value = val;
      getCollapseData();
    };
    // 选择类型
    const handleChangeType = (val) => {
      selType.value = val;
      getLogDayCount();
    };
    const handleUserChangeType = (val) => {
      userType.value = val;
      getCollapseData();
    };

    // 获取全部版本号
    const getLogVersionList = async () => {
      const { data } = await getVersionList({
        appKey: appParams.value.appKey,
        pn: appParams.value.pn,
        timeType: 3,
        limit: 30
      });
      versionList.value = data;
      userVersionList.value = data;
    };

    // 崩溃总体趋势
    const getLogDayCount = async () => {
      const { data } = await getDayCount({
        appKey: appParams.value.appKey,
        pn: appParams.value.pn,
        meVnT: '',
        version: selVersion.value,
        type: selType.value,
        startDate: appParams.value.startDate,
        endDate: appParams.value.endDate
      });
      trendData.value = data.map(item => {
        return {
          time: item.dateTime,
          count: item.docCount, // 崩溃次数
          percent: item.crashPercent // 崩溃占比
        };
      });

    };
    // 用户崩溃分析
    const getCollapseData = async () => {
      const { data } = await getDayCount({
        appKey: appParams.value.appKey,
        pn: appParams.value.pn,
        meVnT: '',
        version: userVersion.value,
        type: userType.value,
        startDate: appParams.value.startDate,
        endDate: appParams.value.endDate
      });
      analyseData.value = data.map(item => {
        return {
          time: item.dateTime,
          count: item.personNum, // 影响用户数
          percent: item.personPercent // 用户影响占比
        };
      });
    };

    // 获取崩溃机型排行
    const getLogTopModel = async () => {
      const { data } = await getTopModel({
        appKey: appParams.value.appKey,
        pn: appParams.value.pn,
        meVnT: '',
        timeType: '',
        limit: '',
        startDate: appParams.value.startDate,
        endDate: appParams.value.endDate
      });
      modelData.value = data.map(item => {
        return {
          name: item.model,
          value: item.docCount
        };
      });
    };

    // 获取崩溃系统排行
    const getLogTopOS = async () => {
      const { data } = await getTopOS({
        appKey: appParams.value.appKey,
        pn: appParams.value.pn,
        meVnT: '',
        timeType: '',
        limit: '',
        startDate: appParams.value.startDate,
        endDate: appParams.value.endDate
      });
      osData.value = data.map(item => {
        return {
          name: item.os,
          value: item.docCount
        };
      });
    };

    // 监听传值
    watch(appData.value, () => {
      if (appData.value.appKey) {
        appParams.value = appData.value;
        getLogVersionList();
        // getLogHourCount();
        getLogDayCount();
        getCollapseData();
        getLogTopModel();
        getLogTopOS();
      }
    }, { immediate: true });

    return { getImgPath, versionList, userVersionList, selVersion, userVersion, typeList, userTypeList, selType, userType, trendData, analyseData, modelData, osData, handleChangeVersion, handleUserChangeVersion, handleChangeType, handleUserChangeType };
  }
}
</script>

<style lang="less">
.trend-wrap {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  &-top {
    display: flex;
    height: 55%;
    .top-chart {
      position: relative;
      width: 50%;
      height: 100%;
      padding: 24px;
      &-info {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
      &-name {
        font-size: 18px;
        font-weight: 500;
      }
      &-check {
        cursor: pointer;
        position: absolute;
        right: 20px;
        top: 38px;
        display: flex;
        z-index: 1;
        &-option {
          width: 90px;
          margin-left: 15px;
          .ant-select {
            color: rgba(255, 191, 102, 0.85) !important;
            .ant-select-arrow {
              color: #aeaeae;
            }
            .ant-select-selection-placeholder {
              color: rgba(255, 191, 102, 0.85);
            }
          }
          .ant-select-single:not(.ant-select-customize-input)
            .ant-select-selector {
            padding: 0;
          }
          .ant-select:not(.ant-select-customize-input) .ant-select-selector {
            border: 0 !important;
            box-shadow: none !important;
          }
        }
      }
      &-main {
        width: 100%;
        height: 94%;
      }
    }
  }
  &-bottom {
    position: relative;
    display: flex;
    height: 45%;
    // padding-top: 24px;
    .bottom-chart {
      position: relative;
      width: 33%;
      height: 100%;
      &-title {
        font-size: 18px;
        line-height: 30px;
        margin: 8px 0;
      }
      &-box {
        position: relative;
        width: 100%;
        height: calc(100% - 46px);
        padding: 24px;
      }
      &-main {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 100%;
        div {
          width: 50%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }
        span {
          display: block;
        }
        &-text {
          color: rgba(234, 237, 255, 0.85);
        }
        &-logo {
          width: 76px;
          height: 76px;
          margin: 18px 0;
          img {
            width: 100%;
          }
        }
        &-name {
          font-size: 28px;
        }
      }
    }
  }
  .chart-space {
    margin-left: 24px;
  }
}
</style>