<template>
  <div class="scatter-map" id="scatter-map"></div>
</template>

<script>
import * as echarts from 'echarts';
import { ref, toRefs, computed, onMounted, watch, onBeforeUnmount } from 'vue';
import { getThemeColor } from '@/utils/addColor.js';
import axios from 'axios';
export default {
  props: {
    seriesData: {
      type: Array,
      default: () => { []; }
    }
  },
  setup (props) {
    const { seriesData } = toRefs(props);
    const geoCoordMap = ref({});
    let myChart = {};
    const themStyles = getThemeColor();
    const setOptions = computed(() => {
      let startData = [];
      let userData = [];
      seriesData.value.map(item => {
        startData.push({
          name: item.dim,
          value: item.pv
        });
        userData.push({
          name: item.dim,
          value: item.nu
        });
      });
      const convertData = (data) => {
        let res = [];
        data.map(item => {
          let geoCoord = geoCoordMap.value[item.name];
          if (geoCoord) {
            res.push({
              name: item.name,
              value: geoCoord.concat(item.value)
            });
          }
        });
        return res;
      };
      let options = {
        legend: {
          orient: 'horizontal',
          y: 'bottom',
          x: 'left',
          left: '10%',
          itemGap: 20,
          itemWidth: 15,
          data: [
            {
              name: '启动次数',
              icon: 'roundRect',
              itemStyle: {
                color: '#FF9966'
              },
              textStyle: {
                color: '#FF9966'
              }
            },
            {
              name: '新增用户数',
              icon: 'roundRect',
              itemStyle: {
                color: '#e4695d'
              },
              textStyle: {
                color: '#e4695d'
              }
            }
          ]
        },
        tooltip: {
          trigger: 'item',
          backgroundColor: '#11151ee5',
          padding: 16,
          textStyle: {
            color: '#eaedff'
          },
          extraCssText: 'border: 1px dotted #eaedff99'
          // formatter: (params) => {
          // }
        },
        geo: {
          map: 'china',
          roam: false,
          zoom: 1.23,
          top: 'center',
          label: {
            show: false
          },
          itemStyle: {
            normal: {
              color: {
                type: 'linear-gradient',
                x: 150,
                y: 20,
                x2: 100,
                y2: 450,
                colorStops:themStyles["--mainMapConfig"]["bgColor"],
                global: true,// 缺省为 false
              },
              borderWidth: 1, //设置外层边框
              borderColor: themStyles["--mainMapConfig"]["borderColor"],
              shadowColor: themStyles["--mainMapConfig"]["borderColor"],
            },
            emphasis: {
              show: false,
              areaColor:null,
            }
          }
        },
        visualMap: [{
          show: false,
          type: 'continuous',
          min: 0,
          max: 10000,
          seriesIndex: [0],
          inRange: {
            color: ['#10151E', 'rgba(255, 153, 102, 0.19)', 'rgba(255, 153, 102, 1)']
          },
          textStyle: {
            color: 'rgba(255, 153, 102, 1)'
          }
        }],
        series: [
          {
            name: '启动次数',
            type: 'map',
            map: 'china',
            roam: false,
            zoom: 1.23,
            top: 'center',
            itemStyle: {
              normal: {
                color: 'none',
                areaColor: 'rgba(128, 128, 128, 0)',
                borderColor: '#eaedff7a',
                borderWidth: 1,
                borderType: 'solid'
              },
            },
            label: {
              show: false
            },
            emphasis: {
              label: {
                color: '#eaedff'
              },
              itemStyle: {
                areaColor: 'none'
              }
            },
            data: startData
          },
          {
            name: '新增用户数',
            type: 'scatter',
            coordinateSystem: 'geo',
            symbolSize: function (val) {
              return val[2] / 1000;
            },
            label: {
              show: false
            },
            itemStyle: {
              color: '#e4695d'
            },
            zlevel: 5,
            data: convertData(userData)
          }
        ]
      };
      return options;
    });

    function initMapChart () {
      axios.get('/6507d9a98a1e476e.json').then(geoJson => {
        geoCoordMap.value = Object.fromEntries(geoJson.data.features.map(item => [item.properties.name, item.properties.center]));
        echarts.registerMap('china', { geoJSON: geoJson.data });
        let scatterChart = echarts.getInstanceByDom(document.getElementById('scatter-map'));
        if (scatterChart == null) {
          scatterChart = echarts.init(document.getElementById('scatter-map'));
        }
        scatterChart.setOption(setOptions.value);
        myChart = scatterChart;

      });
    }

    const resizeChart = () => {
      myChart.resize();
    };

    onMounted(() => {
      initMapChart();
      window.addEventListener(
        "resize", resizeChart
      );
    });

    onBeforeUnmount(() => {
      window.removeEventListener(
        "resize", resizeChart
      );
    });

    // 监听传值，刷新图表
    watch(seriesData, () => {
      initMapChart();
    });
  },
}
</script>

<style lang="less" scoped>
.scatter-map {
  width: 100%;
  height: 100%;
}
</style>
