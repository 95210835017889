<template>
  <div class="app-details">
    <div class="app-details-left">
      <div class="left-card">
        <Card-border></Card-border>
        <div class="left-card-back" @click="goBack">
          <img :src="getImgPath('application/back.png')" />
        </div>
        <div class="left-card-line"></div>
        <div class="left-card-icon">
          <img :src="appInfo.icon || '@/assets/images/weather.png'" />
        </div>
        <div class="left-app-info">
          <div class="left-details-btn" @click="handleToDetail(appName)">
            <span> 查看详情 </span>
            <right-outlined />
          </div>
          <div class="left-card-name">{{ appName }}</div>
          <span class="left-card-type">应用类型：</span>
          <span class="left-card-text">{{ appInfo.clsname }}</span>
        </div>
      </div>
      <div class="left-list">
        <span class="left-list-title">风险感染详情</span>
        <div class="left-list-content">
          <div
            class="left-list-details"
            v-for="(item, index) in detailData"
            :key="index"
          >
            <div
              :class="
                index === detailIndex ? 'left-list-card' : 'left-list-wrap'
              "
            >
              <div v-if="index === detailIndex">
                <Card-border></Card-border>
              </div>
              <div class="left-list-card-sort">{{ index + 1 }}</div>
              <div class="left-list-card-box">
                <div class="left-list-card-main">
                  <span>风险名称</span>
                  <div>{{ item.ave }}</div>
                </div>
                <div class="left-list-card-main">
                  <span>感染包名</span>
                  <div>{{ item.pn }}</div>
                </div>
                <div v-if="index === detailIndex">
                  <div class="left-list-card-main left-list-card-flex">
                    <div>
                      <span>周环比感染率</span>
                      <div class="progress-bar-container">
                        <div
                          class="progress-bar"
                          :style="{ width: `${item.week_ratio}%` }"
                        >
                          <div class="progress-num-up">
                            {{ item.week_ratio }}%
                          </div>
                        </div>
                        <div class="progress-num-down">
                          {{ item.week_ratio }}%
                        </div>
                      </div>
                    </div>
                    <div>
                      <span>主/子行为标签</span>
                      <div class="left-list-tag">
                        {{ item.mb }}/{{ item.sb }}
                      </div>
                    </div>
                  </div>
                  <div class="left-list-card-main">
                    <span>签名信息</span>
                    <div>{{ item.s }}</div>
                  </div>
                  <!-- <div class="left-list-card-main">
                    <span>应用md5信息</span>
                    <div>{{ item.apkmd5 }}</div>
                  </div> -->
                </div>
              </div>
              <div
                v-if="index === detailIndex"
                class="left-list-card-btn"
                @click="changBtn(index)"
              >
                <img :src="getImgPath('application/close.png')" />
              </div>
              <div
                v-if="index !== detailIndex"
                class="left-list-card-btn"
                @click="changBtn(index)"
              >
                <img :src="getImgPath('application/open.png')" />
              </div>
            </div>
            <!-- <div class="left-list-wrap">
            <div class="left-list-card-sort">{{index + 1}}</div>
            <div class="left-list-wrap-content">
              <span>风险名称</span>
              <div>{{item.ave}}</div>
              <span class="name">感染包名</span>
              <div>{{item.pn}}</div>
            </div>
            <div class="left-list-card-btn"  @click="changBtn(index)">
              <img src="@/assets/images/application/open.png" />
            </div>
          </div> -->
          </div>
        </div>
      </div>
    </div>
    <div class="app-details-right">
      <div class="right-card">
        <div class="right-card-device">
          <Card-border-orange></Card-border-orange>
          <Card-number
            :data="infectDev"
            :style="'background: #282328'"
            :color="infectColor"
          ></Card-number>
        </div>
        <div class="right-card-device">
          <Card-border></Card-border>
          <Card-number
            :data="deviceAll"
            :style="'background: #eaedff1a'"
            :color="deviceColor"
          ></Card-number>
        </div>
      </div>
      <div class="right-calendar">
        <DatePicker @dataPickerTime="selectTime" />
      </div>
      <div class="right-map">
        <MapChart :seriesData="appData" />
      </div>
      <div class="right-title">
        <span>应用覆盖设备感染分布</span>
        <div></div>
      </div>
      <div class="right-chart">
        <Card-border></Card-border>
        <span class="right-chart-icon">
          <img :src="getImgPath('application/iphone.png')" />
        </span>
        <span class="right-chart-title">设备7日感染趋势</span>
        <div class="right-chart-line">
          <LineChart :data="trendData" :chartIndex="0" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import dayjs from 'dayjs';
import { getTrend, getDetail, getInfo, getApp } from '@/api/application.js';
import CardBorder from '@/components/common/card-border.vue';
import CardBorderOrange from '@/components/common/card-border-orange.vue';
import CardNumber from '@/components/common/card-num.vue';
import MapChart from '@/components/common/map.vue';
import LineChart from '@/components/common/line.vue';
import router from '@/router/index';
// import DatePicker from '@/components/common/date-picker.vue';
import DatePicker from '@/components/common/date-picker-one-day.vue';
import { getImgPath } from '@/utils/addColor.js';
// import { toThousands } from '@/utils/index.js';
import {
  RightOutlined
} from '@ant-design/icons-vue';
let nowTime = dayjs().startOf('week').add(1, 'day').format("YYYY-MM-DD");
const infectColor = { name: '#FCE8CC', value: '#FFB58F' };
const deviceColor = { name: '#EAEDFF', value: '#EAEDFF' };

export default {
  components: {
    CardBorder,
    CardBorderOrange,
    CardNumber,
    MapChart,
    LineChart,
    DatePicker,
    RightOutlined
  },
  setup () {
    const trendData = ref([]);
    const appData = ref([]);
    const infectDev = ref({});
    const deviceAll = ref({});
    const appInfo = ref({});
    const detailData = ref([]);
    const detailIndex = ref(0);

    const appName = router.currentRoute.value.query.name;

    // 点击展开详情
    const changBtn = (val) => {
      detailIndex.value = val;
    };

    // 日期选择
    const selectTime = (val) => {
      nowTime = val;
      getAppTrend();
      getAppDetails();
      getProvApp();
    };

    // 设备7日感染趋势
    const getAppTrend = async () => {
      const res = {
        sign: 0,
        dt: nowTime,
        app: appName
      };
      const { data } = await getTrend(res);
      trendData.value = data.map(item => {
        return {
          name: item.day,
          value: item.inf
        };
      });
    };
    // 风险感染详情
    const getAppDetails = async () => {
      const { data } = await getDetail({
        sign: 0,
        dt: nowTime,
        app: appName,
        page: 1,
        size: 5
      });
      detailData.value = data;
    };
    // 获取单个应用信息
    const getAppInfo = async () => {
      const { data } = await getInfo({
        sign: 0,
        app: appName
      });
      appInfo.value = data;
    };
    // 获取各个省份的app监测数据
    const getProvApp = async () => {
      const params = {
        sign: 0,
        app: appName,
        dt: nowTime
      };
      const { data, total } = await getApp(params);
      infectDev.value = { name: '感染设备数', value: total.inf || 0 };
      deviceAll.value = { name: '设备总数', value: total.cov || 0 };
      appData.value = data.map(item => {
        return {
          name: item.name,
          value: item.inf
        };
      });
    };

    const goBack = () => {
      window.history.go(-1);
    };
    // 跳转详情页
    const handleToDetail = (value) => {
      router.push(`/appDetail?appName=${value}`);
    };

    getAppTrend();
    getAppDetails();
    getAppInfo();
    getProvApp();

    return {
      detailData,
      infectDev,
      infectColor,
      deviceAll,
      deviceColor,
      goBack,
      trendData,
      appData,
      appName,
      appInfo,
      selectTime,
      detailIndex,
      changBtn,
      getImgPath,
      handleToDetail
    };
  },
}
</script>
<style lang="less">
@import url('./details.less');
</style>