<template>
  <div class="board-wrap">
    <div class="board-wrap-left">
      <span class="board-wrap-title">黑产IP分布</span>
      <div class="left-map">
        <Card-border></Card-border>
        <MapChart :seriesData="allIpData" />
      </div>
    </div>
    <div class="board-wrap-middle">
      <div class="board-wrap-box">
        <span class="board-wrap-title">风险IP变化</span>
        <div class="board-wrap-chart">
          <Card-border></Card-border>
          <LineBar
            :lineBarData="brushDevData"
            :chartIndex="1"
            seriesTitle="风险IP"
          />
        </div>
      </div>
      <div class="board-wrap-bottom">
        <span class="board-wrap-title">风险IP排名TOP5</span>
        <div class="board-wrap-chart">
          <Card-border></Card-border>
          <div class="board-wrap-table">
            <RankTable :rankTableData="rankDevData" />
          </div>
        </div>
      </div>
    </div>
    <div class="board-wrap-right">
      <div class="board-wrap-box">
        <span class="board-wrap-title">攻击设备趋势 </span>
        <div class="board-wrap-chart">
          <Card-border></Card-border>
          <LineBar
            :lineBarData="devicesData"
            :chartIndex="0"
            seriesTitle="攻击设备"
          />
        </div>
      </div>
      <div class="board-wrap-bottom">
        <span class="board-wrap-title">攻击设备指数</span>
        <div class="board-wrap-chart">
          <Card-border></Card-border>
          <PieChart :pieData="levelDevData" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, toRefs, watch } from 'vue';
import { getIp, getTotaldevices, getFp, getLeveldevices, getFpday } from '@/api/environment.js';
import CardBorder from '@/components/common/card-border.vue';
import MapChart from '@/components/common/map.vue';
import LineBar from './common/line-bar.vue';
import PieChart from './common/pie-chart.vue';
import RankTable from './common/rank-table.vue';
const devLevel = ['一级', '二级', '三级', '四级'];
export default {
  components: {
    CardBorder,
    MapChart,
    LineBar,
    PieChart,
    RankTable
  },
  props: {
    timePicker: Object
  },
  setup (props) {
    const { timePicker } = toRefs(props);
    const allIpData = ref([]);
    const devicesData = ref([]);
    const brushDevData = ref([]);
    const levelDevData = ref([]);
    const rankDevData = ref([]);
    // 获取黑产IP分布
    const getEnvIp = async () => {
      const { data } = await getIp(timePicker.value);
      allIpData.value = data.map(item => {
        return {
          name: item.prov,
          value: item.num
        };
      });
    };
    // 获取攻击设备趋势
    const getEnvTotaldevices = async () => {
      const { data } = await getTotaldevices(timePicker.value);
      devicesData.value = data.devices.map(item => {
        return {
          time: item.day,
          device_total: item.total_device,
          device_num: item.device_nums
        };
      });
    };
    // 获取刷机设备变化
    const getEnvFp = async () => {
      const { data } = await getFp(timePicker.value);
      brushDevData.value = data.map(item => {
        return {
          time: item.dt,
          device_total: item.total,
          device_num: item.num
        };
      });
    };
    // 获取攻击设备指数
    const getEnvLeveldevices = async () => {
      const { data } = await getLeveldevices(timePicker.value);
      levelDevData.value = data.levelinfo.map(item => {
        return {
          name: devLevel[item.level - 1],
          value: item.device_nums,
          percent: Math.round(item.ratio * 10000) / 100
        };
      });
    };
    // 获取刷机设备排名TOP5
    const getEnvFpday = async () => {
      const { data } = await getFpday(timePicker.value);
      rankDevData.value = data;
    };
    // 监听传值
    watch(timePicker.value, () => {
      getEnvIp();
      getEnvTotaldevices();
      getEnvFp();
      getEnvLeveldevices();
      getEnvFpday();
    });

    getEnvIp();
    getEnvTotaldevices();
    getEnvFp();
    getEnvLeveldevices();
    getEnvFpday();

    return { allIpData, devicesData, brushDevData, levelDevData, rankDevData };
  }
}
</script>

<style scoped lang="less">
.board-wrap {
  position: relative;
  display: flex;
  height: 100%;
  &-title {
    font-size: 18px;
    line-height: 32px;
  }
  &-left {
    height: 100%;
    width: 45%;
    .left-map {
      position: relative;
      width: 100%;
      // min-height: 738px;
      height: calc(100% - 32px);
      padding: 10px;
    }
  }
  &-middle {
    padding: 0 22px;
    width: 30%;
    height: 100%;
  }
  &-box {
    height: 50%;
  }
  &-chart {
    position: relative;
    width: 100%;
    height: calc(100% - 32px);
    padding: 24px;
  }
  &-right {
    width: 25%;
    height: 100%;
  }
  &-bottom {
    height: 50%;
    padding-top: 22px;
  }
  &-table {
    height: 100%;
    position: relative;
    overflow-y: scroll;
  }
}
</style>
