import * as VueRouter from 'vue-router';
import Login from '@/modules/login/index';
import Nav from '@/modules/index';
import Home from '@/modules/home/index';
import Application from '@/modules/application/index';
import AppDetails from '@/modules/application/details';
import AppDetail from '@/modules/application/detailTable';
import Pirate from '@/modules/pirate/index';
import CrashLog from '@/modules/crashLog/index';
import LogDetails from '@/modules/crashLog/details';
import DataAnalysis from '@/modules/dataAnalysis/index';
import Environment from '@/modules/environment/index';
import Virus from '@/modules/virus/index';
import VirusMore from '@/modules/virus/more';
import RiskControl from '@/modules/riskControl/index';
import RiskMore from '@/modules/riskControl/more';
import RiskEquipment from '@/modules/riskEquipment';
import AppSafe from '@/modules/appSafe/index';
import AppCompliance from '@/modules/appCompliance/index';

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login
  },
  {
    path: '/home',
    name: 'Nav',
    component: Nav,
    children: [
      {
        path: '',
        name: 'Home',
        component: Home,
      },
      {
        path: '/application',
        name: 'Application',
        component: Application
      },
      {
        path: '/detail',
        name: 'AppDetails',
        component: AppDetails
      },
      {
        path: '/appDetail',
        name: 'AppDetail',
        component: AppDetail
      },
      {
        path: '/pirate',
        name: 'Pirate',
        component: Pirate
      },
      {
        path: '/crashLog',
        name: 'CrashLog',
        component: CrashLog
      },
      {
        path: '/logDetails',
        name: 'LogDetails',
        component: LogDetails
      },
      {
        path: '/dataAnalysis',
        name: 'DataAnalysis',
        component: DataAnalysis
      },
      {
        path: '/environment',
        name: 'Environment',
        component: Environment
      },
      {
        path: '/virus',
        name: 'Virus',
        component: Virus
      },
      {
        path: '/virusMore',
        name: 'VirusMore',
        component: VirusMore
      },
      {
        path: '/riskControl',
        name: 'RiskControl',
        component: RiskControl
      },
      {
        path: '/riskMore',
        name: 'RiskMore',
        component: RiskMore
      },
      {
        path: '/riskEquipment',
        name: 'RiskEquipment',
        component: RiskEquipment
      },
      {
        path: '/appSafe',
        name: 'AppSafe',
        component: AppSafe
      }, {
        path: '/appCompliance',
        name: 'AppCompliance',
        component: AppCompliance
      },
    ]
  }
];

const router = VueRouter.createRouter({
  history: VueRouter.createWebHashHistory(),
  routes
});

export default router;