<template>
  <a-table
    class="ant-table-app-detail"
    size="middle"
    :columns="columns"
    :data-source="tableData"
    :pagination="appTable"
    :rowClassName="
      (record, index) => (index % 2 === 1 ? 'table-striped' : null)
    "
  >
    <template #bodyCell="{ column, record }">
      <template v-if="column.key === 'action'">
        <span class="detail-action-btn" @click="handleToDetail(record)">
          查看应用详情
        </span>
      </template>
    </template>
  </a-table>
</template>
  
  <script>
import { ref, reactive } from 'vue';
import { columnsArr } from './columns';
import router from '@/router/index';

export default {
  props: {
    tableData: Array,
  },
  setup (props) {
    const appTable = reactive({
      showSizeChanger: false,
      total: props.tableData.length,
      showTotal: (total) => ` 共${total} 条数据`
    });
    const dataSource = ref([]);
    const columns = ref([]);
    const searcFilter = ref({});
    const getColumns = () => {
      columns.value = columnsArr;
    };
    // 跳转详情页
    const handleToDetail = (record) => {
      const { app_name = '', app_pkg = '', apk_cert_md5 = '' } = record;
      router.push(`/appDetail?appName=${app_name}&appPkg=${app_pkg}&appMd5=${apk_cert_md5}`);
      setTimeout(() => {
        window.location.reload();
      }, 500);
    };
    getColumns();
    return {
      searcFilter,
      dataSource,
      columns,
      appTable,
      handleToDetail
    };
  }
}
  </script>
  <style lang="less" >
@import url(./index.less);
@import url(../../../../style/a-table.less);
</style>