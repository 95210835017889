import dayjs from "dayjs";
export const columnsArr = [
    {
        title: '序号',
        customRender: ({ index }) => {
            return index + 1;
        },
        dataIndex: 'sort',
        key: 'sort',
        width: 80
    }, {
        title: '设备ID	',
        dataIndex: 'oaid',
        key: 'oaid',
        ellipsis: true
    }, {
        title: '设备型号',
        dataIndex: 'model',
        key: 'model',
        ellipsis: true
    }, {
        title: '安装状态',
        dataIndex: 'state',
        key: 'state',
        ellipsis: true
    }, {
        title: '最早安装时间',
        dataIndex: 'first_time',
        key: 'first_time',
        sorter: (a, b) => dayjs(a.first_time) - dayjs(b.first_time)

    }, {
        title: '最近活跃时间',
        dataIndex: 'recent_time',
        key: 'recent_time',
        sorter: (a, b) => dayjs(a.recent_time) - dayjs(b.recent_time)
    }, {
        title: '性别',
        dataIndex: 'sex',
        key: 'sex'
    }, {
        title: '年龄',
        dataIndex: 'age',
        key: 'age'
    }, {
        title: '设备标签',
        dataIndex: 'tags',
        key: 'tags'
    }, {
        title: '工作地',
        dataIndex: 'workplace',
        key: 'workplace',
        ellipsis: true

    }, {
        title: '家庭地',
        dataIndex: 'homeplace',
        key: 'homeplace',
        ellipsis: true
    }
];