<template>
  <div class="filter-box">
    <a-form
      ref="formRef"
      layout="inline"
      :model="formState"
      @finish="handleSearch"
    >
      <a-form-item label="设备型号" name="model">
        <a-input v-model:value="formState.model" placeholder="请输入..." />
      </a-form-item>
      <a-form-item label="标签" name="tags">
        <a-input v-model:value="formState.tags" placeholder="请输入..." />
      </a-form-item>
      <a-form-item label="安装状态" name="state">
        <a-select
          ref="select"
          v-model:value="formState.state"
          placeholder="请选择..."
        >
          <a-select-option value="安装">安装</a-select-option>
          <a-select-option value="卸载">卸载</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item name="selectTime">
        <div class="filter-datepicker">
          <a-range-picker
            v-model:value="formState.selectTime"
            value-format="YYYY-MM-DD"
            :showToday="false"
            :ranges="{
              今天: [dayjs(), dayjs()],
              最近一周: [
                dayjs().startOf('day').subtract(6, 'd'),
                dayjs().endOf('day')
              ],
              最近一个月: [
                dayjs().startOf('day').subtract(30, 'd'),
                dayjs().endOf('day')
              ],
              最近三个月: [
                dayjs().startOf('day').subtract(90, 'd'),
                dayjs().endOf('day')
              ]
            }"
          />
        </div>
      </a-form-item>
      <a-form-item>
        <div class="search-btn-box">
          <span class="search-btn search-btn-primary" @click="handleSearch">
            检索
          </span>
          <span class="search-btn" @click="handleReset"> 重置 </span>
        </div>
      </a-form-item>
    </a-form>
  </div>
</template>

<script>
import { ref, reactive } from 'vue';
import { Input } from 'ant-design-vue';
import dayjs from 'dayjs';
export default {
  props: {
    tabVal: String,
  },
  components: {
    [Input.name]: Input,

  },
  setup (props, vm) {
    const formRef = ref();
    const formState = reactive({
      selectTime: [dayjs(), dayjs().subtract(1, "month")]
    });
    // 查询
    const handleSearch = () => {
      const _this = vm;
      formRef.value.validate().then((res) => {
        let newParams = {};
        newParams = { ...res };
        if (res.selectTime) {
          newParams.st = res.selectTime[0];
          newParams.et = res.selectTime[1];
          delete newParams.selectTime;
        }
        _this.emit("searchData", newParams);
      });
    };
    // 重置
    const handleReset = () => {
      formRef.value.resetFields();
      vm.emit("searchData", {});
    };
    return {
      formRef,
      formState,
      dayjs,
      handleSearch,
      handleReset
    };
  }
}
</script>

<style  lang="less">
@import url(./index.less);
@import url(../../../../style/a-input.less);
@import url(../../../../style/a-datepicker.less);
</style>