<template>
  <div class="dotted-lines" :id="'dotted-lines' + chartIndex"></div>
</template>

<script>
import * as echarts from 'echarts';
import { toRefs, computed, onMounted, watch, onBeforeUnmount } from 'vue';
export default {
  props: {
    lineData: {
      type: Array,
      default: () => {[]}
    },
    chartIndex: Number
  },
  setup(props) {
    const { lineData } = toRefs(props);
    let myChart = {};

    const lineOptions = computed(() => {
      return {
        tooltip: {
          trigger: 'axis',
          backgroundColor: '#11151ee5',
          padding: 16,
          textStyle: {
            color: '#eaedff'
          },
          extraCssText: 'border: 1px dotted #eaedff99'
        },
        legend: {
          data: ['崩溃次数', '崩溃率'],
          top: '-1%',
          right: '28%',
          textStyle: {
            color: 'rgba(255, 191, 102, 0.85)',
            fontSize: 14
          },
          itemHeight: 10,
          itemGap: 35
        },
        grid: {
          left: '0',
          right: '0',
          bottom: '0',
          top: '16%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            axisLine: {
              lineStyle: {
                color: 'rgba(234, 237, 255, 0.3)',
              }
            },
            splitLine: {
              show: false,
            },
            axisTick: {
              show: false
            },
            splitArea: {
              show: false
            },
            axisLabel: {
              interval: 0,
              color: '#9FA2B1',
              fontSize: 12
              // rotate: 45
            },
            data: lineData.value.map(item => item.time)
          }
        ],
        yAxis: [
          {
            type: 'value',
            splitLine: {
              show: false
            },
            axisLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              interval: 0,
              color: '#9FA2B1',
              fontSize: 12
            },
            splitArea: {
              show: false
            }
          },
          {
            type: 'value',
            min: 0,
            max: 100,
            axisLabel: {
              formatter: '{value} %',
              color: 'rgba(234, 237, 255, 0.65)'
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: 'rgba(255, 255, 255, 0.1)'
              }
            }
          }
        ],
        series: [
          {
            name: '崩溃次数',
            type: 'line',
            symbolSize: 10,
            symbol: 'circle',
            itemStyle: {
              color: '#FF7366',
              borderColor: 'rgba(255, 255, 255, 0.33)',
              borderWidth: 3,
              borderType: 'solid'
            },
            label: {
              show: true,
              position: 'top',
              color: 'rgba(255, 115, 102, 1)'
            },
            lineStyle: {
              color: '#FF7366',
              width: 1,
              type: 'dashed'
            },
            data: lineData.value.map(item => item.count)
          },
          {
            name: '崩溃率',
            type: 'line',
            symbolSize: 10,
            symbol: 'circle',
            yAxisIndex: 1,
            itemStyle: {
              color: '#FFBF66',
              borderColor: 'rgba(255, 255, 255, 0.33)',
              borderWidth: 3,
              borderType: 'solid'
            },
            label: {
              show: true,
              position: 'top',
              color: 'rgba(255, 191, 102, 1)',
              formatter: (val) => {
                return val.value + '%'
              }
            },
            lineStyle: {
              color: '#FFBF66',
              width: 1,
              type: 'dashed'
            },
            data: lineData.value.map(item => item.percent)
          }
        ]
      }
    });

    const lineBox = () => {
      let dottedLine = echarts.getInstanceByDom(document.getElementById('dotted-lines' + props.chartIndex));
      if (dottedLine == null) {
        dottedLine = echarts.init(document.getElementById('dotted-lines' + props.chartIndex));
      }
      dottedLine.setOption(lineOptions.value);
      myChart = dottedLine
    };

    const resizeChart = () => {
      myChart.resize()
    }

    onMounted(() => {
      lineBox();
      window.addEventListener(
        "resize", resizeChart
      )
    });

    onBeforeUnmount(() => {
      window.removeEventListener(
          "resize", resizeChart
      )
    })

    // 监听传值，刷新图表
    watch(lineData, () => {
      lineBox();
    });

  }
}
</script>

<style scoped lang="less">
.dotted-lines {
  width: 100%;
  height: 100%;
}
</style>