<template>
    <div class="stageLine">
        <ul>
            <li>
                <div class="stage_header">
                    政策同意前
                    <span>{{ options.before || 0 }}</span>
                </div>
                <div class="stage_total">
                    <div class="stage_schedule">

                    </div>
                </div>
            </li>
            <li>
                <div class="stage_up"></div>
            </li>
            <li>
                <div class="stage_header">
                    应用运行时
                    <span>{{ options.running || 0 }}</span>
                </div>
                <div class="stage_total">
                    <div class="stage_schedule">

                    </div>
                </div>
            </li>
            <li>
                <div class="stage_up">

                </div>
            </li>
            <li>
                <div class="stage_header">
                    后台静默时
                    <span>{{ options.silence || 0 }}</span>
                </div>
                <div class="stage_total">
                    <div class="stage_schedule">

                    </div>
                </div>
            </li>
            <li>
                <div class="stage_up">

                </div>
            </li>
            <li>
                <div class="stage_header">
                    应用退出时
                    <span>{{ options.quit || 0 }}</span>
                </div>
                <div class="stage_total">
                    <div class="stage_schedule">

                    </div>
                </div>
            </li>
        </ul>
    </div>
</template>

<script>

import { ref } from 'vue';
import * as echarts from 'echarts';
import { toRefs, computed, onMounted, watch, onBeforeUnmount } from 'vue';

export default {
    name: 'RiskPic',
    props: {
        data: {
            type: Array,
            default: () => { []; }
        }
    },
    setup(props) {
        const { data } = toRefs(props);
        const options = ref({});
        const before = ref('100%');
        const quit = ref('100%');
        const running = ref('100%');
        const silence = ref('100%');
        watch(data, () => {
            options.value = data.value;
            before.value = `${data.value.before / data.value.total * 100}%`;
            quit.value = `${data.value.quit / data.value.total * 100}%`
            running.value = `${data.value.running / data.value.total * 100}%`
            silence.value = `${data.value.silence / data.value.total * 100}%`
        });
        return { options, before, quit, running, silence };
    }
}

</script>

<style scoped lang="less">
@font-face {
    font-family: 'Azonix';
    // src: url('#{$fa-font-path}/fontawesome-webfont.eot?v=#{$fa-version}');
    src: url('../../../assets/font/Azonix.otf') format('opentype'); // used when developing fonts
    font-weight: normal;
    font-style: normal;
}

.stageLine {
    display: flex;
    flex-direction: column;
    gap: 12px;
    height: 100%;
    position: relative;
    z-index: 2;

    ul {
        list-style: none;
        display: flex;
        flex-direction: column;
        gap: 12px;
        margin: 0;
        padding: 0;
        height: 100%;
        justify-content: space-between;
        overflow: auto;

        li {
            display: flex;
            flex-direction: column;
            gap: 8px;

            .stage_header {
                display: flex;
                align-items: center;
                gap: 8px;
                font-family: PingFang SC;
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
                letter-spacing: 0em;
                text-align: right;
                color: #EAEDFF;

                span {
                    font-family: Azonix;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 16px;
                    letter-spacing: 0em;
                    text-align: left;
                    color: #7CD8FF;
                }
            }

            .stage_total {
                width: 100%;
                height: 24px;
                background-color: #FFFFFF1A;
                border-radius: 8px;
                overflow: hidden;

                .stage_schedule {
                    // width: 80%;
                    height: 24px;
                    background: linear-gradient(270deg, #00A9FA 0%, rgba(82, 255, 203, 0) 100%);
                    border-radius: 8px;
                    overflow: hidden;
                }
            }

            .stage_up {
                background: url(../../../assets/images/down.png) no-repeat;
                background-position-x: 28px;
                width: 12px;
                height: 6px;
                padding: 0 28px;
            }

            &:nth-child(1) {
                .stage_schedule {
                    width: v-bind(before);
                }
            }

            &:nth-child(3) {
                .stage_schedule {
                    width: v-bind(running);
                }
            }

            &:nth-child(5) {
                .stage_schedule {
                    width: v-bind(silence);
                }
            }

            &:nth-child(7) {
                .stage_schedule {
                    width: v-bind(quit);
                }
            }
        }
    }
}
</style>