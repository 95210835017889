<template>
  <div class="card-num">
    <span :style="`color: ${color.name}`">{{ data.name || '-' }}</span>
    <CountUp
      v-if="data.index"
      :style="`color: ${color.value}`"
      :startVal="data.startValue"
      :endVal="data.value"
      :index="data.index"
    ></CountUp>
    <span v-else :style="`color: ${color.value}`">{{ data.value || 0 }}</span>
  </div>
</template>

<script>
import CountUp from "./countup.vue";
export default {
  props: {
    data: Object,
    color: Object
  },
  components: {
    CountUp
  },
  setup () {
    return { CountUp };
  }
}
</script>

<style scoped lang="less">
.card-num {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 16px 24px;
  border-radius: 16px;
  span:first-child {
    font-size: 12px;
    line-height: 22px;
  }
  span:last-child {
    font-family: 'Azonix';
    font-size: 38px;
    line-height: 38px;
  }
}
</style>
