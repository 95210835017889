import { get } from "../utils/request";
// 接口文档 http://doc.qiwoo.org/doc/view/5825

// 崩溃日志 -> 监测app列表
export const getApplist = (params) => get('/security/applist', params);

// 崩溃日志 -> 今日数据概览
export const getRealTime = (params) => get('/crash/realTime', params);

// 崩溃日志 -> 崩溃趋势 -> app版本号列表
export const getVersionList = (params) => get('/crash/versionList', params);

// 崩溃日志 -> 一天24小时崩溃趋势数据
export const getHourCount = (params) => get('/crash/hourCount', params);

// 崩溃日志 -> 崩溃总体趋势和用户崩溃分析
export const getDayCount = (params) => get('/crash/dayCount', params);

// // 崩溃日志 -> 崩溃趋势数据(按小时粒度)
// export const getHourCount = (params) => get('/crash/hourCount', params);

// 崩溃日志 -> 崩溃机型排行
export const getTopModel = (params) => get('/crash/topModel', params);

// 崩溃日志 -> 崩溃系统排行
export const getTopOS= (params) => get('/crash/topOS', params);

// 崩溃日志 -> 崩溃日志TOP10
export const getTopList = (params) => get('/crash/top10', params);

// 崩溃日志 -> 崩溃日志统计列表
export const getTableList = (params) => get('/crash/list', params);

// 崩溃日志 -> 崩溃机型TOP25
export const getTypeTop = (params) => get('/crash/topMO', params);
