<template>
  <span :id="'countup' + index"></span>
</template>

<script>
import CountUp from "countup.js";
import { toRefs, onMounted, watch, ref } from 'vue';
var options = {
  useEasing: true,
  useGrouping: true,
  separator: '',
  decimal: '',
};
export default {
  name: 'CountUp',
  props: {
    endVal: Object,
    startVal: Object,
    index: Number
  },
  setup (props) {
    const { endVal, startVal } = toRefs(props);
    let demo = ref({});
    onMounted(() => {
      demo.value = new CountUp('countup' + props.index, startVal.value || 0, endVal.value || 0, 0, 2, options);
      if (!demo.value.error) {
        demo.value.start();
      } else {
        console.error(demo.value.error);
      }
    });

    watch(endVal, (n) => {
      demo.value.update(n);
    });

  }
}    
</script>