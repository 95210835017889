import { get } from "../utils/request";
// 接口文档 http://doc.qiwoo.org/doc/view/5821

// 首页-> 数据概览
export const getHomeSummary = (params) => get('/security/summary', params);

// 首页 -> 近7日活跃用户数
export const getActiveUser = (params) => get('/security/activeuser', params);

// 首页 -> 仿冒影响设备数
export const getPirateSummary = (params) => get('/piracy/cmcc/app/summary', params);

