<template>
  <div class="terminal-wrap">
    <div class="terminal-wrap-top">
      <div class="top-device">
        <div class="top-device-app">
          <Card-border></Card-border>
          <span class="top-device-app-icon">
            <!-- <img src="@/assets/images/weather.png" /> -->
            <img :src="dataAppList.iconUrl" />
          </span>
          <div class="top-device-app-box">
            <div class="top-device-app-main">
              <span class="top-device-app-name">{{ dataAppList.appname }}</span>
              <span class="top-device-app-btn" @click="changeApp"
                >切换应用</span
              >
            </div>
            <div class="top-device-app-main">
              <span class="top-device-app-pk">资产主体：</span>
              <span class="top-device-app-text">{{ dataAppList.company }}</span>
            </div>
          </div>
          <div class="top-device-app-select">
            <SelectOption
              :appOptions="appLists"
              :show="selectOptionShow"
              @handelSelectApp="handelSelectApp"
            />
          </div>
        </div>
        <div class="top-device-start">
          <div class="start-box">
            <Card-border></Card-border>
            <span class="start-box-text">昨日启动数</span>
            <div class="start-box-main">
              <span class="start-box-num">{{ dataNumber.yesterdaypv }}</span>
              <span class="start-box-line">|</span>
              <span class="start-box-icon">
                <img
                  :src="dataNumber.yesterdaypvloop > 0 ? RiseIcon : DropIcon"
                />
              </span>
              <span
                class="start-box-percent"
                :class="dataNumber.yesterdaypvloop > 0 ? 'rise' : 'drop'"
                >{{ percent(dataNumber.yesterdaypvloop) }}%</span
              >
            </div>
          </div>
          <div class="start-box">
            <Card-border></Card-border>
            <span>昨日平均启动数</span>
            <div class="start-box-main">
              <span class="start-box-num">{{
                Math.floor(dataNumber.yesterdayperpv * 100) / 100
              }}</span>
              <span class="start-box-line">|</span>
              <span class="start-box-icon">
                <img
                  :src="dataNumber.yesterdayperpvloop > 0 ? RiseIcon : DropIcon"
                />
              </span>
              <span
                class="start-box-percent"
                :class="dataNumber.yesterdayperpvloop > 0 ? 'rise' : 'drop'"
                >{{ percent(dataNumber.yesterdayperpvloop) }}%</span
              >
            </div>
          </div>
        </div>
        <!-- <div class="top-device-card">
          <Card-border></Card-border>
          <div class="top-device-card-chart">
            <GaugeChart :rateData="activeDevData.active_rate" :rateIndex="0" rateName="活跃率" />
          </div>
          <div class="top-device-card-content">
            <span>活跃设备数</span>
            <span>{{activeDevData.active_device}}</span>
          </div>
          <div class="top-device-card-content">
            <span>设备总数</span>
            <span>{{activeDevData.cov}}</span>
          </div>
        </div> -->
        <div class="top-device-list">
          <Card-border></Card-border>
          <div class="top-device-list-tabs">
            <a-radio-group v-model:value="typeRadio">
              <a-radio-button
                v-for="(item, index) in typeOptions"
                :key="index"
                :value="item.label"
                @click="onClickType(item)"
                >{{ item.label }}</a-radio-button
              >
            </a-radio-group>
          </div>
          <div class="top-device-list-box">
            <div class="list-main">
              <span class="list-main-title">TOP5启动次数</span>
              <div class="list-main-chart">
                <BarItem :barChartData="barStartList" :barIndex="0" />
              </div>
            </div>
            <div class="list-main">
              <span class="list-main-title">TOP5新增用户</span>
              <div class="list-main-chart">
                <BarItem :barChartData="barUserList" :barIndex="1" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="top-map">
        <ScatterMap :seriesData="deviceData" />
      </div>
      <div class="top-risk">
        <div class="top-risk-calendar">
          <DatePicker @dataPickerTime="selectTime" />
        </div>
        <div class="top-risk-box">
          <Card-border></Card-border>
          <div class="top-risk-table">
            <a-table
              class="ant-table-striped"
              size="middle"
              :columns="columns"
              :data-source="tableList"
              :pagination="false"
              :rowClassName="
                (record, index) => (index % 2 === 1 ? 'table-striped' : null)
              "
            >
            </a-table>
          </div>
        </div>
      </div>
    </div>
    <div class="terminal-wrap-bottom">
      <div class="bottom-content">
        <Card-border></Card-border>
        <span class="bottom-title">启动次数</span>
        <div class="bottom-content-chart">
          <BarLine :barLineData="startAllData" :chartIndex="0" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, reactive } from 'vue';
import dayjs from 'dayjs';
import RiseIcon from '@/assets/images/dataAnalysis/rise.png';
import DropIcon from '@/assets/images/dataAnalysis/drop.png';
import { getApplist } from '@/api/crashLog.js';
import { getAllNumber, getActiveDevice, terminalList, startUpNumList } from '@/api/dataAnalysis.js';
import { Radio, Table } from 'ant-design-vue';
import CardBorder from '@/components/common/card-border.vue';
// import DatePicker from '@/components/common/date-picker.vue';
import DatePicker from '@/components/common/date-picker-one-day.vue';
// import GaugeChart from '@/components/common/gauge.vue';
import ScatterMap from './common/scatter-map.vue';
import BarItem from './common/bar-chart.vue';
import BarLine from './common/bar-line.vue';
import SelectOption from '@/components/common/select-option.vue';
const typeOptions = [
  {
    label: '版本',
    model: 'versionDetailTable',
    lineMethod: 'versionNewUserBar'
  },
  {
    label: '机型',
    model: 'deviceModelDetailTable',
    lineMethod: 'versionNewUserBar'
  },
  {
    label: '分辨率',
    model: 'resolutionDetailTable',
    lineMethod: 'resolutionNewUserBar'
  },
  {
    label: '系统',
    model: 'osDetailTable',
    lineMethod: 'osNewUserBar'
  },
  {
    label: '地域',
    model: 'areaDetailTable',
    lineMethod: 'areaNewUserBar'
  },
  {
    label: '网络',
    model: 'networkDetailTable',
    lineMethod: 'networkNewUserBar'
  },
  {
    label: '运营商',
    model: 'opcompanyDetailTable',
    lineMethod: 'opcompanyNewUserBar'
  }
];
export default {
  components: {
    CardBorder,
    DatePicker,
    // GaugeChart,
    ScatterMap,
    BarItem,
    BarLine,
    SelectOption,
    [Radio.Group.name]: Radio.Group,
    [Radio.Button.name]: Radio.Button,
    [Table.name]: Table
  },
  setup () {
    let nowTime = dayjs().subtract(1, 'day').format("YYYY-MM-DD"); // 昨天
    let lastTime = dayjs().subtract(6, 'day').format("YYYY-MM-DD"); //  前7天
    const typeRadio = ref('版本');
    const appLists = ref([]);
    const dataAppList = ref([]);
    const dataNumber = ref({});
    const activeDevData = ref({});
    const deviceData = ref([]);
    const barStartList = ref([]);
    const barUserList = ref([]);
    const startAllData = ref([]);
    const selectOptionShow = ref(false);
    const columns = ref([]);
    const tableList = ref([]);

    const appParams = reactive({
      pn: '',
      sign: ''
    });
    const timePicker = reactive({
      starttime: lastTime,
      endtime: nowTime
    });

    // 转化百分比
    const percent = (value) => {
      return Math.round(value * 10000) / 100;
    };
    // 日历选择
    const selectTime = (val) => {
      nowTime = val
      lastTime = val
      timePicker.starttime = lastTime;
      timePicker.endtime = nowTime;
      getDataApplist()
      getDataDevice()
    };
    // 切换类型
    const onClickType = (option) => {
      dataTerminalList(option.model, option.lineMethod);
    };
    // 获取监测app列表
    const getDataApplist = async () => {
      const { data } = await getApplist();
      appLists.value = data;
      dataAppList.value = data[2];
      appParams.pn = data[2].pn;
      appParams.sign = data[2].sign;
      getDataAllNumber();
      dataTerminalList(typeOptions[0].model, typeOptions[0].lineMethod);
      dataDeviceList();
      dataStartUpList();
    };
    // 切换应用
    const changeApp = () => {
      selectOptionShow.value = !selectOptionShow.value;
    };
    // 应用列表切换
    const handelSelectApp = async (name, show) => {
      const selectAppList = appLists.value.filter(item => item.appname === name);
      selectOptionShow.value = show;
      dataAppList.value = selectAppList[0];
      appParams.pn = selectAppList[0].pn;
      appParams.sign = selectAppList[0].sign;
      getDataAllNumber();
      dataTerminalList(typeOptions[0].model, typeOptions[0].lineMethod);
      dataDeviceList();
      dataStartUpList();
    };
    // 获取数据概览
    const getDataAllNumber = async () => {
      const params = { ...appParams, ...{ m: 'summaryTopNumbers' } };
      const { data } = await getAllNumber(params);
      dataNumber.value = data;
    };
    // 获取设备数
    const getDataDevice = async () => {
      const { data } = await getActiveDevice({ dt: '' });
      activeDevData.value = data;
    };
    // 获取终端类型列表数据
    const dataTerminalList = async (type, lineMethod) => {
      const urlParam = { ...appParams, ...{ m: type } };
      const params = { ...timePicker, ...{ lineMethod: lineMethod } };
      const { data } = await terminalList(urlParam, params);
      const barDataList = data.line.slice(0, 5);
      barStartList.value = barDataList.map(item => {
        return {
          name: item.dim,
          value: item.pv
        };
      });
      barUserList.value = barDataList.map(item => {
        return {
          name: item.dim,
          value: item.nu
        };
      });
      columns.value = data.table.thead.map((item, index) => {
        return {
          title: item,
          dataIndex: index,
          key: index
        };
      });
      tableList.value = data.table.data;
    };
    // 获取设备分布
    const dataDeviceList = async () => {
      const urlParam = { ...appParams, ...{ m: 'summaryArea' } };
      const { data } = await terminalList(urlParam, timePicker);
      deviceData.value = data.line;
    };
    // 获取启动次数
    const dataStartUpList = async () => {
      const newTimePicker = {
        endtime :dayjs(timePicker.starttime).format("YYYY-MM-DD"),
        starttime: dayjs(timePicker.endtime).subtract(6, 'day').format("YYYY-MM-DD"),
      }
      const { data } = await startUpNumList(appParams, newTimePicker);
      startAllData.value = data;
    };

    getDataApplist();
    getDataDevice();

    return {
      dataAppList,
      dataNumber,
      activeDevData,
      typeRadio,
      typeOptions,
      percent,
      onClickType,
      selectTime,
      deviceData,
      barStartList,
      barUserList,
      startAllData,
      RiseIcon,
      DropIcon,
      appLists,
      changeApp,
      handelSelectApp,
      selectOptionShow,
      columns,
      tableList
    };
  }
}
</script>

<style scoped lang="less">
@import url(./terminal.less);
</style>