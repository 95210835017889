<template>
  <div class="virusMore-wrap">
    <div class="virusMore-wrap-top">
      <div class="top-box">
        <span class="top-box-back" @click="goBack">
          <img src="@/assets/images/application/back.png" />
        </span>
        <span class="top-box-line"></span>
        <span class="top-box-text">风险设备详情</span>
      </div>
      <div class="top-right">
        <div class="top-right-search">
          <a-input-search placeholder="自定义关键词" @search="onSearch" />
        </div>
      </div>
    </div>
    <div class="virusMore-wrap-table">
      <Card-border></Card-border>
      <a-table
        class="ant-table-striped"
        size="middle"
        :show-total="(total) => `共 ${total} 条`"
        :columns="columns"
        :data-source="tableListData"
        :pagination="tablePage"
        @change="handlePageChange"
        :rowClassName="(record, index) => (index % 2 === 1 ? 'table-striped' : null)"
      >
      </a-table>
    </div>
  </div>
</template>

<script>
import { ref, reactive, onMounted, onBeforeUnmount } from 'vue';
import dayjs from 'dayjs';
import { InputSearch, Table } from 'ant-design-vue';
import CardBorder from '@/components/common/card-border.vue';
import { getDetail } from '@/api/virus.js';
import router from "@/router/index";

let nowTime = dayjs().startOf('week').add(1,'day').format("YYYY-MM-DD");

const columns = [
  {
    title: '排序',
    customRender: ({index}) => {
      return index + 1
    },
    dataIndex: 'sort',
    key: 'sort',
    width: 80
  },
  {
    title: '设备信息',
    dataIndex: 'm1',
    key: 'm1',
    ellipsis: true
  },
  {
    title: '应用包名',
    dataIndex: 'pn',
    key: 'pn',
    ellipsis: true
  },
  {
    title: '签名信息',
    dataIndex: 's',
    key: 's',
    ellipsis: true
  },
  {
    title: "主行为",
    dataIndex: "mb",
    key: "mb",
    width: "9%",
    customRender: ({ text }) => {
      return text || '-';
    },
  },
  {
    title: "子行为",
    dataIndex: "sb",
    key: "sb",
    width: "9%",
    customRender: ({ text }) => {
      return text || '-';
    },
  },
   {
    title: '风险名称',
    dataIndex: 'ave',
    key: 'ave',
    ellipsis: true
  },
  {
    title: '风险概述',
    dataIndex: 'ave_desc',
    key: 'ave_desc',
    ellipsis: true,
    width: "25%",
  }
];

export default {
  components: {
    CardBorder,
    [InputSearch.name]: InputSearch,
    [Table.name]: Table
  },
  setup() {
    const tableListData = ref([]);
    const tablePage = reactive({
      current: 1,
      total: 0,
      pageSize: 10,
      showSizeChanger: false
    });

    const setTableHeight = () => {
        if($(".virusMore-wrap-table").height()){
            const h = ($(".virusMore-wrap-table").height() - 42 - 56)/10 - 24
            $(".virusMore-wrap-table .ant-table-tbody td").height(h)
            $(".virusMore-wrap-table .ant-table").height($(".virusMore-wrap-table").height() - 56)
        }
    }

    onMounted(() => {
        window.addEventListener(
            "resize", setTableHeight
        )
    })

    onBeforeUnmount(() => {
        window.removeEventListener(
            "resize", setTableHeight
        )
    })

    // 获取设备详情
    const getRiskDetail = async (val) => {
      const res = await getDetail({ 
        dt: nowTime,
        page: tablePage.current,
        size: tablePage.pageSize,
        risk_level: router.currentRoute.value.query.level,
        words: val
      });
      tableListData.value = res.data;
      tablePage.total = res.total;
      setTimeout(()=>{
          setTableHeight()
      })
    };

    // 表格分页
    const handlePageChange = (val) => {
      tablePage.current = val.current;
      getRiskDetail();
    };

    // 返回
    const goBack = () => {
      window.history.go(-1);
    };

    // 搜索
    const onSearch = (value) => {
      tablePage.current = 1
      getRiskDetail(value);
    };

    getRiskDetail();

    return { goBack, onSearch, columns, tableListData, tablePage, handlePageChange };
  }
}
</script>

<style lang="less" scoped>
@import url("../../style/search.less");
.virusMore-wrap {
  display: flex;
  flex-direction: column;
  height: 100%;
  &-top {
    width: 100%;
    height: 44px;
    display: flex;
    justify-content: space-between;
    .top-box {
      width: 214px;
      height: 38px;
      display: flex;
      align-items: center;
      span {
        display: inline-block;
      }
      &-back {
        width: 38px;
        height: 38px;
        cursor: pointer;
        img {
          width: 100%;
        }
      }
      &-line {
        height: 34px;
        border-right: 2px solid #585b60;
        margin: 0px 15px;
      }
      &-text {
        font-size: 24px;
        line-height: 38px;
      }
    }
    .top-right {
      display: flex;
      width: auto;
      div:first-child {
        height: 44px;
        margin-right: 0px;
      }
    }
  }
  &-table {
    position: relative;
    margin-top: 24px;
    padding: 32px 24px 10px;
    height: calc(100% - 68px);
  }
}
</style>
