<template>
  <div class="linesChart" :id="'linesChart' + linesIndex"></div>
</template>

<script>
import * as echarts from 'echarts';
import dayjs from 'dayjs';
import { toRefs, computed, onMounted, watch, onBeforeUnmount } from 'vue';
import { getThemeColor } from '@/utils/addColor.js';

export default {
  props: {
    data: {
      type: Array,
      default: () => { []; }
    },
    linesIndex: Number
  },
  setup (props) {
    const { data } = toRefs(props);
    let myChart = {};
    const themStyles = getThemeColor();
    const lineOptions = computed(() => {
      return {
        tooltip: {
          trigger: 'axis',
          backgroundColor: '#11151ee5',
          padding: 16,
          textStyle: {
            color: '#eaedff'
          },
          extraCssText: 'border: 1px dotted #eaedff99',
          formatter: '覆盖率<br />{b}：{c}%'
        },
        grid: {
          left: '0',
          top: '5%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          nameTextStyle: {
            color: '#9FA2B1',
            fontSize: 12
          },
          axisLine: {
            lineStyle: {
              color: 'rgba(255, 255, 255, 0.2)'
            }
          },
          axisTick: {
            show: false
          },
          data: data.value.map(item => dayjs(item.day).format('MM/DD'))
        },
        yAxis: {
          type: 'value',
          splitLine: {
            lineStyle: {
              color: 'rgba(255, 255, 255, 0.2)'
            }
          },
          axisLabel: {
            color: '#9FA2B1',
            formatter: '{value} %'
          }
        },
        series: [{
          name: '覆盖率',
          type: 'line',
          itemStyle: {
            color: '#eaedff',
            borderColor: '#ffffff54'
          },
          lineStyle: {
            color: themStyles['--environmentLines']['line']
          },
          smooth: true,
          areaStyle: {
            color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [
              {
                offset: 0,
                color: `rgba(${themStyles['--environmentLines']['bg']}, 0.2)`

              },
              {
                offset: 1,
                color: `rgba(${themStyles['--environmentLines']['bg']}, 0.8)`
              }
            ])
          },
          data: data.value.map(item => Math.round(item.ratio * 10000) / 100)
        }]
      };
    });

    function linesChart () {
      let linesChart = echarts.getInstanceByDom(document.getElementById('linesChart' + props.linesIndex));
      if (linesChart == null) {
        linesChart = echarts.init(document.getElementById('linesChart' + props.linesIndex));
      }
      linesChart.setOption(lineOptions.value);
      myChart = linesChart;
    }

    const resizeChart = () => {
      myChart.resize();
    };

    onMounted(() => {
      linesChart();
      window.addEventListener(
        "resize", resizeChart
      );
    });

    onBeforeUnmount(() => {
      window.removeEventListener(
        "resize", resizeChart
      );
    });

    // 监听传值，刷新图表
    watch(data, () => {
      linesChart();
    });

  },
}
</script>

<style scoped lang="less">
.linesChart {
  width: 100%;
  height: 100%;
}
</style>
