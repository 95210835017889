<template>
    <div class="container">
        <CardBorder />
        <slot></slot>
    </div>
</template>

<script>
import { ref } from 'vue';
import CardBorder from '@/components/common/card-border.vue';
export default {
    name: 'HomePage',
    components: {
        CardBorder
    },
    props: {
        wh: {
            type: String,
            default: '100%'
        },
        ht: {
            type: String,
            default: '100%'
        },
        pd: {
            type: String,
            default: '24px'
        }
    },
    setup() {
        //   const logoConfig = ref({});

        //   const activeState = ref(location.hash.slice(1));

        //   return { menus, activeState, logoConfig, onClick };
    },
}
</script>

<style scoped lang="less">
.container {
    position: relative;
    border: 1px dotted saddlebrown;
    height: v-bind(ht);
    width: v-bind(wh);
    color: #fff;
    border: 16px;
    padding: v-bind(pd);
}
</style>