<template>
  <div class="history-map-wrap">
    <div class="history-map">
      <div id="gaomap" class="gaomap" style="width: 100%; height: 100%"></div>
      <div class="history-list">
        <div class="history-title">
          历史轨迹（{{ dataSource.length || 0 }}条）
        </div>
        <div class="history-tip">
          位置轨迹来源包含GPS、WIFI、IP，精度GPS > WIFI >
          IP。注：IP来源精度为街道级，可能会存在较大偏差，仅供参考
        </div>
        <div class="history-table">
          <a-table
            class="ant-table-map"
            size="middle"
            :loading="tableLoading"
            :columns="columns"
            :data-source="dataSource"
            :pagination="appTable"
            @change="handlePageChange"
            :scroll="{ y: 450 }"
            :rowClassName="
              (record, index) => (index % 2 === 1 ? 'table-striped' : null)
            "
          >
            <template #bodyCell="{ column, text, record, index }">
              <template v-if="column.dataIndex == 'location'">
                <div
                  class="history-table-location"
                  @click="handleSelectMark(record)"
                >
                  <div class="location-text">
                    {{ text || '-' }}
                  </div>
                  <div class="location-time">
                    {{ record.timestamp }}
                  </div>
                </div>
              </template>
              <template v-if="column.dataIndex == 'sort'">
                <div class="history-table-sort">
                  {{ index + 1 + (appTable.current - 1) * 10 }}
                </div>
              </template>
            </template>
          </a-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, reactive, watch } from 'vue';
import { getDevicedGpsHistory } from '@/api/riskEquipment.js';
import AMapLoader from '@amap/amap-jsapi-loader';
export default {
  props: {
    tabVal: String,
    oaid: String,
    filter: Object,
  },
  setup (props,) {
    window._AMapSecurityConfig = {
      securityJsCode: "4b4d07dc683b1cdc623704d0c6673aef",
    };
    const columns = [
      {
        title: '序号',
        customRender: ({ index }) => {
          return index + 1;
        },
        dataIndex: 'sort',
        key: 'sort',
        width: 80
      }, {
        title: '位置',
        dataIndex: 'location',
        key: 'location'
      }, {
        title: '来源',
        dataIndex: 'source',
        key: 'source',
        width: 60
      },
    ];
    const appTable = reactive({
      current: 1,
      showSizeChanger: false
    });
    const dataSource = ref([]);
    const tableLoading = ref(false);
    const tableMarker = ref([]);
    const map = ref(null);
    const AMapConfig = ref(null);
    const searcFilter = ref({});
    const mapInit = () => {
      AMapLoader.load({
        key: "f435621dcd09bb24bde0be3b7e5ac55c", // 申请好的Web端开发者Key，首次调用 load 时必填
        version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: ["AMap.Scale", "AMap.ToolBar", "AMap.ControlBar", 'AMap.Geocoder', 'AMap.Marker',
          'AMap.CitySearch', 'AMap.Geolocation', 'AMap.AutoComplete', 'AMap.InfoWindow'], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      }).then((AMap) => {
        // 获取到作为地图容器的DOM元素，创建地图实例
        //设置地图容器id
        AMapConfig.value = AMap;
        map.value = new AMap.Map("gaomap");
        // 如果有值则标记
        if (tableMarker.value.length > 0) {
          const marker = new AMap.Marker({
            position: [...tableMarker], //位置
          });
          map.value.add(marker);
        }
      }).catch(e => {
        console.log(e);
      });
    };
    // 获取历史轨迹
    const getHistoryData = async () => {
      tableLoading.value = true;
      let params = {};
      params.oaid = props.oaid;
      params = { ...params, ...searcFilter.value };
      const res = await getDevicedGpsHistory(params);
      tableLoading.value = false;
      if (res.error == 200) {
        dataSource.value = res.data || [];
        if (res.data.length > 0) {
          handleSelectMark(res.data[0]);
        }
      }
    };
    const handleSelectMark = (record) => {
      // longitude:经度
      // latitude: 维度
      const { latitude, longitude } = record;
      tableMarker.value = [longitude, latitude];
    };
    // 表格分页
    const handlePageChange = (val) => {
      appTable.current = val.current;
    };
    //监听位置变化并移动至标记位置
    watch(tableMarker, (val) => {
      if (map.value) {
        const marker = new AMapConfig.value.Marker({
          position: [...val], //位置
        });
        map.value.add(marker);
        map.value.setCenter([...val]);
      }
    });
    watch(() => props.filter, val => {
      appTable.current = 1;
      searcFilter.value = val;
      console.log(1, '1');
      getHistoryData();
    });

    getHistoryData();
    mapInit();
    return {
      columns,
      dataSource,
      appTable,
      tableLoading,
      handlePageChange,
      handleSelectMark
    };
  }
}
</script>

<style lang="less" >
@import url(./index.less);
</style>