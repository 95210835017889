<template>
  <a-date-picker
    :locale="locale"
    @change="selectTime"
    placeholder="选择日期"
    :disabled-date="disabledDate"
    v-model:value="date"
    format="YYYY-MM-DD"
  />
</template>

<script>
import locale from 'ant-design-vue/es/date-picker/locale/zh_CN';
import { handleChangeSearchDate } from "@/utils/index.js";
import { ref } from 'vue';
import dayjs from 'dayjs';

export default {
  props: {
    dataPickerTime: Function,
  },
  setup (props, vm) {
    const date = ref('');
    const customWeekStartEndFormat = ref('');
    // 初始化日期
    const handleDateInit = () => {
      // 如果本地存储有，则使用本地存储的数据
      const searchDate = localStorage.searchDate ? dayjs(localStorage.searchDate) : dayjs().subtract(1, "day");
      date.value = searchDate;
      getData();
    };
    // 日期选择
    const selectTime = (val) => {
      date.value = val ? val : dayjs().subtract(1, "day");
      handleChangeSearchDate(dayjs(date.value).format("YYYY-MM-DD"));
      getData();
    };

    // 弹出日历
    const openChange = (val) => {
      console.log(val);
    };

    // 不可选择的日期
    const disabledDate = current => {
      return current && current > dayjs().subtract(1, "day");
    };
    const getData = () => {
      const newDate = dayjs(date.value).format("YYYY-MM-DD");
      vm.emit("dataPickerTime", newDate);
    };
    handleDateInit();
    return {
      customWeekStartEndFormat,
      date,
      locale,
      selectTime,
      openChange,
      disabledDate,
    };
  }
}
</script>

<style lang="less">
.ant-picker {
  width: 100%;
  height: 100%;
  color: #eaedff !important;
  background: #11151ee5 !important;
  border-color: #ffffff4d !important;
  border-radius: 8px !important;
}

.ant-picker-input > input {
  color: #eaedff !important;
}
.ant-picker-suffix {
  color: #eaedff;
  .anticon {
    color: #eaedff;
  }
}
.ant-picker-separator {
  color: #eaedff;
}
.ant-picker-focused {
  border-color: #ffffff4d;
  box-shadow: none !important;
  border-right-width: 1px !important;
  outline: 0;
}
.ant-picker:hover,
.ant-picker-focused {
  border-color: #ffffff4d;
  border-right-width: 1px !important;
}
.ant-picker-dropdown {
  color: #eaedff !important;
  .ant-picker-panel-container {
    background: #11151ee5 !important;
    box-shadow: none !important;
    .ant-picker-panel {
      border: 1px solid #ffffff4d !important;
    }
  }
  .ant-picker-header {
    color: #eaedff;
    border-bottom: 1px solid #2b2c33;
    button {
      color: #eaedff;
    }
  }
  .ant-picker-content th {
    color: #eaedff;
  }
  .ant-picker-cell {
    color: #eaedff;
  }
  .ant-picker-week-panel-row-selected td,
  .ant-picker-week-panel-row-selected:hover td {
    background: #ebc5b826 !important;
  }
  .ant-picker-cell-in-view.ant-picker-cell-today
    .ant-picker-cell-inner::before {
    border-radius: 50%;
    border-color: var(--mainChartColor);
  }
  .ant-picker-week-panel-row:hover td {
    background: #ebc5b826 !important;
  }
}
</style>
