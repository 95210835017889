<template>
  <span
    class="disposal-btn"
    @click="handleModalChange(true)"
    v-if="btn !== false"
  >
    一键处置
  </span>
  <a-modal
    :visible="visible"
    :footer="null"
    width="640px"
    :closable="false"
    wrapClassName="disposal-modal"
  >
    <div class="disposal-modal-close" @click="handleClose">
      <img src="http://p0.qhimg.com/t01fc96df827b9a9dd9.png" alt="" />
    </div>
    <div class="disposal-title">请选择需要一键处置的应用</div>
    <div class="disposal-content">
      <div class="disposal-top">
        <div class="disposal-top-checkAll">
          <a-checkbox
            v-model:checked="checkAll"
            :indeterminate="indeterminate"
            @change="handleCheckAll"
          >
            <span class="checkbox-text">全选</span>
          </a-checkbox>
        </div>
        <div class="disposal-top-checkbox">
          <a-checkbox-group
            v-model:value="checkList"
            style="width: 100%"
            @change="handleCheckChange"
          >
            <a-row :gutter="[16, 16]">
              <a-col :span="12" v-for="item in plainOptions" :key="item.value">
                <a-checkbox :value="item.value">
                  <img class="checkbox-icon" src="" alt="" />
                  <span class="checkbox-text">{{ item.label }}</span>
                </a-checkbox>
              </a-col>
            </a-row>
          </a-checkbox-group>
        </div>
        <div class="disposal-line"></div>
      </div>
      <div class="disposal-bottom">
        <div class="disposal-bottom-left">
          已选
          <span class="disposal-check-num">{{ checkList.length }}</span>
          项
        </div>
        <div class="disposal-bottom-right">
          <!-- <a-button ghost @click="handleModalChange(false)">风险提示</a-button> -->
          <a-button ghost @click="handleModalChange()">应用崩溃</a-button>
        </div>
      </div>
    </div>
  </a-modal>
  <a-modal
    :visible="false"
    :footer="null"
    width="360px"
    :closable="false"
    wrapClassName="disposal-confirm-modal"
  >
    <div class="disposal-confirm-title">请确认是否要应用崩溃</div>
    <div class="disposal-confirm-content">
      <div class="disposal-confirm-top">
        <span>崩溃应用</span>
        <a-tooltip>
          <template #title
            >确认为【应用崩溃】的应用将无法在该风险设备上正常运行</template
          >
          <div class="disposal-confirm-hit"></div>
        </a-tooltip>
      </div>
      <div class="disposal-confirm-content">
        <div class="" v-for="item in checkList" :key="item.label">
          <div class="disposal-confirm-app">
            <img class="checkbox-icon" src="" alt="" />
            <span class="checkbox-text">{{ item.label }}</span>
          </div>
        </div>
      </div>
      <div class="disposal-confirm-bottom">
        <div class="disposal-bottom-right">
          <a-button ghost @click="handleModalChange()">取消</a-button>
          <a-button ghost @click="handleModalChange()">确定</a-button>
        </div>
      </div>
    </div>
  </a-modal>
</template>

<script>
import { ref, watch } from 'vue';
import { message } from 'ant-design-vue';
export default {
  props: {
    visible: Boolean,
    onClose: Function,
    btn: {
      type: Boolean,
      default: () => { true; }
    },
  },
  setup (props, vm) {
    const indeterminate = ref(false);
    const checkAll = ref(false);
    const checkList = ref([]);
    const plainOptions = ref([
      {
        label: "360天气",
        icon: "",
        value: "360",
      }, {
        label: "斗鱼",
        icon: "",
        value: "斗鱼",
      }, {
        label: "快看",
        icon: "",
        value: "快看",
      }, {
        label: "飞猪",
        icon: "",
        value: "飞猪",
      }, {
        label: "快手",
        icon: "",
        value: "快手",
      }, {
        label: "虎牙",
        icon: "",
        value: "虎牙",
      }, {
        label: "抖音",
        icon: "",
        value: "抖音",
      }, {
        label: "QQ",
        icon: "",
        value: "qq",
      }, {
        label: "微信",
        icon: "",
        value: "微信",
      }, {
        label: "虎牙",
        icon: "",
        value: "虎牙",
      }, {
        label: "抖音",
        icon: "",
        value: "抖音",
      }, {
        label: "QQ",
        icon: "",
        value: "qq",
      }, {
        label: "微信",
        icon: "",
        value: "微信",
      }, {
        label: "虎牙",
        icon: "",
        value: "虎牙",
      }, {
        label: "抖音",
        icon: "",
        value: "抖音",
      }, {
        label: "QQ",
        icon: "",
        value: "qq",
      }, {
        label: "微信",
        icon: "",
        value: "微信",
      }, {
        label: "虎牙",
        icon: "",
        value: "虎牙",
      }, {
        label: "抖音",
        icon: "",
        value: "抖音",
      }, {
        label: "QQ",
        icon: "",
        value: "qq",
      }, {
        label: "微信",
        icon: "",
        value: "微信",
      }, {
        label: "虎牙",
        icon: "",
        value: "虎牙",
      }, {
        label: "抖音",
        icon: "",
        value: "抖音",
      }, {
        label: "QQ",
        icon: "",
        value: "qq",
      }, {
        label: "微信",
        icon: "",
        value: "微信",
      }, {
        label: "虎牙",
        icon: "",
        value: "虎牙",
      }, {
        label: "抖音",
        icon: "",
        value: "抖音",
      }, {
        label: "QQ",
        icon: "",
        value: "qq",
      }, {
        label: "微信",
        icon: "",
        value: "微信",
      }, {
        label: "虎牙",
        icon: "",
        value: "虎牙",
      }, {
        label: "抖音",
        icon: "",
        value: "抖音",
      }, {
        label: "QQ",
        icon: "",
        value: "qq",
      }, {
        label: "微信",
        icon: "",
        value: "微信",
      }
    ]);

    // 全选
    const handleCheckAll = (e) => {
      checkList.value = e.target.checked ? plainOptions.value.map((item) => {
        return item.value;
      }) : [];
      indeterminate.value = false;
    };
    // 选择
    const handleCheckChange = (e) => {
      checkList.value = e;
    };
    const handleModalChange = () => {
      // visible.value = state;
      if (checkList.value.length === 0) {
        message.error("请选择需要处置的应用");
      }
    };
    // 关闭模态框
    const handleClose = () => {
      vm.emit('onClose', false);
    };
    // 监听传值，刷新图表
    watch(() => checkList.value, val => {
      indeterminate.value = !!val.length && val.length < plainOptions.value.length;
      checkAll.value = val.length === plainOptions.value.length;
    });
    return {
      checkList,
      checkAll,
      plainOptions,
      indeterminate,
      handleModalChange,
      handleCheckAll,
      handleCheckChange,
      handleClose
    };
  },
}
</script>

<style lang="less" >
@import url(./index.less);
</style>