<template>
  <div class="appDetailTable-wrap">
    <div class="appDetailTable-wrap-top">
      <div class="top-box">
        <span class="top-box-back" @click="goBack">
          <img :src="getImgPath('application/back.png')" />
        </span>
        <span class="top-box-line"></span>
        <span class="top-box-text">应用详情</span>
      </div>
      <div class="top-right">
        <div class="search-box">
          <a-select :value="selectVal" @change="handleSearchTypeChange">
            <a-select-option
              :value="item.value"
              v-for="item in commonFilter"
              :key="item.value"
            >
              {{ item.label }}
            </a-select-option>
          </a-select>
          <a-input-search
            placeholder="请输入..."
            style="width: 486px"
            @change="handleSearchKeyWord"
            @search="onSearch"
            :loading="searchLoading"
          />
        </div>
      </div>
    </div>
    <div class="appDetailTable-appInfo">
      <Card-border></Card-border>
      <div class="appInfo-box">
        <div class="appInfo-left">
          <div class="user-type">
            <img
              :src="
                appInfo.icon || 'http://p0.qhimg.com/t01042beca808bc86f0.png'
              "
              alt=""
              onerror="http://p0.qhimg.com/t01042beca808bc86f0.png"
            />
          </div>
        </div>
        <div class="appInfo-right">
          <div class="appInfo-name">
            <span>
              {{ deviceInfo.app_name || '-' }}
              <div
                class="appInfo-copy"
                @click="handleCopy(deviceInfo.app_name || '-')"
              >
                <copy-outlined />
              </div>
            </span>
          </div>
          <div class="appInfo-userInfo">
            <div class="userInfo-sex userInfo-tag" v-if="deviceInfo.app_tags">
              {{ deviceInfo.app_tags }}
            </div>
            <span v-else>-</span>
          </div>
          <div class="appInfo-list">
            <div
              class="appInfo-card"
              v-for="(item, index) in cardConfig"
              :key="index"
            >
              <div class="appInfo-card-bottom">
                <div
                  class="appInfo-card-bottom-list"
                  v-for="data in item.list"
                  :key="data.key"
                >
                  <div class="list-label">{{ data.label }}：</div>
                  <div class="list-value">
                    {{ deviceInfo[data.key] || '-' }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="appDetailTable-tablebox">
      <div class="appDetailTable-tablebox-tabs">
        <a-radio-group v-model:value="activeTab" @change="handleRadioChange">
          <a-radio-button
            v-for="item in tabConfig"
            :key="item.value"
            :value="item.value"
          >
            {{ item.label }}
          </a-radio-button>
        </a-radio-group>
      </div>
      <div class="appDetailTable-tablebox-table">
        <Card-border></Card-border>
        <div class="appDetailTable-table" v-if="activeTab === '1'">
          <div class="table-filter">
            <div class="filter-right">
              <Filter :tabVal="activeTab" @searchData="handleSearchData" />
            </div>
          </div>
          <Table
            :tabVal="activeTab"
            :deviceInfo="deviceInfo"
            :filter="filter"
          />
        </div>
        <div class="appDetailTable-chart" v-if="activeTab === '2'">
          <a-spin :spinning="!chartData ? true : false" tip="加载中" />
          <a-empty v-if="!chartData || chartData.length === 0" />
          <line-chart v-else :lineBarData="chartData" />
        </div>
      </div>
    </div>
  </div>
  <a-modal
    v-model:visible="searchTableVisible"
    :footer="null"
    wrapClassName="table-modal"
    :closable="false"
    :width="1080"
  >
    <div class="table-modal-close" @click="handleClose">
      <img src="http://p0.qhimg.com/t01fc96df827b9a9dd9.png" alt="" />
    </div>
    <div class="table-title">APP画像列表</div>
    <detail-list :tableData="searchTableData" />
  </a-modal>
</template>
  
<script>
import { ref } from 'vue';
import useClipboard from "vue-clipboard3";
import { CopyOutlined } from '@ant-design/icons-vue';
import CardBorder from "@/components/common/card-border.vue";
import LineChart from "./components/Chart/line-bar.vue";
import Filter from "./components/Filter";
import { getImgPath } from '@/utils/addColor.js';
import { getAppInfo, getAppInstallLifeCycle, getInfo } from '@/api/application.js';
import Table from './components/Table/index.vue';
import DetailList from './components/DetailList/index.vue';
import router from '@/router/index';
import weatherIcon from "@/assets/images/weather.png";

import { message } from 'ant-design-vue';
const cardConfig = [
  {
    list: [
      {
        label: "APP包名",
        key: "app_pkg"
      }, {
        label: "APP类型",
        key: "language"
      }
    ],
    img: 'test'
  },
  {
    list: [
      {
        label: "历史装机量",
        key: "installs"
      }, {
        label: "签名MD5",
        key: "apk_cert_md5"
      }
    ]
  },
];
const tabConfig = [
  {
    label: "安装设备列表",
    value: "1"
  }, {
    label: "生命周期",
    value: "2"
  }
];
const commonFilter = [
  {
    label: "APP名称",
    value: "app_name",
  },
  {
    label: "APP包名",
    value: "app_pkg",
  },
  {
    label: "APP签名MD5",
    value: "apk_cert_md5",
  },
];
const commonDic = {
  appName: 'app_name',
  appMd5: 'apk_cert_md5',
  appPkg: 'app_pkg',
};
export default {
  components: {
    CardBorder,
    Filter,
    Table,
    LineChart,
    DetailList,
    CopyOutlined
  },
  setup () {
    const selectVal = ref('app_name');//搜索条件
    const appInfo = ref({});//应用信息
    const activeTab = ref('1');//TAB
    const keyWrods = ref('');//搜索关键字
    const deviceInfo = ref({});//设备信息
    const filter = ref({});//筛选条件
    const chartData = ref(null);//图表数据
    const params = router.currentRoute.value.query;
    const searchTableData = ref(null);//搜索结果
    const searchTableVisible = ref(false);
    const searchLoading = ref(false);
    const { toClipboard } = useClipboard();
    // tab切换
    const handleRadioChange = (e) => {
      const val = e.target.value;
      activeTab.value = val;
    };
    // 获取单个应用信息
    const getAppInfoData = async () => {
      let newParams = {};
      for (let i in params) {
        newParams[commonDic[i]] = params[i];
      }
      const { data } = await getInfo({
        sign: 0,
        app: newParams.app_name
      });
      appInfo.value = data;
    };
    // 获取设备信息
    const getDeviceInfoData = async () => {
      let newParams = {};
      for (let i in params) {
        newParams[commonDic[i]] = params[i];
      }
      const data = await getData({ ...newParams });
      if (data[0]) {
        let newData = { ...data[0] };
        deviceInfo.value = newData;
        getAppInstallLifeCycleData();
      }
    };
    const handleClose = () => {
      searchTableVisible.value = false;
    };
    // 详情内容查询
    const onSearch = async () => {
      let params = {};
      if (keyWrods.value) {
        searchLoading.value = true;
        params[selectVal.value] = keyWrods.value;
        const data = await getData(params);
        searchLoading.value = false;
        searchTableData.value = data;
        searchTableVisible.value = true;
      }
    };
    // 查询数据并返回
    const getData = async (params) => {
      const res = await getAppInfo(params);
      if (res.error == 200) {
        return res.data || [];
      }
      return [];
    };
    //查询数据
    const handleSearchData = async (filterData) => {
      filter.value = filterData;
    };
    const handleSearchTypeChange = (val) => {
      selectVal.value = val;
    };
    const handleSearchKeyWord = (e) => {
      const val = e.target.value;
      keyWrods.value = val;
    };
    const goBack = () => {
      router.push('/application');
    };

    const getAppInstallLifeCycleData = async () => {
      let parmas = {
        app_pkg: deviceInfo.value.app_pkg || '',
        apk_cert_md5: deviceInfo.value.apk_cert_md5 || '',
      };
      const res = await getAppInstallLifeCycle(parmas);
      if (res.error == 200) {
        chartData.value = res.data;
      }
    };
    const handleCopy = async (text) => {
      try {
        await toClipboard(text);  //实现复制
        message.success("复制成功");
      } catch (e) {
        console.error(e);
      }
    };
    getDeviceInfoData();
    getAppInfoData();
    return {
      appInfo,
      selectVal,
      activeTab,
      getImgPath,
      cardConfig,
      tabConfig,
      deviceInfo,
      keyWrods,
      filter,
      searchTableVisible,
      searchTableData,
      searchLoading,
      chartData,
      commonFilter,
      handleCopy,
      handleRadioChange,
      handleSearchData,
      handleSearchTypeChange,
      handleSearchKeyWord,
      onSearch,
      goBack,
      handleClose
    };
  }
}
</script>
<style lang="less" >
@import url(./detailTable.less);
@import url(../../style/a-table.less);
</style>