<template>
  <div class="dotted-line" :id="'dotted-line' + chartIndex"></div>
</template>

<script>
import * as echarts from 'echarts';
import dayjs from 'dayjs';
import { toRefs, computed, onMounted, watch, onBeforeUnmount } from 'vue';
export default {
  props: {
    lineDataLists: Object,
    chartIndex: Number
  },
  setup(props) {
    const { lineDataLists } = toRefs(props);
    let myChart = {};

    const lineOptions = computed(() => {
      const { newuserDaily, remainuserDaily, remainuserDpw, remainuserDpm } = lineDataLists.value;
      // 转化百分比
      const percent = (value) => {
        return Math.round(value * 10000) / 100;
      };
      return {
        tooltip: {
          trigger: 'axis',
          backgroundColor: '#11151ee5',
          padding: 16,
          textStyle: {
            color: '#eaedff'
          },
          extraCssText: 'border: 1px dotted #eaedff99',
          formatter: (params) => {
            return `
              <div>
                <div>${params[0].seriesName}：${params[0].data}</div>
                <div>${params[1].seriesName}：${params[1].data}</div>
                <div>${params[2].seriesName}：${params[2].data}%</div>
                <div>${params[3].seriesName}：${params[3].data}%</div>
              </div>`
          }
        },
        legend: {
          data: ['新增用户', '日活用户', '日活/周活', '日活/月活'],
          top: '-1%',
          right: '0',
          textStyle: {
            color: 'inherit',
            fontSize: 14
          },
          itemHeight: 10,
          itemGap: 25
        },
        grid: {
          left: '0',
          right: '0',
          bottom: '0',
          top: '16%',
          containLabel: true,
        },
        xAxis: [
          {
            type: 'category',
            axisLine: {
              lineStyle: {
                color: 'rgba(234, 237, 255, 0.3)',
              }
            },
            splitLine: {
              show: false,
            },
            axisTick: {
              show: false
            },
            splitArea: {
              show: false
            },
            axisLabel: {
              interval: 0,
              color: '#9FA2B1',
              fontSize: 12,
            },
            data: newuserDaily && newuserDaily.map(item => dayjs(item.timelong).format('YYYY-MM-DD'))
          }
        ],
        yAxis: [
          {
            type: 'value',
            splitLine: {
              show: false
            },
            axisLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              interval: 0,
              color: '#9FA2B1',
              fontSize: 12
            },
            splitArea: {
              show: false
            }
          },
          {
            type: 'value',
            min: 0,
            max: 100,
            axisLabel: {
              formatter: '{value} %',
              color: 'rgba(234, 237, 255, 0.65)'
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: 'rgba(255, 255, 255, 0.1)'
              }
            }
          }
        ],
        series: [
          {
            name: '新增用户',
            type: 'line',
            symbolSize: 5,
            symbol: 'circle',
            itemStyle: {
              color: 'rgba(234, 237, 255, 1)',
              borderColor: 'rgba(234, 237, 255, 1)',
              borderWidth: 3,
              borderType: 'solid'
            },
            label: {
              show: true,
              position: 'top',
              color: 'rgba(234, 237, 255, 1)'
            },
            lineStyle: {
              color: 'rgba(234, 237, 255, 1)',
              width: 1
            },
            data: newuserDaily && newuserDaily.map(item => item.total)
          },
          {
            name: '日活用户',
            type: 'line',
            symbolSize: 10,
            symbol: 'circle',
            itemStyle: {
              color: '#FFBF66',
              borderColor: 'rgba(255, 255, 255, 0.33)',
              borderWidth: 3,
              borderType: 'solid'
            },
            label: {
              show: true,
              position: 'top',
              color: 'rgba(255, 191, 102, 1)'
            },
            lineStyle: {
              color: '#FFBF66',
              width: 1,
              type: 'dashed'
            },
            data: remainuserDaily && remainuserDaily.map(item => item.total)
          },
          {
            name: '日活/周活',
            type: 'line',
            symbolSize: 10,
            symbol: 'circle',
            yAxisIndex: 1,
            itemStyle: {
              color: '#FF7366',
              borderColor: 'rgba(255, 255, 255, 0.33)',
              borderWidth: 3,
              borderType: 'solid'
            },
            label: {
              show: true,
              position: 'top',
              color: 'rgba(255, 115, 102, 1)',
              formatter: (val) => {
                return val.value + '%'
              }
            },
            lineStyle: {
              color: '#FF7366',
              width: 1,
              type: 'dashed'
            },
            data: remainuserDpw && remainuserDpw.map(item => percent(item.total))
          },
          {
            name: '日活/月活',
            type: 'line',
            symbolSize: 10,
            symbol: 'circle',
            yAxisIndex: 1,
            itemStyle: {
              color: '#FF7366',
              borderColor: 'rgba(255, 255, 255, 0.33)',
              borderWidth: 3,
              borderType: 'solid'
            },
            label: {
              show: true,
              position: 'top',
              color: 'rgba(255, 115, 102, 1)',
              formatter: (val) => {
                return val.value + '%'
              }
            },
            lineStyle: {
              color: '#FF7366',
              width: 1,
              type: 'dashed'
            },
            data: remainuserDpm && remainuserDpm.map(item => percent(item.total))
          }
        ]
      }
    });

    const lineBox = () => {
      let dottedLine = echarts.getInstanceByDom(document.getElementById('dotted-line' + props.chartIndex));
      if (dottedLine == null) {
        dottedLine = echarts.init(document.getElementById('dotted-line' + props.chartIndex));
      }
      dottedLine.setOption(lineOptions.value);
      myChart = dottedLine
    };

    const resizeChart = () => {
      myChart.resize()
    }

    onMounted(() => {
      lineBox();
      window.addEventListener(
        "resize", resizeChart
      )
    });

    onBeforeUnmount(() => {
      window.removeEventListener(
          "resize", resizeChart
      )
    })

    // 监听传值，刷新图表
    watch(lineDataLists, () => {
      lineBox();
    });

  }
}
</script>

<style scoped lang="less">
.dotted-line {
  width: 100%;
  height: 100%;
}
</style>