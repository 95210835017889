<template>
  <div class="tree-map" id="tree-map"></div>
</template>

<script>
import * as echarts from 'echarts';
import { toRefs, computed, onMounted, watch, onBeforeUnmount } from 'vue';
import { getThemeColor } from '@/utils/addColor.js';
export default {
  props: {
    treeMapData: {
      type: Array,
      default: () => { []; }
    }
  },
  setup (props) {
    const { treeMapData } = toRefs(props);
    let myChart = {};
    const themStyles = getThemeColor();
    const setOptions = computed(() => {
      let data = [];
      treeMapData.value.map(item => {
        data.push({
          name: item.name,
          value: item.ratio
        });
      });
      return {
        tooltip: {
          trigger: 'item',
          backgroundColor: '#11151ee5',
          padding: 16,
          textStyle: {
            color: '#eaedff'
          },
          extraCssText: 'border: 1px dotted #eaedff99',
          formatter: (params) => {
            return params.name + '：' + Math.round(params.value * 10000) / 100 + '%';
          }
        },
        color: themStyles['--environmentTreeMap']['colorList'],
        series: [
          {
            type: 'treemap',
            width: '100%',
            height: '100%',
            roam: false,
            nodeClick: false, // 点击节点后的行为,false无反应
            breadcrumb: {
              show: false
            },
            label: {
              show: true,
              position: ['10%', '40%'],
              formatter: (params) => {
                return `{a|${params.name}: }{b|${Math.round(params.value * 10000) / 100}%}`;
              },
              fontSize: 14,
              rich: {
                a: {
                  color: '#EAEDFF'
                },
                b: {
                  color: themStyles['--environmentTreeMap']['fontColor']
                }
              }
            },
            itemStyle: {
              show: true,
              borderWidth: 1,
              borderRadius: 4,
              borderColor: '#10151E',
            },
            data: data
          }
        ]
      };
    });

    const treeMapChart = () => {
      let treeMap = echarts.getInstanceByDom(document.getElementById('tree-map'));
      if (treeMap == null) {
        treeMap = echarts.init(document.getElementById('tree-map'));
      }
      setOptions.value && treeMap.setOption(setOptions.value);
      myChart = treeMap;
    };

    const resizeChart = () => {
      myChart.resize();
    };

    onMounted(() => {
      treeMapChart();
      window.addEventListener(
        "resize", resizeChart
      );
    });

    onBeforeUnmount(() => {
      window.removeEventListener(
        "resize", resizeChart
      );
    });

    // 监听传值，刷新图表
    watch(treeMapData, () => {
      treeMapChart();
    });
  }
}
</script>

<style scoped lang="less">
.tree-map {
  width: 100%;
  height: 100%;
}
</style>