<template>
  <div class="riskMore-wrap">
    <div class="riskMore-wrap-top">
      <div class="top-box">
        <span class="top-box-back" @click="goBack">
          <img :src="getImgPath('application/back.png')" />
        </span>
        <span class="top-box-line"></span>
        <span class="top-box-text">风险设备总览</span>
      </div>
      <div class="top-right">
        <div class="top-right-search">
          <a-input-search placeholder="自定义关键词" @search="onSearch" />
        </div>
        <div>
          <DatePicker @dataPickerTime="selectTime" />
        </div>
      </div>
    </div>
    <div class="riskMore-wrap-table">
      <Card-border></Card-border>
      <a-table
        class="ant-table-riskMore"
        size="middle"
        :show-total="(total) => `共 ${total} 条`"
        :columns="columns"
        :data-source="tableListData"
        :pagination="tablePage"
        @change="handlePageChange"
        :rowClassName="
          (record, index) => (index % 2 === 1 ? 'table-striped' : null)
        "
      >
        <template #bodyCell="{ column, text, record }">
          <template v-if="column.dataIndex === 'm1'">
            <span @click="handleToDetail(record)">{{ text }}</span>
          </template>
          <template v-if="devRankArr.indexOf(column.dataIndex) !== -1">
            <img
              class="riskMore_state_icon"
              :src="getImgPath(`${text == '1' ? 'success' : 'error'}.png`)"
            />
          </template>
          <template v-if="column.dataIndex === '|'">
            <span class="risk_more_tabel_line">|</span>
          </template>
          <template v-if="column.dataIndex === 'ave_desc'">
            <div class="downBox">
              <!-- <Disposal @handleDisposal="disposal" /> -->
              <span class="down-text">
                {{ text }}
              </span>
            </div>
          </template>
        </template>
      </a-table>
    </div>
  </div>
</template>
<script>
import { ref, reactive, onMounted, onBeforeUnmount } from 'vue';
import dayjs from 'dayjs';
import { InputSearch, Table } from 'ant-design-vue';
// import DatePicker from '@/components/common/date-picker.vue';
import DatePicker from '@/components/common/date-picker-one-day.vue';
import CardBorder from '@/components/common/card-border.vue';
import Disposal from '@/components/common/disposal/index.vue';
import router from '@/router/index';
import { getDetail } from '@/api/riskControl.js';
import { getImgPath } from '@/utils/addColor.js';

let nowTime = dayjs().startOf('week').add(1, 'day').format("YYYY-MM-DD");

const riskLevel = {
  '1': '低风险',
  '2': '中风险',
  '3': '高风险',
  '4': '严重'
};
const devRankArr = ['is_ave', 'is_root', 'is_xposed', 'is_hook', 'is_ps', 'is_sdr'];
const columns = [
  {
    title: '排序',
    customRender: ({ index }) => {
      return index + 1;
    },
    dataIndex: 'sort',
    key: 'sort',
    width: 80
  },
  {
    title: '设备信息',
    dataIndex: 'm1',
    key: 'm1',
    ellipsis: true
  },
  {
    title: '风险等级',
    dataIndex: 'risk_level',
    key: 'risk_level',
    customRender: ({ text }) => {
      let texts = '';
      Object.keys(riskLevel).map(key => {
        if (key === text) {
          texts = riskLevel[key];
        }

      });
      return texts;
    },
    width: 100
  },
  {
    title: '地理位置',
    dataIndex: 'geo',
    key: 'geo',
    width: 100

  },
  {
    title: '|',
    dataIndex: '|',
    key: '|',
    width: 40,
    // customRender: () => {
    //   return '|';
    // }
  },
  {
    title: '病毒木马',
    dataIndex: 'is_ave',
    key: 'is_ave',
    width: 100
  },
  {
    title: 'Root设备',
    dataIndex: 'is_root',
    key: 'is_root',
    width: 100

  },
  {
    title: 'Xposed框架',
    dataIndex: 'is_xposed',
    key: 'is_xposed',
    width: 110
  },
  {
    title: 'Hook设备',
    dataIndex: 'is_hook',
    key: 'is_hook',
    width: 100
  },
  {
    title: '双开设备',
    dataIndex: 'is_ps',
    key: 'is_ps',
    width: 100

  },
  {
    title: '模拟器设备',
    dataIndex: 'is_sdr',
    key: 'is_sdr',
    width: 100
  },
  {
    title: '|',
    dataIndex: '|',
    key: '|',
    width: 40,
    // customRender: () => {
    //   return '|';
    // }
  },
  {
    title: '备注',
    dataIndex: 'ave_desc',
    key: 'ave_desc',
    ellipsis: true
  }
];

export default {
  components: {
    DatePicker,
    CardBorder,
    // Disposal,
    [InputSearch.name]: InputSearch,
    [Table.name]: Table
  },
  setup () {
    const tableListData = ref([]);
    const tablePage = reactive({
      current: 1,
      total: 0,
      pageSize: 10,
      showSizeChanger: false,
      showTotal: (total) => `共 ${total} 条数据`
    });
    const risklevel = ref('');

    const setTableHeight = () => {
      if ($(".riskMore-wrap-table").height()) {
        const h = ($(".riskMore-wrap-table").height() - 42 - 56) / 10 - 24;
        $(".riskMore-wrap-table .ant-table-tbody td").height(h);
        $(".riskMore-wrap-table .ant-table").height($(".riskMore-wrap-table").height() - 56);
      }
    };

    onMounted(() => {
      window.addEventListener(
        "resize", setTableHeight
      );
    });

    onBeforeUnmount(() => {
      window.removeEventListener(
        "resize", setTableHeight
      );
    });
    //风险等级
    const getRiskLevel = () => {
      risklevel.value = window.location.href.split("=")[1];
    };

    // 日期选择
    const selectTime = (val) => {
      nowTime = val;
      tablePage.current = 1;
      getRiskDetail();
    };

    // 获取设备详情
    const getRiskDetail = async (val) => {
      const res = await getDetail({
        dt: nowTime,
        page: tablePage.current,
        size: tablePage.pageSize,
        risk_level: risklevel.value,
        words: val
      });
      tableListData.value = res.data;
      tablePage.total = res.count;
      setTimeout(() => {
        setTableHeight();
      });
    };

    // 表格分页
    const handlePageChange = (val) => {
      tablePage.current = val.current;
      getRiskDetail();
    };

    // 返回
    const goBack = () => {
      window.history.go(-1);
    };

    // 搜索
    const onSearch = (value) => {
      tablePage.current = 1;
      getRiskDetail(value);
    };
    // 跳转详情页
    const handleToDetail = (record) => {
      const { m1 = "" } = record;
      router.push(`/riskEquipment?level=${risklevel.value}&m1=${m1}`);
    };


    getRiskLevel();
    getRiskDetail();

    return {
      getImgPath,
      columns,
      tableListData,
      tablePage,
      devRankArr,
      goBack,
      onSearch,
      selectTime,
      handlePageChange,
      handleToDetail
    };
  }
}
</script>

<style lang="less">
@import url('../../style/search.less');
.riskMore-wrap {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  &-top {
    width: 100%;
    height: 44px;
    display: flex;
    justify-content: space-between;
    .top-box {
      width: 214px;
      height: 38px;
      display: flex;
      align-items: center;
      span {
        display: inline-block;
      }
      &-back {
        width: 38px;
        height: 38px;
        cursor: pointer;
        img {
          width: 100%;
        }
      }
      &-line {
        height: 34px;
        border-right: 2px solid #585b60;
        margin: 0px 15px;
      }
      &-text {
        font-size: 24px;
        line-height: 38px;
      }
    }
    .top-right {
      width: 598px;
      display: flex;
      div {
        width: 287px;
        height: 44px;
        &:first-child {
          margin-right: 24px;
        }
      }
    }
  }
  &-table {
    position: relative;
    margin-top: 24px;
    padding: 32px 24px 10px;
    height: calc(100% - 68px);
  }
  .riskMore_state_icon {
    width: 32px;
    height: 20px;
  }
  .downBox {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    .disposal-btn {
      cursor: pointer;
      position: absolute;
      left: 20px;
      opacity: 0;
      z-index: -1;
    }

    .down-text {
      opacity: 1;
    }

    // &:hover {
    //   .disposal-btn {
    //     opacity: 1;
    //     z-index: 1;
    //   }

    //   .down-text {
    //     opacity: 0;
    //   }
    // }
  }
  .risk_more_tabel_line {
    color: #ffffff66;
  }
}
</style>
