<template>
  <div class="gaugeChart" :id="'gaugeChart' + rateIndex"></div>
</template>

<script>
import * as echarts from 'echarts';
import { computed, onMounted, toRefs, watch, onBeforeUnmount } from 'vue';
export default {
  name: 'GaugeChart',
  props: {
    rateData: String,
    rateIndex: Number,
    rateName: String
  },
  setup(props) {
    const { rateData, rateName } = toRefs(props);
    let myChart = {};

    const setOptions = computed(() => {
      let options = {
        series: [{
          type: 'gauge',
          startAngle: 90,
          endAngle: -270,
          min: 0,
          max: 1,
          splitNumber: 8,
          axisLine: {
            show: false
          },
          radius: "45",
          progress: {
            show: true,
            width: 8,
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [
                {
                  offset: 0,
                  color: 'rgba(255, 115, 102, 1)'
                },
                {
                  offset: 0.5,
                  color: 'rgba(255, 191, 102, 0.93)'
                },
                {
                  offset: 1,
                  color: 'rgba(255, 191, 102, 0.05)'
                },
              ]),
            }
          },
          pointer: {
            show: false
          },
          axisTick: {
            show: false
          },
          splitLine: {
            show: false
          },
          axisLabel: {
            show: false
          },
          title: {
            offsetCenter: [0, '-30%'],
            fontSize: 16,
            color: '#EAEDFF',
            fontWeight: 'bold'
          },
          detail: {
            fontSize: 15,
            offsetCenter: [0, '30%'],
            valueAnimation: true,
            formatter: function (value) {
              return (Math.round(value * 10000)) / 100 + '%';
            },
            color: '#9FA2B1'
          },
          data: [
            { 
              value: rateData.value,
              name: rateName.value,
            }
          ]
        }]
      }
      return options
    })

    function initChart(resize) {
      let gaugeChart = echarts.getInstanceByDom(document.getElementById('gaugeChart' + props.rateIndex));
      if (gaugeChart == null) {
        gaugeChart = echarts.init(document.getElementById('gaugeChart' + props.rateIndex));
      }
      gaugeChart.setOption(setOptions.value);
      myChart = gaugeChart
      if(resize){
          setTimeout(()=>{
            gaugeChart.resize();
          })
      }
    }

    const resizeChart = () => {
      myChart.resize()
    }

    onMounted(() => {
      initChart();
      window.addEventListener(
        "resize", resizeChart
      )
    });

    onBeforeUnmount(() => {
      window.removeEventListener(
          "resize", resizeChart
      )
    })

    // 监听传值，刷新图表
    watch(rateName, () => {
      initChart(true);
    });
  }
}
</script>

<style scoped lang="less">
.gaugeChart {
  width: 100%;
  height: 100%;
}
</style>
