import axios from "axios";
axios.defaults.withCredentials=true;
axios.defaults.baseURL = 'https://wentian.360.cn';


export function get (url, data) {
  return new Promise((resolve, reject) => {
    axios({
      method: 'GET',
      url,
      params: data
    })
    .then(res => resolve(res.data))
    .catch(err => reject(err))
  })
}

export function post (url, data) {
  return new Promise((resolve, reject) => {
    axios({
      method: 'POST',
      url,
      data
    })
    .then(res => resolve(res.data))
    .catch(err => reject(err))
  })
}
