import { get } from "../utils/request";
// 接口文档 http://doc.qiwoo.org/doc/view/5866

// 环境监测 -> 设备概览
export const getSummary = (params) => get('/env/cmcc/summary', params);

// 环境监测 -> 指标看版01 -> 黑产IP分布
export const getIp = (params) => get('/env/cmcc/ip', params);

// 环境监测 -> 指标看版01 -> 攻击设备趋势
export const getTotaldevices = (params) => get('/env/abnormaldevice/totaldevices', params);

// 环境监测 -> 指标看版01 -> 刷机设备变化
export const getFp = (params) => get('/env/cmcc/fp', params);

// 环境监测 -> 指标看版01 -> 攻击设备指数
export const getLeveldevices = (params) => get('/env/abnormaldevice/leveldevices', params);

// 环境监测 -> 指标看版01 -> 刷机设备排名TOP5
export const getFpday = (params) => get('/env/cmcc/fp_day', params);

// 环境监测 -> 指标看版02 -> 移动设备品牌排名
export const getVendor = (params) => get('/env/cmcc/vendor', params);

// 环境监测 -> 指标看版02 -> JTY品牌覆盖率变化趋势
export const getVendorTrend = (params) => get('/env/cmcc/vendor-trend', params);

// 环境监测 -> 指标看版02 -> 移动设备机型排名
export const getModel = (params) => get('/env/cmcc/model', params);

// 环境监测 -> 指标看版02 -> WTT&56品牌覆盖率变化趋势
export const getModelTrend = (params) => get('/env/cmcc/model-trend', params);

// 环境监测 -> 指标看版02 -> 设备联网方式
export const getNetworkTrend = (params) => get('/env/cmcc/network-trend', params);

// 环境监测 -> 指标看版02 -> 设备分辨率
export const getDpi = (params) => get('/env/cmcc/dpi', params);

// 环境监测 -> 指标看版02 -> 操作系统分布
export const getOstrend = (params) => get('/env/cmcc/os-trend', params);