<template>
  <div class="app-monitor">
    <div class="app-monitor-left">
      <div class="left-safe">
        <span
          v-if="safeData >= 0 && safeData < 30"
          class="left-safe-num left-safe-num-low"
        >
          {{ safeData }}
        </span>
        <span
          v-if="safeData >= 30 && safeData < 80"
          class="left-safe-num left-safe-num-middle"
        >
          {{ safeData }}
        </span>
        <span
          v-if="safeData >= 80 && safeData <= 100"
          class="left-safe-num left-safe-num-high"
        >
          {{ safeData }}
        </span>
        <span>安全指数</span>
        <video
          class="left-safe-background"
          :src="getImgPath('safetyindex.webm')"
          autoPlay="autoPlay"
          loop="loop"
          muted="muted"
        ></video>
      </div>
      <div class="left-rank">
        <div class="left-rank-title">
          <span class="left-rank-name">感染应用排名</span>
          <div class="left-rank-search">
            <a-select
              v-model:value="searchValue"
              show-search
              placeholder="请输入"
              :default-active-first-option="false"
              :show-arrow="false"
              :filter-option="false"
              :not-found-content="null"
              :options="searchData"
              @search="handleSearch"
              @change="handleChange"
            ></a-select>
          </div>
        </div>
        <div class="left-rank-list">
          <div class="left-rank-list-inner">
            <div class="left-rank-card">
              <Card :data="rankCardData" />
            </div>
            <div class="left-rank-table">
              <a-table
                class="ant-table-striped"
                size="middle"
                :columns="columns"
                :data-source="dataList"
                :pagination="false"
                :rowClassName="
                  (record, index) => (index % 2 === 1 ? 'table-striped' : null)
                "
                :locale="{ emptyText: '暂无数据' }"
              >
              </a-table>
              <div class="left-rank-pagination">
                <a-pagination
                  v-model:current="current"
                  :pageSize="pageSize"
                  simple
                  :total="total"
                  @change="onChange"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="app-monitor-right">
      <div class="right-card-infect">
        <Card-border-orange></Card-border-orange>
        <Card-number
          :data="infectDev"
          :style="'background: #282328'"
          :color="infectColor"
        ></Card-number>
      </div>
      <div class="right-card-all">
        <Card-border></Card-border>
        <Card-number
          :data="deviceAll"
          :style="'background: #eaedff1a'"
          :color="deviceColor"
        ></Card-number>
      </div>
      <div class="right-map">
        <MapChart
          :seriesData="allAppData"
          @mouseoverMap="mouseoverMap"
          :data="allProvData"
        />
      </div>
      <div class="right-map-content">
        <span class="right-map-title">应用覆盖设备感染分布</span>
        <span></span>
        <div class="right-map-tab">
          <a-checkbox-group
            v-model:value="checkedType"
            :options="typeOptions"
            @change="changeCheck"
          />
        </div>
      </div>
      <div class="right-calendar-picker">
        <DatePicker @dataPickerTime="selectTime" />
        <!-- <a-date-picker @change="selectTime" placeholder="选择日期" /> -->
      </div>
      <div class="right-line-chart">
        <Card-border></Card-border>
        <div class="right-line-chart-info">
          <span class="icon">
            <img :src="getImgPath('application/iphone.png')" />
          </span>
          <span class="title">设备7日感染趋势</span>
          <div class="chart">
            <LineChart :data="trendData" :chartIndex="0" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, ref, toRefs, onBeforeUnmount } from 'vue';
import dayjs from 'dayjs';
import { getList, getAll, getProv, getTrend, getSearch } from '@/api/application.js';
import { Checkbox, InputSearch, Select, Table, Pagination } from 'ant-design-vue';
import Card from '@/components/common/card.vue';
import MapChart from '@/components/common/map.vue';
import CardBorder from '@/components/common/card-border.vue';
import CardBorderOrange from '@/components/common/card-border-orange.vue';
import CardNumber from '@/components/common/card-num.vue';
// import DatePicker from '@/components/common/date-picker.vue';
import DatePicker from '@/components/common/date-picker-one-day.vue';
import LineChart from '@/components/common/line.vue';
import router from '@/router/index';
import { getImgPath } from '@/utils/addColor.js';

const typeOptions = [
  { label: '危险', value: 1 },
  { label: '谨慎', value: 2 }
];

let selectMonday = dayjs().startOf('week').add(1, 'day').format("YYYY-MM-DD");

const infectColor = { name: '#FCE8CC', value: '#FFB58F' };
const deviceColor = { name: '#EAEDFF', value: '#EAEDFF' };

const columns = [
  {
    title: '排序',
    customRender: ({ index }) => {
      return index + 4;
    },
    dataIndex: 'sort',
    key: 'sort'
  },
  {
    title: '应用名称',
    dataIndex: 'appname',
    key: 'appname'
  },
  {
    title: '感染设备',
    dataIndex: 'inf',
    key: 'inf'
  },
  {
    title: '覆盖设备',
    dataIndex: 'cov',
    key: 'cov'
  },
  {
    title: '感染率',
    customRender: ({ text }) => {
      return (Math.round(text * 10000)) / 100 + '%';
    },
    dataIndex: 'rate',
    key: 'rate'
  }
];

export default {
  name: 'Application',
  components: {
    Card,
    MapChart,
    CardNumber,
    CardBorder,
    CardBorderOrange,
    [Checkbox.Group.name]: Checkbox.Group,
    [InputSearch.name]: InputSearch,
    [Select.name]: Select,
    [Table.name]: Table,
    [Pagination.name]: Pagination,
    DatePicker,
    LineChart
  },
  setup () {
    const infectDev = ref({});
    const deviceAll = ref({});
    const rankCardData = ref([]);
    const dataList = ref([]);
    const allAppData = ref([]);
    const safeData = ref(0);
    const state = reactive({
      checkedType: [1, 2]
    });
    const trendData = ref([]);
    const mouseoverMap = (value) => {
      getAppProv(value);
    };
    const allProvData = ref([]);
    const searchValue = ref();
    const searchData = ref([]);
    const signChecked = ref(0);
    const devicePage = reactive({
      current: 1,
      pageSize: 5,
      total: 0
    });
    // 资源路径
    const assetsConfig = ref({});
    // 列表页面跳转
    const onChange = (val) => {
      devicePage.current = val;
      getAppRank();
    };


    // 日期选择
    const selectTime = (val) => {
      // selectMonday = dayjs(val).startOf('week').add(8, 'day').format("YYYY-MM-DD");
      selectMonday = val;
      getAppRank();
      getAllApp();
      getAppTrend();
    };

    // 设备风险等级选择
    const changeCheck = (arr) => {
      // 风险等级：0全部，1危险，2谨慎，-1不选风险等级
      if (arr.length > 0) {
        if (arr.length === 2) {
          signChecked.value = 0;
        } else {
          signChecked.value = arr.includes(1) ? 1 : 2;
        }
      } else {
        signChecked.value = -1;
      }
      getAppRank();
      getAllApp();
      getAppTrend();
    };

    // 获取应用排行
    const getAppRank = async () => {
      const params = {
        sign: signChecked.value,
        page: devicePage.current,
        size: devicePage.pageSize,
        dt: selectMonday
      };
      const res = await getList(params);
      rankCardData.value = res.data.slice(0, 3);
      dataList.value = res.data.slice(3, 5);
      devicePage.total = res.count;
      setTimeout(() => {
        setTableHeight();
      });
    };
    // 应用覆盖设备感染分布
    const getAllApp = async () => {
      const params = {
        sign: signChecked.value,
        dt: selectMonday
      };
      const { data, total } = await getAll(params);
      infectDev.value = { name: '感染设备数', value: total.inf, index: '24' };
      deviceAll.value = { name: '设备总数', value: total.cov, index: '34' };
      safeData.value = total.score;
      allAppData.value = data.map(item => {
        return {
          name: item.name,
          value: item.inf
        };
      });
    };
    // 设备7日感染趋势
    const getAppTrend = async () => {
      const res = {
        sign: signChecked.value,
        dt: selectMonday
      };
      const { data } = await getTrend(res);
      trendData.value = data.map(item => {
        return {
          name: item.day,
          value: item.inf
        };
      });
    };
    // 地图中每个省份app的感染数据
    const getAppProv = async (provName) => {
      const { data } = await getProv({
        sign: signChecked.value,
        dt: selectMonday,
        apps: '',
        prov: provName,
      });
      allProvData.value = data.all;
    };
    // 获取感染应用
    const getAppSearch = async (name) => {
      const { data } = await getSearch({ app: name });
      searchData.value = data.map((item, index) => {
        return {
          name: index,
          value: item.appname
        };
      });
    };
    // 模糊查询
    const handleSearch = (val) => {
      getAppSearch(val);
    };
    // 点击搜索跳转详情
    const handleChange = (val) => {
      searchValue.value = val;
      router.push(`/detail?name=${val}`);
    };

    const setTableHeight = () => {
      if ($(".ant-table-striped").height()) {
        const h = ($(".ant-table-striped").height() - 43) / 2 - 24;
        $(".ant-table-striped .ant-table-tbody td").height(h);
      }
    };

    onBeforeUnmount(() => {
      window.removeEventListener(
        "resize", setTableHeight
      );
    });

    return {
      rankCardData,
      dataList,
      infectDev,
      infectColor,
      deviceAll,
      deviceColor,
      typeOptions,
      ...toRefs(state),
      columns,
      allAppData,
      safeData,
      trendData,
      mouseoverMap,
      allProvData,
      searchValue,
      searchData,
      handleSearch,
      handleChange,
      changeCheck,
      selectTime,
      ...toRefs(devicePage),
      onChange,
      assetsConfig,
      getImgPath
    };
  },
}
</script>

<style lang="less">
@import url('./index.less');
</style>
