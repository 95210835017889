<template>
  <div class="user-wrap">
    <div class="user-wrap-left">
      <div class="left-app">
        <Card-border></Card-border>
        <span class="left-app-icon">
          <!-- <img src="@/assets/images/weather.png" /> -->
          <img :src="dataAppList.iconUrl" />
        </span>
        <div class="left-app-box">
          <div class="left-app-main">
            <span class="left-app-name">{{ dataAppList.appname }}</span>
            <span class="left-app-btn" @click="changeApp">切换应用</span>
          </div>
          <div class="left-app-main">
            <span class="left-app-pk">资产主体：</span>
            <span class="left-app-text">{{ dataAppList.company }}</span>
          </div>
          <div class="left-app-select">
            <SelectOption
              :appOptions="appLists"
              :show="selectOptionShow"
              @handelSelectApp="handelSelectApp"
            />
          </div>
        </div>
      </div>
      <div class="left-card">
        <div class="left-card-box">
          <Card-border></Card-border>
          <span class="left-card-box-text">累计用户</span>
          <div class="left-card-box-main">
            <span class="left-card-box-num">{{ dataNumber.totalnu }}</span>
          </div>
        </div>
        <div class="left-card-box">
          <Card-border></Card-border>
          <span class="left-card-box-text">过去7天活跃用户数</span>
          <div class="left-card-box-main">
            <span class="left-card-box-num">{{ dataNumber.nearly7au }}</span>
            <span class="left-card-box-line">|</span>
            <span class="left-card-box-icon">
              <img :src="dataNumber.nearly7auloop > 0 ? RiseIcon : DropIcon" />
            </span>
            <span
              class="left-card-box-percent"
              :class="dataNumber.nearly7auloop > 0 ? 'rise' : 'drop'"
              >{{ percent(dataNumber.nearly7auloop) }}%</span
            >
          </div>
        </div>
      </div>
      <div class="left-card">
        <div class="left-card-box">
          <Card-border></Card-border>
          <span class="left-card-box-text">昨日新增用户数</span>
          <div class="left-card-box-main">
            <span class="left-card-box-num">{{ dataNumber.yesterdaynu }}</span>
            <span class="left-card-box-line">|</span>
            <span class="left-card-box-icon">
              <img
                :src="dataNumber.yesterdaynuloop > 0 ? RiseIcon : DropIcon"
              />
            </span>
            <span
              class="left-card-box-percent"
              :class="dataNumber.yesterdaynuloop > 0 ? 'rise' : 'drop'"
              >{{ percent(dataNumber.yesterdaynuloop) }}%</span
            >
          </div>
        </div>
        <div class="left-card-box">
          <Card-border></Card-border>
          <span class="left-card-box-text">昨日活跃用户数</span>
          <div class="left-card-box-main">
            <span class="left-card-box-num">{{ dataNumber.yesterdayau }}</span>
            <span class="left-card-box-line">|</span>
            <span class="left-card-box-icon">
              <img
                :src="dataNumber.yesterdayauloop > 0 ? RiseIcon : DropIcon"
              />
            </span>
            <span
              class="left-card-box-percent"
              :class="dataNumber.yesterdayauloop > 0 ? 'rise' : 'drop'"
              >{{ percent(dataNumber.yesterdayauloop) }}%</span
            >
          </div>
        </div>
      </div>
      <div class="left-user">
        <Card-border></Card-border>
        <div class="left-user-nav">
          <span class="user-wrap-title">新用户留存（近7日）</span>
          <div class="left-user-picker">
            <DatePicker @dataPickerTime="selectSevenTime" />
          </div>
        </div>
        <div class="left-user-chart">
          <FunnelChart :funnelData="newUserDataList" />
        </div>
      </div>
    </div>
    <div class="user-wrap-right">
      <div class="right-calendar">
        <DatePicker @dataPickerTime="selectTime" timeType="lastWeek" />
      </div>
      <div class="right-retention">
        <Card-border></Card-border>
        <div class="right-retention-top">
          <span class="user-wrap-title">留存分析</span>
          <div class="right-retention-tabs">
            <a-radio-group v-model:value="userRadio">
              <a-radio-button value="1">次日留存</a-radio-button>
              <a-radio-button value="2">7日留存</a-radio-button>
            </a-radio-group>
          </div>
        </div>
        <div class="right-retention-charts">
          <LinesChart
            v-if="userRadio == 1"
            :data="nextDataList"
            :chartIndex="0"
          />
          <LinesChart
            v-if="userRadio == 2"
            :data="sevenDataList"
            :chartIndex="1"
          />
        </div>
      </div>
      <div class="right-trend">
        <Card-border></Card-border>
        <span class="user-wrap-title">用户数量趋势</span>
        <div class="right-trend-chart">
          <LineDottedChart :lineDataLists="userTrendData" :chartIndex="0" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, reactive } from 'vue';
import dayjs from 'dayjs';
import { Radio } from 'ant-design-vue';
import RiseIcon from '@/assets/images/dataAnalysis/rise.png';
import DropIcon from '@/assets/images/dataAnalysis/drop.png';
import { getApplist } from '@/api/crashLog.js';
import { getAllNumber, newUserRemainList, userRateList, userTrendList } from '@/api/dataAnalysis.js';
import CardBorder from '@/components/common/card-border.vue';
// import DatePicker from '@/components/common/date-picker.vue';
import DatePicker from '@/components/common/date-picker-one-day.vue';
import FunnelChart from './common/funnel-chart.vue';
import LineDottedChart from './common/line-chart.vue';
import LinesChart from './common/lines.vue';
import SelectOption from '@/components/common/select-option.vue';
const userRadio = ref('1');
export default {
  components: {
    CardBorder,
    DatePicker,
    FunnelChart,
    LineDottedChart,
    LinesChart,
    SelectOption,
    [Radio.Group.name]: Radio.Group,
    [Radio.Button.name]: Radio.Button
  },
  setup () {
    let nowTime = dayjs().subtract(1, 'day').format("YYYY-MM-DD"); // 昨天
    let lastTime = dayjs().subtract(6, 'day').format("YYYY-MM-DD"); //  前7天
    const dataAppList = ref([]);
    const nextDataList = ref([]);
    const dataNumber = ref([]);
    const newUserDataList = ref([]);
    const sevenDataList = ref([]);
    const userTrendData = ref([]);
    const appParams = reactive({
      pn: '',
      sign: ''
    });
    const timePicker = reactive({
      starttime: lastTime,
      endtime: nowTime
    });
    const appLists = ref([]);
    const selectOptionShow = ref(false);

    // 转化百分比
    const percent = (value) => {
      return Math.round(value * 10000) / 100;
    };

    // 日历选择
    const selectTime = (val) => {
      nowTime = dayjs(val).format("YYYY-MM-DD");
      lastTime = dayjs(val).subtract(6, 'day').format("YYYY-MM-DD");
      timePicker.starttime = lastTime;
      timePicker.endtime = nowTime;
      dataSevenList();
      dataNextList();
      dataUserTrendList();
    };

    // 新用户留存（近7日）日历选择
    const selectSevenTime = (val) => {
      nowTime = val;
      lastTime = dayjs(val).subtract(6, 'day').format("YYYY-MM-DD");
      timePicker.starttime = lastTime;
      timePicker.endtime = nowTime;
      dataNewUserList();
    };

    // 获取监测app列表
    const getDataApplist = async () => {
      const { data } = await getApplist();
      appLists.value = data;
      dataAppList.value = data[2];
      appParams.pn = data[2].pn;
      appParams.sign = data[2].sign;
      getDataAllNumber();
      dataNewUserList();
      dataSevenList();
      dataNextList();
      dataUserTrendList();
    };
    // 切换应用
    const changeApp = () => {
      selectOptionShow.value = !selectOptionShow.value;
    };
    // 应用列表切换
    const handelSelectApp = async (name, show) => {
      const selectAppList = appLists.value.filter(item => item.appname === name);
      selectOptionShow.value = show;
      dataAppList.value = selectAppList[0];
      appParams.pn = selectAppList[0].pn;
      appParams.sign = selectAppList[0].sign;
      getDataAllNumber();
      dataNewUserList();
      dataSevenList();
      dataNextList();
      dataUserTrendList();
    };
    // 获取数据概览
    const getDataAllNumber = async () => {
      const params = { ...appParams, ...{ m: 'summaryTopNumbers' } };
      const { data } = await getAllNumber(params);
      dataNumber.value = data;
    };
    // 获取新用户留存（近7日）
    const dataNewUserList = async () => {
      const { data } = await newUserRemainList(appParams, timePicker);
      newUserDataList.value = data.map(item => {
        return {
          name: dayjs(item.timelong).format('MM-DD'),
          value: item.total
        };
      });
    };
    // 获取7日留存率
    const dataSevenList = async () => {
      const urlParam = { ...appParams, ...{ m: 'remainanalyseDayD07' } };
      const params = { ...timePicker, ...{ lineMethod: 'remainanalyseDayD07' } };
      const { data } = await userRateList(urlParam, params);
      sevenDataList.value = data.remainanalyseDayD07.map(item => {
        return {
          name: dayjs(item.timelong).format('YYYY-MM-DD'),
          value: item.total
        };
      });
    };
    // 获取次日留存率
    const dataNextList = async () => {
      const urlParam = { ...appParams, ...{ m: 'startUpPerDaily' } };
      const params = { ...timePicker, ...{ lineMethod: 'startUpPerDaily' } };
      const { data } = await userRateList(urlParam, params);
      nextDataList.value = data.startUpPerDaily.map(item => {
        return {
          name: dayjs(item.timelong).format('YYYY-MM-DD'),
          value: item.total
        };
      });
    };
    // 获取用户数量趋势
    const dataUserTrendList = async () => {
      const { data } = await userTrendList(appParams, timePicker);
      userTrendData.value = data;
    };

    getDataApplist();

    return {
      userRadio,
      percent,
      selectTime,
      dataAppList,
      dataNumber,
      nextDataList,
      RiseIcon,
      DropIcon,
      sevenDataList,
      userTrendData,
      newUserDataList,
      appLists,
      changeApp,
      handelSelectApp,
      selectOptionShow,
      selectSevenTime
    };
  }
}
</script>

<style scoped lang="less">
@import url(./user.less);
</style>