<template>
  <div class="crashLog-wrap">
    <div class="crashLog-wrap-top">
      <div class="top-card">
        <Card-border></Card-border>
        <span class="top-card-icon">
          <img src="@/assets/images/weather.png" />
        </span>
        <div class="top-card-box">
          <div class="top-card-main">
            <span class="top-card-app">{{ logAppList.appname }}</span>
            <span class="top-card-btn" @click="changeApp">切换应用</span>
          </div>
          <div class="top-card-main">
            <span class="top-card-name">资产主体：</span>
            <span class="top-card-text">{{ logAppList.company }}</span>
          </div>
          <div class="top-card-select">
            <SelectOption
              :appOptions="appLists"
              :show="selectOptionShow"
              @handelSelectApp="handelSelectApp"
            />
          </div>
        </div>
      </div>
      <div class="top-card top-card-space">
        <Card-border></Card-border>
        <div class="top-card-details" @click="onClickDetails">
          <span>
            <img :src="getImgPath('crashLog/line.png')" />
          </span>
          <span>查看实时崩溃详情</span>
        </div>
        <!-- <div class="top-card-chart">
          <GaugeChart :rateData="logData.percentDocCount" :rateIndex="0" rateName="崩溃率" />
        </div> -->
        <div class="top-card-content">
          <span>今日启动次数</span>
          <!-- <span>{{logData.useNum}}</span> -->
          <CountUp
            :startVal="logData.startUseNum"
            :endVal="logData.useNum"
            :index="66"
          ></CountUp>
        </div>
        <div class="top-card-content">
          <span>今日崩溃次数</span>
          <!-- <span>{{logData.docCount}}</span> -->
          <CountUp
            :startVal="logData.startDocCount"
            :endVal="logData.docCount"
            :index="77"
          ></CountUp>
        </div>
      </div>
      <div class="top-card">
        <Card-border></Card-border>
        <div class="top-card-chart">
          <GaugeChart
            :rateData="logData.percent"
            :rateIndex="1"
            rateName="用户占比"
          />
        </div>
        <div class="top-card-content">
          <span>今日影响用户</span>
          <span>{{ logData.personNum }}</span>
        </div>
        <div class="top-card-content">
          <span>全部用户数</span>
          <span>{{ logData.totalUser }}</span>
        </div>
      </div>
    </div>
    <div v-if="detailsShow" class="crashLog-wrap-hour">
      <Card-border></Card-border>
      <div class="hour-line">
        <LinesChart :data="hourDataList" :chartIndex="0" />
      </div>
    </div>
    <div class="crashLog-wrap-tabs">
      <div class="crashLog-wrap-tabs-calendar" v-show="activeKey == '1'">
        <DatePicker @dataPickerTime="selectTime" />
      </div>
      <a-tabs v-model:value="activeKey" @change="tabsChange">
        <a-tab-pane key="1" tab="崩溃趋势">
          <TrendPane v-if="activeKey == '1'" :appData="appData" />
        </a-tab-pane>
        <a-tab-pane key="2" tab="崩溃日志" force-render>
          <LogPane v-if="activeKey == '2'" :appData="appData" />
        </a-tab-pane>
      </a-tabs>
    </div>
  </div>
</template>

<script>
import { ref, reactive } from 'vue';
import dayjs from 'dayjs';
import { getApplist, getRealTime, getHourCount } from '@/api/crashLog.js';
import { Tabs } from 'ant-design-vue';
import CardBorder from '@/components/common/card-border.vue';
import GaugeChart from '@/components/common/gauge.vue';
import TrendPane from './trend.vue';
import LogPane from './log.vue';
import SelectOption from '@/components/common/select-option.vue';
import LinesChart from '@/components/common/line.vue';
// import DatePicker from '@/components/common/date-picker.vue';
import DatePicker from '@/components/common/date-picker-one-day.vue';
import CountUp from "@/components/common/countup.vue";
import { getImgPath } from '@/utils/addColor.js';
export default {
  components: {
    CardBorder,
    GaugeChart,
    TrendPane,
    LogPane,
    SelectOption,
    LinesChart,
    DatePicker,
    CountUp,
    [Tabs.name]: Tabs,
    [Tabs.TabPane.name]: Tabs.TabPane
  },
  setup () {
    const activeKey = ref('1');
    const logAppList = ref([]);
    const logData = ref({});
    const appData = reactive({
      appKey: '',
      pn: '',
      startDate: dayjs().subtract(6, 'day').format("YYYY-MM-DD"), //  前7天
      endDate: dayjs().format("YYYY-MM-DD") // 今天
    });
    const appLists = ref([]);
    const selectOptionShow = ref(false);
    const detailsShow = ref(false);
    const hourDataList = ref([]);

    // 获取崩溃日志监测app列表
    const getLogApplist = async () => {
      const { data } = await getApplist();
      appLists.value = data;
      logAppList.value = data[2];
      appData.appKey = logAppList.value.ak;
      appData.pn = logAppList.value.pn;
      getLogRealTime(data[3].ak, data[3].pn);
      getLogHourCount();
    };

    // 切换应用
    const changeApp = () => {
      selectOptionShow.value = !selectOptionShow.value;
    };

    // 应用列表切换
    const handelSelectApp = async (name, show) => {
      const selectAppList = appLists.value.filter(item => item.appname === name);
      selectOptionShow.value = show;
      logAppList.value = selectAppList[0];
      appData.appKey = logAppList.value.ak;
      appData.pn = logAppList.value.pn;
      getLogRealTime(appData.appKey, appData.pn);
      getLogHourCount();
    };

    // 获取今日崩溃日志数据概览
    const getLogRealTime = async (appKey, pn) => {
      const { data } = await getRealTime({
        appKey: appKey,
        pn: pn
      });
      logData.value = data;
      logData.value.startUseNum = '',
        logData.value.startDocCount = '';
    };

    // 查看详情
    const onClickDetails = () => {
      detailsShow.value = !detailsShow.value;
    };

    // 获取一天24小时崩溃趋势数据
    const getLogHourCount = async () => {
      const { data } = await getHourCount({
        appKey: appData.appKey,
        date: dayjs().format('YYYY-MM-DD')
      });
      hourDataList.value = data.map(item => {
        return {
          name: dayjs(item.dateTime).format('HH:mm'),
          value: item.docCount
        };
      });
    };

    // 日历选择
    const selectTime = (val) => {
      appData.startDate = val;
      appData.endDate = val;
    };

    const tabsChange = (val) => {
      activeKey.value = val;
    };

    getLogApplist();


    return { getImgPath, activeKey, logAppList, logData, appData, changeApp, handelSelectApp, appLists, selectOptionShow, onClickDetails, detailsShow, hourDataList, selectTime, tabsChange, CountUp };
  }
}
</script>

<style lang="less">
@import url(./index.less);
</style>
