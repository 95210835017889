<template>
  <div class="bar-box" :id="'bar-box' + chartIndex"></div>
</template>

<script>
import * as echarts from 'echarts';
import { toRefs, computed, onMounted, watch, onBeforeUnmount } from 'vue';
import { getThemeColor } from '@/utils/addColor.js';
export default {
  props: {
    barData: {
      type: Array,
      default: () => { []; }
    },
    chartIndex: Number
  },
  setup (props) {
    const { barData } = toRefs(props);
    let myChart = {};
    const themStyles = getThemeColor();
    const barOptions = computed(() => {
      return {
        tooltip: {
          trigger: 'axis',
          backgroundColor: '#11151ee5',
          padding: 16,
          textStyle: {
            color: '#eaedff'
          },
          extraCssText: 'border: 1px dotted #eaedff99'
        },
        grid: {
          left: '0',
          right: '0',
          bottom: '3%',
          top: '18%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            axisLine: {
              lineStyle: {
                color: 'rgba(255, 255, 255, 0.3)',
              }
            },
            splitLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            splitArea: {
              show: false
            },
            axisLabel: {
              interval: 0,
              color: '#9FA2B1',
              fontSize: 12,
            },
            data: barData.value.map(item => item.name)
          }
        ],
        yAxis: [
          {
            name: '数量/万',
            type: 'value',
            splitLine: {
              lineStyle: {
                color: 'rgba(234, 237, 255, 0.1)'
              }
            },
            axisLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              interval: 0,
              color: '#9FA2B1',
              fontSize: 12
            },
            splitArea: {
              show: false
            }
          }
        ],
        series: [
          {
            name: '',
            type: 'bar',
            barMaxWidth: 16,
            roundCap: false,
            itemStyle: {
              borderRadius: [30, 30, 0, 0],
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 1,
                  color: `rgba(${themStyles['--crashLogBar']['color1']}, 0)`
                },
                {
                  offset: 0.5,
                  color: `rgba(${themStyles['--crashLogBar']['color2']}, 0.88)`
                  // color: 'rgba(255, 162, 102, 0.88)'
                },
                {
                  offset: 0,
                  color: `rgba(${themStyles['--crashLogBar']['color3']}, 1)`
                  // color: 'rgba(255, 190, 102, 1)'
                }
              ])
            },
            data: barData.value.map(item => item.value)
          }
        ]
      };
    });

    const barCharts = () => {
      let barBox = echarts.getInstanceByDom(document.getElementById('bar-box' + props.chartIndex));
      if (barBox == null) {
        barBox = echarts.init(document.getElementById('bar-box' + props.chartIndex));
      }
      barBox.setOption(barOptions.value);
      myChart = barBox;
    };

    const resizeChart = () => {
      myChart.resize();
    };

    onMounted(() => {
      barCharts();
      window.addEventListener(
        "resize", resizeChart
      );
    });

    onBeforeUnmount(() => {
      window.removeEventListener(
        "resize", resizeChart
      );
    });

    // 监听传值
    watch(barData, () => {
      barCharts();
    });
  }
}
</script>

<style scoped lang="less">
.bar-box {
  width: 100%;
  height: 100%;
}
</style>