<template>
  <div class="bar-charts" id="bar-charts"></div>
</template>

<script>
import * as echarts from 'echarts';
import { toRefs, computed, onMounted, watch, onBeforeUnmount } from 'vue';
import { getThemeColor } from '@/utils/addColor.js';
export default {
  props: {
    barChartsData: {
      type: Array,
      default: () => { []; }
    }
  },
  setup (props) {
    const { barChartsData } = toRefs(props);
    let myChart = {};
    const themStyles = getThemeColor();
    // 根据主题渲染图片
    const circleImg = require(`@/assets/${localStorage.theme || 'orange'}/environment/circle.png`);
    const setOptions = computed(() => {
      // let maxArr = (new Array(barChartsData.value.length)).fill(100);
      const getSymbolData = (prams) => {
        let arr = [];
        prams.map(item => {
          arr.push({
            value: (Math.round(item.ratio * 10000)) / 100,
            symbolPosition: 'end'
          });
        });
        return arr;
      };
      return {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'line',
            lineStyle: {
              opacity: 0
            }
          },
          backgroundColor: '#11151ee5',
          padding: 16,
          textStyle: {
            color: '#eaedff'
          },
          extraCssText: 'border: 1px dotted #eaedff99',
          formatter: '设备分辨率<br />{b}：{c}%'
        },
        grid: {
          left: '0',
          right: '0',
          bottom: '0',
          top: '3%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            gridIndex: 0,
            data: barChartsData.value.map(item => item.name),
            axisTick: {
              show: false
            },
            axisLine: {
              lineStyle: {
                color: 'rgba(255, 255, 255, 0.2)'
              }
            },
            axisLabel: {
              show: true,
              color: '#9FA2B1',
              fontSize: 12,
              rotate: 45,
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            gridIndex: 0,
            splitLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            axisLine: {
              lineStyle: {
                color: '#0c3b71'
              }
            },
            axisLabel: {
              color: '#9FA2B1',
              formatter: '{value} %'
            }
          },
          {
            type: 'value',
            gridIndex: 0,
            splitLine: {
              show: true,
              lineStyle: {
                color: 'rgba(255, 255, 255, 0.05)'
              }
            },
            axisLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              show: false
            },
            splitArea: {
              show: false
            }
          }
        ],
        series: [
          {
            name: '',
            type: 'pictorialBar',
            symbol: 'image://' + circleImg,
            symbolSize: [25, 25],
            symbolOffset: [0, -25],
            z: 12,
            itemStyle: {
              color: '#14b1eb'
            },
            data: getSymbolData(barChartsData.value),
          },
          {
            name: '分辨率',
            type: 'bar',
            showBackground: true,
            backgroundStyle: {
              color: '#EAEDFF',
              opacity: 0.1
            },
            barWidth: 12,
            xAxisIndex: 0,
            yAxisIndex: 0,
            itemStyle: {
              borderRadius: [30, 30, 0, 0],
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: themStyles["--environmentBarCharts"]["offset-0"]
                },
                {
                  offset: 1,
                  color: themStyles["--environmentBarCharts"]["offset-1"]
                }
              ])
            },
            label: {
              position: 'top',
              offset: [0, -13],
              color: themStyles["--environmentBarCharts"]["label"],
              fontSize: 12,
              formatter: (params) => {
                return params.value + '%';
              }
            },
            data: barChartsData.value.map(item => {
              return {
                name: item.name,
                value: Math.round(item.ratio * 10000) / 100
              };
            }),
            zlevel: 2
          }
          // {
          //   name: '背景',
          //   type: 'bar',
          //   barWidth: 24,
          //   xAxisIndex: 0,
          //   yAxisIndex: 1,
          //   barGap: '-145%',
          //   data: maxArr,
          //   itemStyle: {
          //     color: '#EAEDFF',
          //     opacity: 0.1
          //   },
          //   zlevel: 1
          // }
        ]
      };
    });

    const barCharts = () => {
      let barCharts = echarts.getInstanceByDom(document.getElementById('bar-charts'));
      if (barCharts == null) {
        barCharts = echarts.init(document.getElementById('bar-charts'));
      }
      setOptions.value && barCharts.setOption(setOptions.value);
      myChart = barCharts;
    };

    const resizeChart = () => {
      myChart.resize();
    };

    onMounted(() => {
      barCharts();
      window.addEventListener(
        "resize", resizeChart
      );
    });

    onBeforeUnmount(() => {
      window.removeEventListener(
        "resize", resizeChart
      );
    });

    // 监听传值，刷新图表
    watch(barChartsData, () => {
      barCharts();
    });
  }
}
</script>

<style scoped lang="less">
.bar-charts {
  width: 100%;
  height: 100%;
}
</style>