<template>
  <a-table
    class="ant-table-risk"
    size="middle"
    :columns="columns"
    :data-source="dataSource"
    :pagination="appTable"
    @change="handlePageChange"
    :loading="tableLoading"
    :rowClassName="
      (record, index) => (index % 2 === 1 ? 'table-striped' : null)
    "
  >
    <template #bodyCell="{ column, text }">
      <template v-if="column.dataIndex === 'wifi_tags'">
        <div
          :class="text === '公共WLAN' ? 'tags_green' : 'tags_yellow'"
          v-if="text"
        >
          {{ text }}
        </div>
        <span v-else>-</span>
      </template>
    </template>
  </a-table>
</template>

<script>
import { ref, watch, reactive } from 'vue';
import { getDevicedInstall, getDevicedWifiList, getDevicedIpHistory } from '@/api/riskEquipment.js';
import { columnsArr } from './columns';
export default {
  props: {
    tabVal: String,
    oaid: String,
    filter: Object,
    tableType: String,
  },
  setup (props) {
    const appTable = reactive({
      current: 1,
      total: 0,
      pageSize: 10,
      showSizeChanger: false,
      showTotal: (total) => `共 ${total} 条数据`
    });
    const dataSource = ref([]);
    const columns = ref([]);
    const tableLoading = ref(false);
    const searcFilter = ref({});
    const getTableData = async () => {
      tableLoading.value = true;
      let api = '';
      switch (props.tabVal) {
        case '1':
          api = getDevicedInstall;
          break;
        case '2':
          api = getDevicedWifiList;
          break;
        case '3':
          api = getDevicedIpHistory;
          break;
        default:
      }
      let params = {};
      params.oaid = props.oaid;
      params.page = appTable.current;
      params.size = appTable.pageSize;
      if (props.tabVal === '2' || props.tabVal === '3') {
        params.type = props.tableType;
      }
      params = { ...params, ...searcFilter.value };
      const res = await api(params);
      tableLoading.value = false;
      if (res.error == 200) {
        dataSource.value = res.data;
      }
    };
    const getColumns = () => {
      switch (props.tabVal) {
        case '1':
          columns.value = columnsArr.app;
          break;
        case '2':
          columns.value = columnsArr.wifi[props.tableType];
          break;
        case '3':
          columns.value = columnsArr.ip[props.tableType];
          break;
        default:
      }
    };
    // 表格分页
    const handlePageChange = (val) => {
      appTable.current = val.current;
      getTableData();
    };
    getColumns();
    getTableData();
    // 监听传值，刷新图表
    watch(() => props.tabVal, val => {
      appTable.current = 1;
      getTableData();
      getColumns();
    });
    watch(() => props.filter, val => {
      appTable.current = 1;
      searcFilter.value = val;
      getTableData();
    });
    watch(() => props.tableType, val => {
      appTable.current = 1;
      getColumns();
      getTableData();
    });


    return {
      searcFilter,
      dataSource,
      columns,
      appTable,
      tableLoading,
      handlePageChange
    };
  }
}
</script>
<style lang="less" >
@import url(./index.less);
@import url(../../../../style/a-table.less);
</style>