<template>
  <div class="environment-wrap">
    <div class="environment-wrap-top">
      <div class="top-card">
        <Card-border></Card-border>
        <Card-number
          :data="deviceAll"
          :style="'background: #eaedff1a'"
          :color="deviceColor"
        ></Card-number>
      </div>
      <div class="top-list">
        <!-- <div class="top-list-box" v-for="(item, index) in devRank" :key="index">
          <span>{{item.name}}</span>
          <span>|</span>
          <span>{{item.value}}<i>&nbsp;/&nbsp;{{item.rate}}%</i></span>
        </div> -->
        <div class="top-list-box">
          <span>风险设备-Xposed</span>
          <span>|</span>
          <span>
            <span> {{ devList.xposed }}</span>
            <i>&nbsp;/&nbsp;{{ percent(devList.x_ratio) }}%</i>
          </span>
        </div>
        <div class="top-list-box">
          <span>风险设备-Root</span>
          <span>|</span>
          <span
            ><CountUp
              :startVal="devList.startRoot"
              :endVal="devList.root"
              index="41"
            ></CountUp
            ><i>&nbsp;/&nbsp;{{ percent(devList.r_ratio) }}%</i></span
          >
        </div>
        <div class="top-list-box">
          <span>风险设备-双开</span>
          <span>|</span>
          <span>
            <span>{{ devList.ps }}</span>
            <i>&nbsp;/&nbsp;{{ percent(devList.ps_ratio) }}%</i>
          </span>
        </div>
        <div class="top-list-box">
          <span>风险设备-模拟器</span>
          <span>|</span>
          <span
            ><CountUp
              :startVal="devList.startSdr"
              :endVal="devList.sdr"
              index="42"
            ></CountUp
            ><i>&nbsp;/&nbsp;{{ percent(devList.sdr_ratio) }}%</i></span
          >
        </div>
        <div class="top-list-box">
          <span>风险设备-Hook</span>
          <span>|</span>
          <span>
            <span>{{ devList.hook }}</span>
            <i>&nbsp;/&nbsp;{{ percent(devList.hook_ratio) }}%</i>
          </span>
        </div>
        <div class="top-list-box" @click="linkTo('/virus')">
          <span>风险设备-病毒木马设备</span>
          <span>|</span>
          <span>
            <span>{{ devList.ave }}</span>
            <i>&nbsp;/&nbsp;{{ percent(devList.ave_ratio) }}%</i>
          </span>
        </div>
      </div>
      <div class="top-select">
        <div class="top-select-calendar">
          <DatePicker @dataPickerTime="selectTime" />
        </div>
        <div class="top-select-tab">
          <a-radio-group v-model:value="modeRadio">
            <a-radio-button value="1">指标看版01</a-radio-button>
            <a-radio-button value="2">指标看版02</a-radio-button>
          </a-radio-group>
        </div>
      </div>
    </div>
    <div class="environment-wrap-board">
      <BoardIndex
        v-if="modeRadio === '1'"
        :timePicker="timePicker"
      ></BoardIndex>
      <BoardsIndex
        v-if="modeRadio === '2'"
        :timePicker="timePicker"
      ></BoardsIndex>
    </div>
  </div>
</template>

<script>
import { ref, reactive } from 'vue';
import dayjs from 'dayjs';
import { getSummary } from '@/api/environment.js';
import CardBorder from '@/components/common/card-border.vue';
import CardNumber from '@/components/common/card-num.vue';
import CountUp from '@/components/common/countup.vue';
// import DatePicker from '@/components/common/date-picker.vue';
import DatePicker from '@/components/common/date-picker-one-day.vue';
import { Radio } from 'ant-design-vue';
import BoardIndex from './board.vue';
import BoardsIndex from './boards.vue';
import router from '@/router/index';

const deviceColor = { name: '#EAEDFF', value: '#EAEDFF' };

const modeRadio = ref('1');

export default {
  components: {
    CardBorder,
    CardNumber,
    CountUp,
    // Calendar,
    DatePicker,
    BoardIndex,
    BoardsIndex,
    [Radio.Group.name]: Radio.Group,
    [Radio.Button.name]: Radio.Button,
  },
  setup () {
    let lastMonday = dayjs().subtract(6, 'day').format("YYYY-MM-DD");
    let lastSunday =dayjs().subtract(1, 'day').format("YYYY-MM-DD");
    let monday = dayjs().subtract(1, 'day').format("YYYY-MM-DD");
    const devList = ref({});
    const deviceAll = ref({});
    const timePicker = reactive({
      st: lastMonday,
      et: lastSunday,
      dt: monday
    });

    // 日历选择
    const selectTime = (val) => {
      lastMonday =  dayjs(val).subtract(6, 'day').format("YYYY-MM-DD");
      lastSunday = val;
      monday = val;
      timePicker.st =  dayjs(val).subtract(6, 'day').format("YYYY-MM-DD");
      timePicker.et = val;
      timePicker.dt = val;
      getEnvSummary();
    };

    // 转化百分比
    const percent = (value) => {
      return Math.round(value * 10000) / 100;
    };

    // 获取设备概览
    const getEnvSummary = async () => {
      const { data } = await getSummary({
        st: lastMonday,
        et: lastSunday,
        dt: monday
      });
      devList.value = data;
      devList.value.startRoot = '',
        devList.value.startSdr = '',
        deviceAll.value = { name: '设备总数', value: data.total, startValue: '', index: '61' };
    };
    const linkTo = (path) => {
      router.push(path);
    };

    getEnvSummary();

    return { deviceColor, deviceAll, devList, modeRadio, percent, selectTime, timePicker, linkTo };
  }
}
</script>

<style scoped lang="less">
@import url('./index.less');
</style>
