<template>
  <div class="lineCharts" :id="'lineCharts' + chartIndex"></div>
</template>

<script>
import * as echarts from 'echarts';
import dayjs from 'dayjs';
import { toRefs, computed, onMounted, watch, onBeforeUnmount } from 'vue';
import { getThemeColor } from '@/utils/addColor.js';
export default {
  props: {
    lineBarData: {
      type: Array,
      default: () => { []; }
    },
    chartIndex: Number,
    seriesTitle: {
      type: String,
      default: () => { "图例"; }
    }
  },
  setup (props) {
    const { lineBarData, seriesTitle } = toRefs(props);
    let myChart = {};
    const themStyles = getThemeColor();
    const THEME = localStorage.theme || 'orange';
    const CHARCOLOR = {
      blue: {
        bar: "rgba(187, 239, 255, 0)",
        line: "#BBEFFF",
      },
      orange: {
        bar: "rgba(217, 163, 108, 0.5)",
        line: "#FCE8CC",
      }
    };
    const lineOptions = computed(() => {
      return {
        tooltip: {
          trigger: 'axis',
          backgroundColor: '#11151ee5',
          padding: 16,
          textStyle: {
            color: '#eaedff'
          },
          extraCssText: 'border: 1px dotted #eaedff99'
        },
        // legend: {
        //   top: '0',
        //   right: '20%',
        //   textStyle: {
        //     fontSize: 12,
        //     color: '#9FA2B1',
        //     opacity: 0.8
        //   },
        //   data: [
        //     {
        //       name: '设备总数',
        //       icon: 'circle'
        //     },
        //     {
        //       name: '攻击设备',
        //       icon: 'line',
        //       lineStyle: {
        //         color: '#FCE8CC'
        //       },
        //       type: 'inherit'
        //     }
        //   ]
        // },
        grid: {
          left: '0',
          right: '0',
          bottom: '0',
          top: '10%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            axisLine: {
              lineStyle: {
                color: 'rgba(255, 255, 255, .5)',
              }
            },
            splitLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            splitArea: {
              show: false
            },
            axisLabel: {
              interval: 0,
              color: '#9FA2B1',
              fontSize: 12,
            },
            data: lineBarData.value.map(item => dayjs(item.time).format('MM/DD'))
          }
        ],
        yAxis: [
          {
            type: 'value',
            splitLine: {
              show: false
            },
            axisLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              interval: 0,
              color: '#9FA2B1',
              fontSize: 12
            },
            splitArea: {
              show: false
            }
          }
        ],
        series: [
          {
            name: seriesTitle.value,
            type: 'line',
            symbolSize: 10,
            symbol: 'circle',
            itemStyle: {
              color: CHARCOLOR[THEME]['line'],
              borderColor: 'rgba(255, 255, 255, 0.2)',
              borderWidth: 3,
              borderType: 'solid',
            },
            lineStyle: {
              color: CHARCOLOR[THEME]['line'],
              width: 1,
            },
            data: lineBarData.value.map(item => item.device_num)
          }
        ]
      };
    });


    const lineCharts = () => {
      let lineCharts = echarts.getInstanceByDom(document.getElementById('lineCharts' + props.chartIndex));
      if (lineCharts == null) {
        lineCharts = echarts.init(document.getElementById('lineCharts' + props.chartIndex));
      }
      lineCharts.setOption(lineOptions.value);
      myChart = lineCharts;
    };

    const resizeChart = () => {
      myChart.resize();
    };

    onMounted(() => {
      lineCharts();
      window.addEventListener(
        "resize", resizeChart
      );
    });

    onBeforeUnmount(() => {
      window.removeEventListener(
        "resize", resizeChart
      );
    });

    // 监听传值，刷新图表
    watch(lineBarData, () => {
      lineCharts();
    });
  }
}
</script>

<style scoped lang="less">
.lineCharts {
  width: 100%;
  height: 100%;
}
</style>
