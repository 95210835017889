// 主题样式
import themes from './theme.json';
export function addColor (val) {
    const theme = localStorage.theme || val || 'blue';
    if (val !== theme) {
        localStorage.setItem('theme', val);
    }
}

export function getThemeForCss () {
    const { blue, orange } = themes;
    const theme = localStorage.theme || 'blue';
    // 如果localStorage没有记录，则本都存入
    if (!localStorage.theme) {
        localStorage.setItem('theme', theme);
    }
    let obj = {};
    if (theme === 'blue') {
        obj = Object.assign(obj, blue);
    } else {
        obj = Object.assign(obj, orange);
    }
    for (const key in obj) {
        document.documentElement.style.setProperty(key, obj[key]);
    }
}


export function getThemeColor () {
    const theme = localStorage.theme || 'blue';
    const { blue, orange } = themes;
    let obj = {};
    if (theme === 'blue') {
        obj = Object.assign(obj, blue);
    } else {
        obj = Object.assign(obj, orange);
    }
    return obj;
}

export function getImgPath (path) {
    const theme = localStorage.theme || 'blue';
    return require(`@/assets/${theme}/${path}`);
}

