<template>
  <a-config-provider :locale="zhCN">
    <div class="wrapper">
      <Nav />
      <div class="wrapper-main">
        <router-view></router-view>
      </div>
      <div class="wrapper-background">
        <video
          src="@/assets/images/background0.webm"
          autoPlay="autoPlay"
          loop="loop"
          muted="muted"
        ></video>
      </div>
    </div>
  </a-config-provider>
</template>

<script>
import { onMounted } from 'vue';
import zhCN from 'ant-design-vue/es/locale/zh_CN';
import Nav from '@/components/nav/index.vue';
import { getThemeForCss } from '@/utils/addColor.js';
export default {
  components: {
    Nav,
  },
  setup () {
    getThemeForCss();
    onMounted(() => {
      window.addEventListener('setItemEvent', function (e) {
        // 这里的info是我存入localstorage的key值
        if (e.key === "theme") {
          setTimeout(() => {
            location.reload();
          }, 100);
        }
      });
    });
    return {
      zhCN
    };
  },
}
</script>

<style scoped lang="less">
.wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  &-main {
    padding: 20px 40px 0px 40px;
    height: calc(100vh - 110px);
    position: relative;
    z-index: 2;
  }
  &-background {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    video {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
