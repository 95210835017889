<template>
  <div class="login-wrap">
    <!-- <div class="login-wrap-logo">
      <img src="@/assets/images/nav/logo.png" />
    </div> -->
    <div class="login-wrap-main">
      <div class="login-bg">
        <img src="@/assets/images/login-bg.png" />
      </div>
      <div class="login-box">
        <Card-border :width="474" :height="415"></Card-border>
        <div class="login-box-content" id="login-wrapper">
          <!-- <div class="login-box-title">欢迎登陆</div>
          <div class="login-box-form">
            <div>
              <a-input v-model:value="loginForm.username" placeholder="用户名" />
            </div>
            <div>
              <a-input-password v-model:value="loginForm.password" placeholder="密码" />
            </div>
          </div>
          <div class="login-box-btn" @click="loginClick">立即登陆</div> -->
        </div>
      </div>
    </div>
    <div class="login-wrap-backed">
      <video
        class="left-safe-background"
        src="@/assets/images/background0.webm"
        autoPlay="autoPlay"
        loop="loop"
        muted="muted"
      ></video>
    </div>
  </div>
</template>

<script>
import { reactive, onMounted } from 'vue';
import CardBorder from '@/components/common/card-border.vue';
import { Input } from 'ant-design-vue';
import router from "@/router/index";

export default {
  components: {
    CardBorder,
    [Input.name]: Input,
    [Input.Password.name]: Input.Password
  },
  setup () {
    //sdk登录
    onMounted(() => {
      var wrapper = document.getElementById('login-wrapper');
      var opts = {
        src: 'pcw_jiagu_sec',
        primaryColor: '#BC7246',
        signIn: {
          types: ["mobile"],
          accountPlaceholder: '用户名',
          signUpTip: '',
          mobile: {
            panelTitle: '欢迎登录',
          },
          autoreg: 1,
          findPwdTip: "",
          thirdPart: {
            providers: [],
          },
          agreement: false,

        },
      };
      window.QHPass.init(opts);
      window.QHPass.signIn(wrapper, function () {
        router.push('/home');
      }, function (e) {
        console.log(e, '登陆失败');
      });
      var input = document.getElementsByClassName('quc-button-primary');
      input[0].value = "立即登录";
    });

    const loginForm = reactive({
      username: '',
      password: ''
    });

    const loginClick = () => {
      const params = {
        username: loginForm.username,
        password: loginForm.password
      };
      console.log(params);
      router.push('/home');
    };
    return { loginForm, loginClick };
  }
}
</script>

<style lang="less">
.login-wrap {
  width: 100%;
  height: 100%;
  padding-top: 44px;
  overflow-y: auto;
  &-logo {
    width: 164px;
    height: 34px;
    margin-left: 44px;
    margin-bottom: 12px;
    position: relative;
    z-index: 2;
    img {
      width: 100%;
    }
  }

  &-main {
    width: 100%;
    height: calc(100% - 46px);
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 2;
    .login-bg {
      width: 961px;
      height: 788px;

      img {
        width: 100%;
      }
    }

    .login-box {
      position: relative;
      // width: 390px;
      // height: 340px;
      padding: 15px;

      &-content {
        width: 100%;
        height: 100%;
        text-align: center;
        // padding: 34px 42px;
        border-radius: 5px;
        background: #222225;

        .quc-panel-small {
          background: #222225;

          .quc-primary {
            color: #ffffff !important;
          }

          .quc-input input {
            border: 1px solid rgb(89, 65, 49);
            color: rgb(255, 255, 255) !important;
            background-color: rgba(0, 0, 0, 0);
          }
        }
      }

      &-title {
        font-size: 24px;
      }

      &-form {
        div {
          width: 360px;
          height: 48px;
          margin-top: 36px;

          .ant-input,
          .ant-input-password {
            width: 100%;
            height: 100%;
            background: #12151e;
            border: 1px solid #ef9753;
            border-radius: 2px;
            opacity: 0.3;
            color: #ffffff;
            box-shadow: none;
          }

          .ant-input-password > .ant-input {
            border: 0;
          }

          .ant-input-password-icon {
            color: #ffffff;
          }
        }
      }

      &-btn {
        position: relative;
        width: 360px;
        height: 48px;
        margin-top: 44px;
        background: #bc7246;
        border-radius: 2px;
        cursor: pointer;
        font-size: 20px;
        color: #ffffff;
        line-height: 48px;
      }
    }
  }
  &-backed {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    overflow: hidden;
    z-index: 1;
    video {
      width: 100%;
      height: 100%;
    }
  }
}
.quc-wrapper .quc-input .quc-input-append {
  background-color: transparent !important;
  border-left: none !important;
}
.quc-form-tips {
  display: none;
}
</style>
