<template>
    <div class="compliance">
        <div class="timeBox">
            <div class="time">
                <DatePicker @dataPickerTime="selectTime" :defaultDate="defaultDate"/>
            </div>
        </div>
        <div class="complianceBox">
            <div class="complianceTop">
                <div class="risk">
                    <div class="complianceHeader">
                        风险应用占比
                    </div>
                    <div class="complianceBody">
                        <Container>
                            <template #default>
                                <riskApp :data="riskAppData" />
                            </template>
                        </Container>
                    </div>
                </div>
                <div class="stage">
                    <div class="complianceHeader">
                        风险阶段分布
                    </div>
                    <div class="complianceBody">
                        <Container>

                            <template #default>
                                <StageLine :data="riskAppData.activity" />
                            </template>
                        </Container>
                    </div>
                </div>
                <div class="applicationBox">
                    <div class="application">
                        <div class="complianceHeader">
                            风险应用 TOP 10
                        </div>
                        <div class="complianceBody">
                            <Container>

                                <template #default>
                                    <RiskPic :barData="modelData" :chartIndex="0" tooltipAlert="风险数"
                                        :colorPic="['rgba(187, 239, 255,0)', 'rgba(69, 188, 255,1)']" />
                                </template>
                            </Container>
                        </div>
                    </div>
                    <div class="applicationSDK">
                        <div class="complianceHeader">
                            风险 SDK TOP 10
                        </div>
                        <div class="complianceBody">
                            <Container>

                                <template #default>
                                    <RiskPic :barData="modelDataSdk" :chartIndex="1"
                                        :colorPic="['rgba(255, 153, 102,0)', 'rgba(255, 153, 102,1)']" />
                                </template>
                            </Container>
                        </div>
                    </div>
                </div>
            </div>
            <div class="complianceBotton">
                <div class="type">
                    <div class="complianceHeader">
                        风险类型分布
                    </div>
                    <div class="complianceBody">
                        <Container>

                            <template #default>
                                <Pie :data="modelDataVul" />
                            </template>
                        </Container>
                    </div>
                </div>
                <div class="behavior">
                    <div class="complianceHeader">
                        敏感行为统计
                    </div>
                    <div class="complianceBody">
                        <Container pd="0">

                            <template #default>
                                <BisePie :data="activitySummary" />
                            </template>
                        </Container>
                    </div>
                </div>
                <div class="riskNum">
                    <div class="complianceHeader">
                        检出风险数量变化
                    </div>
                    <div class="complianceBody">
                        <Container>

                            <template #default>
                                <Lines :data="thend" />
                            </template>
                        </Container>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, reactive } from 'vue';
import dayjs from 'dayjs';
import DatePicker from '@/components/common/date-picker.vue';
import Container from './common/container.vue';
import RiskPic from './common/riskPic.vue';
import BisePie from './common/bisePie';
import Lines from './common/lines.vue';
import riskApp from './common/riskApp.vue';
import Pie from './common/pie.vue';
import StageLine from './common/stageLine.vue';

import { getRisk, getTrend } from '@/api/appCompliance.js';
//   import { getLogoConfig } from "@/api/nav.js";
export default {
    name: 'HomePage',
    components: {
        Container,
        RiskPic,
        BisePie,
        Lines,
        riskApp,
        Pie,
        StageLine,
        DatePicker
    },
    setup () {
        //演示数据用
        const defaultDate = "2024-03-11"
        //上面上线前删除
        const appData = reactive({
            starttime: dayjs(defaultDate).subtract(6, 'day').format("YYYY-MM-DD"), //  前7天
            endtime: dayjs(defaultDate).format("YYYY-MM-DD") // 今天
        });
        const modelData = ref([]);
        const modelDataSdk = ref([]);
        const modelDataVul = ref([]);
        const activitySummary = ref({});
        const riskAppData = ref({});
        const thend = ref([]);

        const getRiskCallback = async () => {
            let { data } = await getRisk({
                assettype: 1,
                ...appData,
            });
            if (data) {
                riskAppData.value = data;
                modelData.value = data.app.map(item => ({ name: item.appName, value: item.total }));
                modelDataSdk.value = data.sdk.map(item => ({ name: item.name, value: item.total }));
                modelDataVul.value = data.vul.map((item, index) => {
                    if (index == 0) {
                        return { name: item.name, value: item.total, label: { show: index == 0 } }
                    };
                    return { name: item.name, value: item.total }
                });
                activitySummary.value = data.activitySummary;
            }
        };

        const getTrendCallback = async () => {
            let { data } = await getTrend({
                ...appData,
            });
            if (data) {
                thend.value = data.map(item => ({ name: item.date.replace(/-/g, '/'), value: item.android.total }))
            }
        }


        getRiskCallback();
        getTrendCallback();

        const selectTime = (val) => {
            appData.starttime = dayjs(val).startOf('week').format("YYYY-MM-DD");
            appData.endtime = dayjs(val).endOf('week').format("YYYY-MM-DD");
            getRiskCallback();
            getTrendCallback();
        };
        return { modelData, modelDataSdk, modelDataVul, activitySummary, thend, riskAppData, getRiskCallback, appData, selectTime, defaultDate };
    },
}
</script>

<style scoped lang="less">
.compliance {
    width: 100%;
    height: 100%;
    box-sizing: border-box;

    .timeBox {
        width: 100%;
        height: 44px;
        box-sizing: border-box;
        overflow: hidden;

        .time {
            float: right;
            width: 287px;
            height: 100%;
        }
    }

    .complianceBox {
        width: 100%;
        height: calc(100% - 44px);
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        gap: 14px;

        .complianceTop {
            width: 100%;
            height: 55%;
            box-sizing: border-box;
            display: flex;
            gap: 24px;

            .risk {
                display: flex;
                flex-direction: column;
                gap: 8px;
                width: 30%;
                height: 100%;
            }

            .stage {
                display: flex;
                flex-direction: column;
                gap: 8px;
                width: 25%;
                height: 100%;
            }

            .applicationBox {
                display: flex;
                flex-direction: column;
                gap: 24px;
                width: 45%;
                height: 100%;

                .application {
                    display: flex;
                    flex-direction: column;
                    gap: 8px;
                    height: 50%;
                    width: 100%;
                }

                .applicationSDK {
                    display: flex;
                    flex-direction: column;
                    gap: 8px;
                    height: 50%;
                    width: 100%;
                }
            }
        }

        .complianceBotton {
            width: 100%;
            height: 45%;
            box-sizing: border-box;
            display: flex;
            gap: 24px;

            .type {
                display: flex;
                flex-direction: column;
                gap: 8px;
                width: 30%;
                height: 100%;
            }

            .behavior {
                display: flex;
                flex-direction: column;
                gap: 8px;
                width: 25%;
                height: 100%;
            }

            .riskNum {
                display: flex;
                flex-direction: column;
                gap: 8px;
                width: 45%;
                height: 100%;
            }
        }
    }

    .complianceHeader {
        width: 100%;
        font-family: PingFang SC;
        font-size: 18px;
        font-weight: 500;
        line-height: 26px;
        letter-spacing: 0em;
        text-align: left;
        padding: 0 16px;
        box-sizing: border-box;
    }

    .complianceBody {
        width: 100%;
        height: calc(100% - 26px);
    }
}
</style>